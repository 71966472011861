import React, { useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import {
  Avatar,
  Button,
  ButtonGroup,
  IconEdit,
  IconCopy,
  Text,
  IconButton,
  Tooltip,
  IconKidStar,
  IconKidStarFilled,
} from '@wirestock.io/design-components';
import { useTheme } from 'styled-components';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import * as Styled from './ThemeReview.styles';
import {
  useGetCustomStyleAssets,
  useGetCustomStyleById,
  useMutateCustomStyleAddToFeatured, useMutateCustomStyleRemoveFromFeatured,
  useMutateCustomStyleReview, useGetFeaturedCustomStyles,
} from '../../../queries';
import { RouteParams } from '../ThemesManager.types';
import { ThemeReviewRejectionPopup } from './ThemeReviewRejectionPopup';
import { EditThemeDetailsPopup } from './EditThemeDetailsPopup';

enum ThemeReviewStatuses {
  Published = 'published',
  Restricted = 'restricted',
}

export const ThemeReviewHeader = () => {
  const { themeId } = useParams<RouteParams>();
  const history = useHistory();
  const { color } = useTheme();
  const { data: themeAssets } = useGetCustomStyleAssets(themeId);
  const { data } = useGetCustomStyleById(themeId);
  const { data: featuredThemes, refetch: updateFeaturedList } = useGetFeaturedCustomStyles();
  const featuredThemesIds = featuredThemes?.map((f) => f.id);
  const { mutate: publishTheme, isLoading: isThemePublishLoading } = useMutateCustomStyleReview();
  const { mutate: rejectTheme, isLoading: isThemeRejectionLoading } = useMutateCustomStyleReview();
  const { mutate: addThemeToFeatured } = useMutateCustomStyleAddToFeatured({
    onSuccess: async () => updateFeaturedList(),
  });
  const { mutate: removeThemeFromFeatured } = useMutateCustomStyleRemoveFromFeatured({
    onSuccess: async () => updateFeaturedList(),
  });
  const [, copyToClipboardHelper] = useCopyToClipboard();
  const [isIdCopied, setIsIdCopied] = useState<boolean>(false);
  const [rejectionPopupOpen, setRejectionPopupOpen] = useState<boolean>(false);
  const [editThemeDetailsPopupOpen, setEditThemeDetailsPopupOpen] = useState<boolean>(false);

  if (!data) {
    return null;
  }

  const onIDClick = () => {
    copyToClipboardHelper(data?.id);
    setIsIdCopied(true);

    setTimeout(() => {
      setIsIdCopied(false);
    }, 1000);
  };

  const handleReviewingActionClick = async (action: ThemeReviewStatuses, rejectionReason?: string) => {
    const reviewHandlerOptions = {
      onSuccess: () => {
        history.push('/themes-manager', { shouldRefresh: true });
      },
    };

    if (action === ThemeReviewStatuses.Published) {
      publishTheme({
        id: themeId,
        status: action,
      }, reviewHandlerOptions);
    } else {
      rejectTheme({
        id: themeId,
        status: action,
        reason: rejectionReason,
      }, reviewHandlerOptions);
    }
  };

  return (
    <>
      <Styled.ThemeReviewHeaderContainer>
        <Styled.ThemeReviewHeaderInfoBlock>
          <Avatar src={data?.coverPhotoUrl} size={{ size: '64px', padding: '0' }} borderColor={color.BACKGROUND_1} />
          <Styled.ThemeReviewHeaderTitle>
            <Text text={`${data?.name} by`} tag="h5" />
            <a href={`${process.env.REACT_APP_FRONT_APP_URL}/${data?.authorUsername}`} target="_blank" rel="noreferrer">
              <Text text={data?.authorDisplayName} tag="h5" color={color.BABY_BLUE} />
            </a>
            <Text text={`(${themeAssets?.length} images)`} tag="p2" />
          </Styled.ThemeReviewHeaderTitle>
          <ButtonGroup gap={8}>
            <Button
              label={isIdCopied ? 'Copied!' : 'ID'}
              size="small"
              slotRight={!isIdCopied ? <IconCopy width={16} height={16} /> : undefined}
              type="secondary"
              onClick={onIDClick}
            />
            <IconButton
              size="small"
              icon={<IconEdit />}
              type="secondary"
              onClick={() => setEditThemeDetailsPopupOpen(true)}
            />
            {featuredThemesIds?.includes(data?.id) ? (
              <Tooltip
                key={data?.id}
                id={data?.id}
                content={<p> Remove from featured themes </p>}
                placement="bottom-start"
              >
                <IconButton
                  icon={<IconKidStarFilled width={18} height={18} />}
                  onClick={() => removeThemeFromFeatured(data?.id)}
                  type="secondary"
                  size="small"
                />
              </Tooltip>
            ) : (
              <Tooltip
                key={data?.id}
                id={data?.id}
                content={<p>  Add to featured themes </p>}
                leaveDelay={0}
                placement="bottom-start"
              >
                <IconButton
                  icon={<IconKidStar width={18} height={18} />}
                  onClick={() => addThemeToFeatured(data?.id)}
                  type="secondary"
                  variant="outlined"
                  size="small"
                />
              </Tooltip>
            )}
          </ButtonGroup>
        </Styled.ThemeReviewHeaderInfoBlock>
        {(data?.status === 'completed' && data?.isPublic) && (
        <ButtonGroup>
          <Button
            label="Publish"
            type="primary"
            onClick={() => handleReviewingActionClick(ThemeReviewStatuses.Published)}
            isLoading={isThemePublishLoading}
          />
          <Button
            label="Reject"
            type="destructive"
            onClick={() => setRejectionPopupOpen(true)}
            isLoading={isThemeRejectionLoading}
          />
        </ButtonGroup>
        )}
      </Styled.ThemeReviewHeaderContainer>
      <ThemeReviewRejectionPopup
        isOpen={rejectionPopupOpen}
        onClose={() => setRejectionPopupOpen(false)}
        onReject={(rejectionReason) => handleReviewingActionClick(ThemeReviewStatuses.Restricted, rejectionReason)}
        rejectionIsLoading={isThemeRejectionLoading}
      />
      <EditThemeDetailsPopup isOpen={editThemeDetailsPopupOpen} onClose={() => setEditThemeDetailsPopupOpen(false)} />
    </>
  );
};
