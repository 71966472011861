import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { SlideTransition } from '../Transitions';

import { DialogProps } from '../../types';
import './dialog.main.scss';

export const DialogContainer = (props: DialogProps) => {
  const {
    open,
    dialogCloseHandler,
    children,
  } = props;

  const closeDialog = (stateUpdate: (value: boolean) => void) => {
    stateUpdate(false);
  };

  return (
    <div className="Dialog">
      <Dialog
        open={open}
        TransitionComponent={SlideTransition}
        keepMounted
        onClose={() => closeDialog(dialogCloseHandler)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </div>
  );
};
