import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useTimeout } from 'rooks';
import { useSnackbar } from 'notistack';
import Slider from '@mui/material/Slider';
import {
  useGetAssignedMedia,
  useAssignMedia,
} from '../../../../queries';
import { LoadingProgress } from '../../../../components/LoadingProgress';
import { StyledButton } from '../../../../components/StyledButton';
import { Response, DefaultUserProps } from '../../../../types';
import homeIllustration from '../../../../assets/images/home-illustration.png';

export const RankerView = ({ user }: DefaultUserProps): JSX.Element => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [mediaCount, setMediaCount] = useState<number | number[]>(50);
  const { start: redirectToMediaView } = useTimeout(() => {
    history.push('/rank/media');
  }, 10);
  const { isLoading, data: assignedBatch, remove: removeAssignedBatch }: any =
    useGetAssignedMedia({
      role: user?.selectedRole,
      page: 1,
      options: {
        onSuccess: (res: Response) => {
          const response = res && res.data;

          if (!response.id) {
            enqueueSnackbar(
              'No assigned media. Please try to request new batch.',
            );
          } else {
            redirectToMediaView();
          }
        },
        onError: (error: any) => {
          enqueueSnackbar(
            error.data.message,
            {
              variant: 'error',
            },
          );
        },
      },
    });

  const { mutate: assignMediaMutation, isLoading: getBatchLoading } = useAssignMedia(user?.selectedRole, {
    onSuccess: (res: Response) => {
      if (res && res.message) {
        removeAssignedBatch();
      }
    },
    onError: (error: any) => {
      enqueueSnackbar(
        error.data.message,
        {
          variant: 'error',
        },
      );
    },
  });
  const getTopBatch = () => {
    assignMediaMutation(mediaCount);
  };

  useEffect(() => {
    if (
      assignedBatch &&
      assignedBatch.data.length &&
      (assignedBatch.data[0].id || assignedBatch.data.id)
    ) {
      removeAssignedBatch();
    }
  }, [assignedBatch]);

  return (
    <div className="Home-element">
      {!!isLoading && <LoadingProgress />}
      {!isLoading && (
        <>
          <h1>All Done</h1>
          <img src={homeIllustration} alt="" />

          <Slider
            aria-label="Always visible"
            value={mediaCount}
            max={100}
            min={5}
            step={5}
            onChange={(e: any) => setMediaCount(e?.target?.value)}
            valueLabelDisplay="on"
          />
          <StyledButton
            className="home-element-button"
            variant="contained"
            color="primary"
            onClick={getTopBatch}
            disabled={getBatchLoading}
          >
            Get New Batch
          </StyledButton>
          {!!getBatchLoading && <LoadingProgress fixed size={20} />}
        </>
      )}
    </div>
  );
};
