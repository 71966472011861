import React from 'react';
import Button from '@material-ui/core/Button';

import './styled.button.scss';

export const StyledButton = (props: React.ComponentProps<typeof Button>): JSX.Element => {
  const { children } = props;

  return (
    <div className="StyledButton">
      <Button {...props}>{children}</Button>
    </div>
  );
};
