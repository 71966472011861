import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import {
  Button,
  IconCheck,
  IconDownload,
  Text,
} from '@wirestock.io/design-components';
import { ChallengeDTO } from '../../../models/Challenge/Challenge.dto';
import { ChallengeFavoritesSelector } from './ChallengeFavoritesSelector';
import { ChallengeAllSubmissions } from './ChallengeAllSubmissions';
import * as Styled from './ChallengeSubmissions.styles';
import {
  useGetChallenge,
  useFinilizeChallenge,
  useGetChallengeShortlist,
} from '../../../queries';
import { ClockCountdown } from '../../../components/ClockCountdown';

interface ChallengeSubmissionsProps {
  challenge: ChallengeDTO;
  isOpen: boolean;
}

export const ChallengeSubmissions = ({ challenge, isOpen }: ChallengeSubmissionsProps) => {
  const { color } = useTheme();
  const [tab, setTab] = useState<string | number>(0);
  const { data: challengeData, refetch: refetchChallengeData } = useGetChallenge(challenge?.id);
  const { data: shortlist } = useGetChallengeShortlist(challenge?.id);
  const { mutate: finalizeChallenge } = useFinilizeChallenge({
    onSuccess: () => {
      refetchChallengeData();
    },
  });

  const tabChange = (tabIndex: string | number) => {
    setTab(tabIndex);
  };

  const handleFinalizeChallengeClick = () => {
    finalizeChallenge(challenge?.id);
  };

  const handleDownloadAssetsClick = () => {
    const fileUrls: string[] = [];
    shortlist?.data.map((winner: any) => {
      if (winner.position) {
        fileUrls.push(winner?.media?.originalFileUrl);
      }
    });

    challengeData?.content?.coverPhotoUrl && fileUrls.push(challengeData?.content?.coverPhotoUrl);

    fileUrls.map(async (url) => {
      window.open(url);
    });
  };

  useEffect(() => {
    refetchChallengeData();
  }, [isOpen]);

  const renderTabContent = (tabIndex: string | number) => {
    return !tabIndex ? (
      <ChallengeAllSubmissions
        challenge={challenge}
        isOpen={isOpen}
        refetchChallengeData={refetchChallengeData}
      />
    ) : (
      <ChallengeFavoritesSelector
        challenge={challenge}
        refetchChallengeData={refetchChallengeData}
      />
    );
  };
  return (
    <Styled.ChallengeSubmissionsContainer>
      <Styled.ChallengeSubmissionsHeader>
        <Text
          tag="p1"
          weight="bold"
          text={challenge?.title}
        />
        {!challenge?.deadlineExceeded && (
          <ClockCountdown
            deadline={challenge?.deadlineDate?.toString()}
            tag="p3"
          />
        )}
      </Styled.ChallengeSubmissionsHeader>
      <Styled.ChallengeSubmissionsActions>
        <Button
          slotLeft={(
            <IconDownload
              fill="white"
              width={16}
              height={16}
            />
          )}
          onClick={handleDownloadAssetsClick}
          type="primary"
          label="Download assets"
        />
        {challengeData && challengeData?.favoritesCount > 0 && (
          <Button
            slotLeft={(
              <IconCheck
                fill="white"
                width={16}
                height={16}
              />
            )}
            onClick={handleFinalizeChallengeClick}
            disabled={challengeData?.isCompleted}
            type="primary"
            label="Finalize challenge"
          />
        )}
      </Styled.ChallengeSubmissionsActions>
      <Styled.ChallengeSubmissionsBody>
        <Styled.ChallengeSubmissionsTabs
          onChange={(index) => tabChange(index)}
          indicatorColor={color.BABY_BLUE}
          textColor={color.black}
        >
          <Styled.ChallengeSubmissionsTab label={`All Submissions (${challengeData?.submissionCount || 0})`} />
          <Styled.ChallengeSubmissionsTab label={`Favorites & Winners (${challengeData?.favoritesCount ?? 0})`} />
        </Styled.ChallengeSubmissionsTabs>
        {renderTabContent(tab)}
      </Styled.ChallengeSubmissionsBody>
    </Styled.ChallengeSubmissionsContainer>
  );
};
