import { useQuery } from 'react-query';
import jwt_decode from 'jwt-decode';
import logger from '../utils/logger';
import {
  setItemToLocalStorage,
} from '../services/StorageService';
import {
  AUTH_DATA,
} from '../constants/storage.constants';
import { login, isLoggedIn } from '../services/AuthService';
import { Credentials, JWTToken, Response } from '../types';

export enum AuthKeysEnum {
  Login = 'login',
}

export const useLogin = (credentials: Credentials, options = {}) =>
  useQuery(
    AuthKeysEnum.Login,
    () => login(credentials),
    {
      ...options,
      onSuccess: (res: Response) => {
        /* eslint-disable camelcase */
        const {
          access_token,
        } = res.data;

        const decodedToken: JWTToken = jwt_decode(access_token);

        const authData = {
          userId: parseInt(decodedToken.sub, 10),
          access_token,
          expires_in: decodedToken.exp * 1000,
        };
        /* eslint-enable camelcase */

        setItemToLocalStorage(AUTH_DATA, authData, true);
        isLoggedIn();
        window.location.reload();
      },
      onError: (error: Error) => {
        logger.error(`Login hook: ${error}`);
      },
    },
  );
