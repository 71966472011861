import { useQuery, useMutation } from 'react-query';

import logger from '../../../../utils/logger';
import {
  assignMedia,
  getRankerAssignedMedia,
  getRankerKeywordingDefects,
  saveKeywordingRank,
} from '../../../../services/BatchService';

export enum RankerKeysEnum {
  AssignMedia = 'assignMedia',
  GetRankerAssignedMedia = 'getRankerAssignedMedia',
  GetRankerKeywordingDefects = 'getRankerKeywordingDefects',
}

export const useGetRankerAssignedMedia = (options = {}) =>
  useQuery(
    RankerKeysEnum.GetRankerAssignedMedia,
    () => getRankerAssignedMedia(),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useGetRankerAssignedMedia hook: ${error}`);
      },
    },
  );

export const useAssignMedia = (options = {}) =>
  useMutation(
    (mediaCount: number | number[]) => assignMedia(mediaCount, ''),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useAssignMedia hook: ${error}`);
      },
    },
  );

export const useGetRankerKeywordingDefects = (options = {}) =>
  useQuery(
    RankerKeysEnum.GetRankerKeywordingDefects,
    () => getRankerKeywordingDefects(),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useGetRankerKeywordingDefects hook: ${error}`);
      },
    },
  );

export const useSaveKeywordingRank = (options = {}) =>
  useMutation(
    (data: any) => saveKeywordingRank(data.mediaId, data.keywordingDefects),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useSaveKeywordingRank hook: ${error}`);
      },
    },
  );
