/**
 * generate prefix that will be assigned to the log
 * Format: DD.MM.YYYY / hh:mm:ss
 * @return {string}
 * @private
 */
const _generateLogPrefix = () => {
  const currentData = new Date();

  return `[${currentData.getDate()}.${currentData.getMonth()}.${currentData.getFullYear()} / ${currentData.getHours()}:${currentData.getMinutes()}:${currentData.getSeconds()}]`;
};

/**
 * Preprocessing log arguments
 * @param args
 * @return {[]}
 * @private
 */
const _preprocessor = (args: any) => {
  args.unshift(`${_generateLogPrefix()} -> `);
  return args;
};

/**
 * checking to see if the current build is production or not
 * @return {boolean}
 * @private
 */
const _isLoggerDisabled = () => process.env.NODE_ENV === 'production';

export default {
  /* eslint-disable no-console */
  log: (...args: any) => {
    if (_isLoggerDisabled()) {
      return;
    }

    console.log(..._preprocessor(args));
  },
  info: (...args: any) => {
    if (_isLoggerDisabled()) {
      return;
    }

    console.info(..._preprocessor(args));
  },
  warn: (...args: any) => {
    if (_isLoggerDisabled()) {
      return;
    }

    console.warn(..._preprocessor(args));
  },
  error: (...args: any) => {
    if (_isLoggerDisabled()) {
      return;
    }

    console.error(..._preprocessor(args));
  },
  /* eslint-enable no-console */
};
