import styled, { css } from 'styled-components';

export const ThemesListContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    position: relative;
    grid-gap: ${theme.space.S24};
    grid-template-columns: repeat(6, 1fr);
    width: 100%;
    margin: 0 auto auto;
    padding-top: ${theme.space.S24};
  `};
`;

export const ThemeListItemImage = styled.div`
  cursor: pointer;
  max-width: 631px;
  aspect-ratio: 1;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 600ms;
  }
`;

export const ThemeListItem = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  color: ${({ theme }) => theme.color.black};
  box-shadow: ${({ theme }) => theme.shadow.shadowMain};
  position: relative;

  &:hover {
    ${ThemeListItemImage} {
      img {
        transform: scale(1.05);
      }
    }
  }
`;

export const ThemeListItemContent = styled.div`
  padding: ${({ theme }) => theme.space.lg};
`;
