import {
  InputText, Menu, MenuItem, Popup, PopupProps, Text,
} from '@wirestock.io/design-components';
import React, { useState } from 'react';

const themeRejectionReasons = [
  'Inappropriate Theme name.',
  'The name of the Theme must only contain English letters.',
  'The Theme name must be clear to understand for users or not too abstract and relevant to the images it is based on.',
  'Theme contains inappropriate imagery which are not suitable for public usage and monetization.',
  'The created Theme fails to generate images of sufficient quality, making it unsuitable for public usage and monetization.',
  'The created theme fails to generate images as expected based on contents of the training batch, making it unsuitable for public usage and monetization.',
  'Created Theme contains copyrighted/trademarked assets or assets created by other artists and is unsuitable for public usage and monetization.',
];

interface ThemeReviewRejectionPopupProps extends PopupProps {
  onReject: (rejectionReason: string) => void;
  rejectionIsLoading?: boolean;
}

export const ThemeReviewRejectionPopup = ({
  isOpen, onClose, onReject, rejectionIsLoading,
}: ThemeReviewRejectionPopupProps) => {
  const [rejectionReason, setRejectionReason] = useState<string>('');

  return (
    <Popup
      title="Select rejection reason"
      maxWidth={860}
      isOpen={isOpen}
      onClose={onClose}
      submitLabel="Reject"
      submitButtonProps={{ type: 'destructive', isLoading: rejectionIsLoading }}
      onSubmit={() => onReject(rejectionReason)}
    >
      <>
        <Menu>
          {themeRejectionReasons.map((reason, index) => (
            <MenuItem onClick={() => setRejectionReason(reason)} key={index}>
              <Text text={reason} tag="p3" />
            </MenuItem>
          ))}
        </Menu>
        <InputText
          placeholder="Choose or type rejection reason"
          value={rejectionReason}
          onChange={(e) => setRejectionReason(e.currentTarget.value)}
        />
      </>
    </Popup>
  );
};
