export const ChallengeMediaListResponseTransformer = (challengeMediaListResponse: any): Array<ChallengeMediaDTO> => {
  return challengeMediaListResponse?.map((challenge: any) => {
    return new ChallengeMediaDTO(challenge);
  });
};

export class ChallengeMediaDTO {

  mediaId: number;

  thumbnail: string;

  preview: string;

  originalFileUrl: string;

  userAvatar: string;

  username: string;

  displayName: string;

  challengeId: number;

  width: number;

  height: number;

  duration: number;

  type: string;

  aspectRatio: number;

  position: number;

  prize: string;

  favorite: boolean;

  title: string;

  reviewerNote: string;

  constructor(challengeMediaData: any) {
    this.challengeId = challengeMediaData?.challengeId;
    this.mediaId = challengeMediaData?.mediaId;
    this.preview = challengeMediaData?.details?.mediaPreviewUrl;
    this.thumbnail = challengeMediaData?.details?.mediaThumbnailUrl;
    this.originalFileUrl = challengeMediaData?.details?.originalOptimizedFileUrl;
    this.userAvatar = challengeMediaData?.details?.userAvatarUrl;
    this.username = challengeMediaData?.details?.username;
    this.displayName = challengeMediaData?.details?.displayName;
    this.width = challengeMediaData?.details?.width;
    this.height = challengeMediaData?.details?.height;
    this.duration = challengeMediaData?.details?.duration;
    this.type = challengeMediaData?.details?.type;
    this.aspectRatio = challengeMediaData?.details?.aspectEatio;
    this.title = challengeMediaData?.details?.title;
    this.reviewerNote = challengeMediaData?.reviewerNote;
    this.prize = challengeMediaData?.prize ?? null;
    this.favorite = !!challengeMediaData?.favorite;
    this.position = challengeMediaData?.position ?? null;
  }
}
