import React, { ReactElement } from 'react';
import { Switch } from 'react-router-dom';
import { Providers } from './Providers';
import { AuthRoute } from './components/AuthRoute';
import './App.scss';

import { Main } from './containers/Main';
import { Login } from './containers/Login';

export const App: React.FC = (): ReactElement => (
  <div className="App">
    <Providers>
      <Switch>
        <AuthRoute
          path="/login"
          component={Login}
        />
        <AuthRoute
          path="/"
          authRequired
          component={Main}
        />
      </Switch>
    </Providers>
  </div>
);
