import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useStatsUpdate } from '../queries';
import { UserDTO } from '../models/User/User.dto';

const initialStatsContext: {
  stats: [],
  isLoading: boolean;
} = {
  stats: [],
  isLoading: false,
};

export const StatsContext = createContext(initialStatsContext);

export const StatsProvider = ({ children, user }: { children: JSX.Element, user?: UserDTO }) => {
  const { data: fetchedData, isLoading, refetch } = useStatsUpdate(user?.selectedRole);
  const [stats, setStats] = useState<[]>([]);

  useEffect(() => {
    setStats(fetchedData);
  }, [fetchedData]);

  useEffect(() => {
    refetch();
  }, [user?.selectedRole]);

  return (
    <StatsContext.Provider value={{ stats, isLoading }}>
      {children}
    </StatsContext.Provider>
  );
};

export const useStats = () => useContext(StatsContext);
