import { useMutation, UseQueryOptions, UseQueryResult } from 'react-query';
import { useAuthedQuery } from '../hooks';
import logger from '../utils/logger';
import {
  updateBatchMediaNote,
  getAssignedMedia,
  assignMedia,
} from '../services/BatchService';
import { MediaUpdateProps, Response } from '../types';
import {
  getMediaGeneratedKeywords,
  getMediaCategories,
  getMediaCountries,
  getMediaAgencies,
  getMediaAgenciesReasons,
  getRejectionReasons,
  getRevisionReasons,
  mediaAgencyReject,
  rejectMedia,
  reviseMedia,
  updateMedia,
  acceptMedia,
  putSellingPermission,
  addMediaRejections,
  getMediaDescriptionSuggestions,
  getMediaContentIssues,
  regenerateMediaData,
  makeMediaAIGenerated, mediaFavoriteRank, getMarketplaceRejectionReasons, addMarketplaceRejections,
} from '../services/MediaService';

export enum MediaKeysEnum {
  AcceptMedia = 'acceptMedia',
  AssignMedia = 'assignMedia',
  GetAssignedMedia = 'getAssignedMedia',
  GetMarketplaceRejectionReasons = 'getMarketplaceRejectionReasons',
  GetRejectionReasons = 'getRejectionReasons',
  GetRevisionReasons = 'getRevistionReasons',
  GetGeneratedKeywords = 'getGeneratedKeywords',
  GetGeneratedDescriptions = 'getMediaDescriptionSuggestions',
  GetMediaCategories = 'getMediaCategories',
  GetMediaCountries = 'getMediaCountries',
  GetMediaAgencies = 'getMediaAgencies',
  GetMediaAgenciesReasons = 'getMediaAgenciesReasons',
  GetMediaContentIssues = 'getMediaContentIssues',
}

export const useGetGeneratedKeywords = (options = {}) =>
  useMutation(
    (context: string) => getMediaGeneratedKeywords(context),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useGetGeneratedKeywords hook: ${error}`);
      },
    },
  );

export const useGetGeneratedDescriptions = (options = {}) =>
  useMutation(
    (mediaId: number) => getMediaDescriptionSuggestions(mediaId),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useGetGeneratedDescriptions hook: ${error}`);
      },
    },
  );

export const useGetMediaCategories = (options = {}) =>
  useAuthedQuery(
    MediaKeysEnum.GetMediaCategories,
    getMediaCategories,
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useGetMediaCategories hook: ${error}`);
      },
    },
  );

export const useGetMediaCountries = (options = {}) =>
  useAuthedQuery(
    MediaKeysEnum.GetMediaCountries,
    getMediaCountries,
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useGetMediaCountries hook: ${error}`);
      },
    },
  );

export const useGetMediaAgencies = (options = {}) =>
  useAuthedQuery(
    MediaKeysEnum.GetMediaAgencies,
    getMediaAgencies,
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useGetMediaAgencies hook: ${error}`);
      },
    },
  );

export const useGetMediaAgenciesReasons = (options = {}) =>
  useAuthedQuery(
    MediaKeysEnum.GetMediaAgenciesReasons,
    getMediaAgenciesReasons,
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useGetMediaAgenciesReasons hook: ${error}`);
      },
    },
  );

export const useAgencyMediaReject = (options = {}) =>
  useMutation(
    (data: any) => mediaAgencyReject(data),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useAgencyMediaReject hook: ${error}`);
      },
    },
  );

export const useGetRejectionReasons = () =>
  useAuthedQuery(
    MediaKeysEnum.GetRejectionReasons,
    getRejectionReasons,
    {
      onError: (error: Error) => {
        logger.error(`useGetRejectionReasons hook: ${error}`);
      },
    },
  );

export const useGetMarketplaceRejectionReasons = () =>
  useAuthedQuery(
    MediaKeysEnum.GetMarketplaceRejectionReasons,
    getMarketplaceRejectionReasons,
    {
      onError: (error: Error) => {
        logger.error(`useGetRejectionReasons hook: ${error}`);
      },
    },
  );

export const useGetRevisionReasons = () =>
  useAuthedQuery(
    MediaKeysEnum.GetRevisionReasons,
    getRevisionReasons,
    {
      onError: (error: Error) => {
        logger.error(`useGetRevisionReasons hook: ${error}`);
      },
    },
  );

interface UseGetAssignedMediaProps {
  role?: string | null;
  page?: number;
  options?: UseQueryOptions<UseGetAssignedMediaProps, any, Response, MediaKeysEnum.GetAssignedMedia>;
}

export const useGetAssignedMedia = ({ role, page, options }: UseGetAssignedMediaProps): UseQueryResult<Response> =>
  useAuthedQuery(
    [MediaKeysEnum.GetAssignedMedia, role],
    () => {
      if (!role) return Promise.resolve({ data: [] });
      return getAssignedMedia(role ?? '', page);
    },
    {
      enabled: !!role,
      ...options,
    },
  );

export const useAssignMedia = (role?: string, options = {}) =>
  useMutation(
    (mediaCount: number | number[]) => assignMedia(mediaCount, role ?? ''),
    {
      ...options,
    },
  );

export const useMediaRejection = (options = {}) =>
  useMutation(
    (data: any) => rejectMedia(data.data),
    {
      ...options,
    },
  );

export const useMediaRevision = (options = {}) =>
  useMutation(
    (data: any) => reviseMedia(data.mediaId, data.data),
    {
      ...options,
    },
  );

export const useUpdateMedia = (options = {}) =>
  useMutation(
    (mediaData: MediaUpdateProps) => updateMedia(mediaData),
    {
      ...options,
    },
  );

export const useUpdateCuratorNote = (options = {}) =>
  useMutation(
    (mediaData: any) => updateBatchMediaNote(mediaData),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useUpdateCuratorNote hook: ${error}`);
      },
    },
  );

export const useAcceptMedia = (options = {}) =>
  useMutation(
    (data: any) => acceptMedia(data.mediaData, data.role),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useAcceptMedia hook: ${error}`);
      },
    },
  );

export const useUpdateSellingPermission = (options = {}) =>
  useMutation(
    (data: any) => putSellingPermission(data),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useAcceptMedia hook: ${error}`);
      },
    },
  );

export const useAddMarketplaceRejections = (options = {}) =>
  useMutation(
    (data: any) => addMarketplaceRejections(data),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useAddMarketplaceRejections hook: ${error}`);
      },
    },
  );

export const useAddMediaRejections = (options = {}) =>
  useMutation(
    (data: any) => addMediaRejections(data),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useAddMediaRejections hook: ${error}`);
      },
    },
  );

export const useRegenerateMediaData = (options = {}) =>
  useMutation(
    (caption?: string) => regenerateMediaData(caption),
    {
      ...options,
    },
  );

export const useGetMediaContentIssues = (mediaIds: number[], options = {}) =>
  useAuthedQuery(
    MediaKeysEnum.GetMediaContentIssues,
    () => getMediaContentIssues(mediaIds),
    {
      ...options,
    },
  );

export const useMakeMediaAIGenerated = (options = {}) =>
  useMutation(
    (data: any) => makeMediaAIGenerated(data),
    {
      ...options,
    },
  );

export const useMediaFavoriteRank = (options = {}) =>
  useMutation(
    (data: any) => mediaFavoriteRank(data.mediaId, data.score),
    {
      ...options,
    },
  );
