import React, { useEffect, useState } from 'react';
import { Loader, Pagination } from '@wirestock.io/design-components';
import * as Styled from '../ChallengeSubmissions.styles';
import { ChallengeSubmissionMediaDTO } from '../../../../models/Challenge/ChallengeSubmissionMedia.dto';
import { ChallengeMediaItem } from '../ChallengeMediaItem';
import { useGetChallengeSubmissions, useUpdateChallengeSubmission } from '../../../../queries';
import { ChallengeDTO } from '../../../../models/Challenge/Challenge.dto';
import { ChallengeMediaItemSlider } from '../../../../components/ChallengeMediaItemSlider';

interface ChallengeAllSubmissionsProps {
  challenge: ChallengeDTO;
  isOpen?: boolean;
  refetchChallengeData: any;
}

export const ChallengeAllSubmissions = ({ challenge, isOpen, refetchChallengeData }: ChallengeAllSubmissionsProps) => {
  const [mediaZoomIndex, setMediaZoomIndex]: any = useState(null);
  const [page, setPage] = useState<number>(1);
  const [challengeSubmissions, setChallengeSubmissions] = useState<any>();
  const {
    refetch: refetchSubmissions,
    isLoading,
  } = useGetChallengeSubmissions({
    id: challenge.id,
    shortlist: false,
    page: page,
  }, {
    onSuccess: (response: any) => {
      setChallengeSubmissions(response);
    },
  });
  const { mutate: updateChallengeSubmission } = useUpdateChallengeSubmission({
    onSuccess: () => {
      refetchChallengeData();
      refetchSubmissions();
    },
  });

  useEffect(() => {
    if (isOpen) {
      refetchSubmissions();
    }
  }, [isOpen, page]);

  const onMediaFavorite = (mediaId: string, favorite: boolean) => {
    const submissions = challengeSubmissions;
    submissions.data.forEach((item: any) => {
      if (item.mediaId === mediaId) {
        item.favorite = favorite;
      }
    });

    setChallengeSubmissions(submissions);
    updateChallengeSubmission({
      challengeId: challenge.id,
      mediaId,
      submissionData: {
        favorite,
      },
    });
  };

  const scrollToCursor = () => {
    const cursorElement = (document.querySelector('.media-selected-highlight') as HTMLElement);
    const mediaContainer = (document.querySelector('.challenge-submissions-popup .WsPopup-body') as HTMLElement);

    mediaContainer.scroll({
      top: cursorElement?.offsetTop - mediaContainer?.offsetTop,
      behavior: 'smooth',
    });
  };

  const handlePaginationChange = (newPage: number) => {
    setPage(newPage);
  };

  const changeMediaZoomIndex = (i: number) => {
    setMediaZoomIndex(i);
    scrollToCursor();
  };

  const mediaZoomClose = () => {
    setMediaZoomIndex(null);
  };

  return (
    <>
      <Styled.ChallengeSubmissionsLoader>
        <Loader
          color="black"
          isLoading={isLoading}
          size={30}
        />
      </Styled.ChallengeSubmissionsLoader>
      {challengeSubmissions?.pagination?.totalPages > 1 && (
        <Styled.ChallengeSubmissionsPagination>
          <Pagination
            count={challengeSubmissions?.pagination?.totalPages}
            page={page}
            onChange={(e, newPage) => handlePaginationChange(newPage)}
          />
        </Styled.ChallengeSubmissionsPagination>
      )}
      <Styled.ChallengeSubmissionsGrid>
        {challengeSubmissions?.data?.map((submission: ChallengeSubmissionMediaDTO, index: number) => (
          <ChallengeMediaItem
            key={index}
            id={submission?.mediaId}
            thumbnail={submission?.media?.thumbnail}
            position={submission?.position}
            favorite={submission?.favorite}
            type={submission?.media?.type}
            selected={index === mediaZoomIndex}
            onFavorite={onMediaFavorite}
            onZoom={() => setMediaZoomIndex(index)}
          />
        ))}
      </Styled.ChallengeSubmissionsGrid>
      {challengeSubmissions?.pagination?.totalPages > 1 && (
        <Styled.ChallengeSubmissionsPagination>
          <Pagination
            count={challengeSubmissions?.pagination?.totalPages}
            page={page}
            onChange={(e, newPage) => handlePaginationChange(newPage)}
          />
        </Styled.ChallengeSubmissionsPagination>
      )}
      {typeof mediaZoomIndex === 'number' && (
        <div className="batch-media-slideshow">
          <ChallengeMediaItemSlider
            items={challengeSubmissions?.data}
            mediaZoomIndex={mediaZoomIndex}
            changeMediaZoomIndex={changeMediaZoomIndex}
            mediaZoomClose={mediaZoomClose}
            onFavorite={onMediaFavorite}
          />
        </div>
      )}
    </>
  );
};
