interface userProfileDetails {
  bio: string;
  facebookUrl: string;
  instagramUrl: string;
  twitterUrl: string;
  linkedinUrl: string;
  websiteUrl: string;
  backgroundImageUrl: string;
  avatarUrl: string;
  isEasySubmissionEnabled: boolean;
  datasetDealProgramEnabled: boolean;
  aiReleaseId: number;
}

export class PortfolioDTO {
  id: string;

  createdAt: string;

  displayName: string;

  username: string;

  email: string;

  userDetails: userProfileDetails;

  isSubscribed: boolean;

  constructor(userData: any) {
    const userDataDetails = userData?.details?.data;
    this.id = userData?.id;
    this.email = userData?.email;
    this.displayName = userData?.display_name;
    this.createdAt = userData?.created_at;
    this.username = userData?.username;
    this.userDetails = {
      bio: userDataDetails?.bio,
      facebookUrl: userDataDetails?.facebook_url,
      instagramUrl: userDataDetails?.instagram_url,
      twitterUrl: userDataDetails?.twitter_url,
      linkedinUrl: userDataDetails?.linkedin_url,
      websiteUrl: userDataDetails?.website_url,
      backgroundImageUrl: userDataDetails?.background_image_url,
      avatarUrl: userDataDetails?.avatar_url,
      isEasySubmissionEnabled: userDataDetails?.is_easy_submission_enabled,
      datasetDealProgramEnabled: userDataDetails?.dataset_deal_program_enabled,
      aiReleaseId: userDataDetails?.ai_release_id,
    };

    this.isSubscribed = userData.is_subscribed;
  }
}

export const PortfolioListResponseTransformer = (portfolioListResponse: any): Array<PortfolioDTO> => {
  return portfolioListResponse?.map((portfolio: any) => {
    return new PortfolioDTO(portfolio);
  });
};
