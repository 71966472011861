import { http } from './ApiService';
import { PayoutsDataProps } from '../types';

export default {
  getPayoutsList: ({
    userId, state, status, page,
  }: PayoutsDataProps) => {
    return http.get(
      '/accounting/payout/payouts',
      {
        params: {
          perPage: 30,
          ...(userId && { userId }),
          ...(state && { state }),
          ...(status && { status }),
          ...(page && { page: page + 1 }),
        },
      },
    );
  },
};
