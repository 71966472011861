import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { AxiosResponse } from 'axios';
import { CustomStyleAssetDTO, CustomStyleDTO } from '../models/CustomStyle';
import CustomStylesService from '../services/CustomStylesService';

export const keyOfGetCustomStyleById = (id: string) => ['getCustomStyleById', id];

export const useGetCustomStyleById = (
  id: string,
  options?: Omit<UseQueryOptions<any, any>, any>,
) => {
  return useQuery(
    keyOfGetCustomStyleById(id),
    async (): Promise<CustomStyleDTO> => {
      const request: any = await CustomStylesService.getCustomStyleById(id);
      return CustomStyleDTO.fromData(request);
    },
    {
      enabled: !!id,
      ...options,
    },
  );
};

export const keyOfGetCustomStyleAssets = (id: string) => ['getCustomStyleAssets', id];

export const useGetCustomStyleAssets = (id: string, perPage = 128): UseQueryResult<CustomStyleAssetDTO[]> => {
  return useQuery(
    keyOfGetCustomStyleAssets(id),
    async ({ pageParam = 1 }) => {
      const request = await CustomStylesService.getCustomStyleAssets(id, pageParam, perPage);
      return CustomStyleAssetDTO.fromDataList(request?.data) ?? [];
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );
};

export const getCustomStylesListPagesResult = (data: any): any => {
  if (!data || !data.pages) {
    return [];
  }

  return data.pages.reduce((acc: any, pageData: any) => {
    const stylesList = pageData?.data ?? [];
    return acc.concat(stylesList);
  }, []);
};

const keyOfGetCustomStylesWaitingForReview = () => ['getCustomStylesWaitingForReview '];

export const useGetCustomStylesWaitingForReview = (options?: any) => {
  const {
    data, fetchNextPage, isFetching, isSuccess, isLoading, hasNextPage, refetch, remove,
  }: UseInfiniteQueryResult<any> = useInfiniteQuery(
    keyOfGetCustomStylesWaitingForReview(),
    async ({ pageParam = 1 }) => {
      const request = await CustomStylesService.getCustomStylesWaitingForReview(pageParam, 120);
      return request ?? [];
    },
    {
      ...options,
      refetchOnMount: true,
    },
  );

  return {
    data: CustomStyleDTO.fromDataList(getCustomStylesListPagesResult(data)),
    fetchNextPage,
    isFetching,
    isSuccess,
    isLoading,
    hasNextPage,
    refetch,
    remove,
    total: data?.pages[0]?.total,
  };
};

interface UseMutateCustomStyleReviewParams {
  id: string;
  status: string;
  reason?: string;
}

export const useMutateCustomStyleReview = () => {
  return useMutation(({ id, status, reason }: UseMutateCustomStyleReviewParams) => {
    return CustomStylesService.reviewCustomStyle(id, status, reason);
  });
};

export const useGetCustomStyleCategories = (category?: string): UseQueryResult<any[]> => {
  return useQuery(['getCustomStyleCategories', category], async () => {
    const request = await CustomStylesService.getCustomStyleCategories();
    return request?.data ?? [];
  });
};

interface UpdateCustomStyleByIdParams {
  id: string;
  data: {
    name?: string;
    description?: string;
    category?: string;
    tags?: string[];
    containsTrademark?: string; // boolean to string
  };
}

export const useUpdateCustomStyleByIdMutation = () => {
  return useMutation((params: UpdateCustomStyleByIdParams) => {
    return CustomStylesService.updateCustomStyleById(params.id, params.data);
  });
};

export const useGetCustomStyleBySourceId = (
  sourceId: string,
  isAiGenerated?: boolean,
): UseQueryResult<any> => {
  return useQuery(
    ['useGetCustomStyleByMediaId', sourceId],
    async (): Promise<any> => {
      const response: AxiosResponse = await CustomStylesService.getGenerationById(sourceId);

      if (response?.data?.customStyleId) {
        const res = await CustomStylesService.getCustomStyleById(response?.data?.customStyleId);
        return res;
      }

      return null;
    },
    {
      enabled: !!sourceId && !!isAiGenerated,
      refetchOnWindowFocus: false,
    },
  );
};

export const useGetFeaturedCustomStyles = () => {
  return useQuery(
    ['getFeaturedThemes'],
    async () => {
      const response: AxiosResponse = await CustomStylesService.getFeaturedCustomStyles(800);
      return CustomStyleDTO.fromDataList(response?.data);
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const useMutateCustomStyleAddToFeatured = (options?: any) => {
  return useMutation((id: string) => {
    return CustomStylesService.addCustomStyleToFeatured(id);
  }, options);
};

export const useMutateCustomStyleRemoveFromFeatured = (options?: any) => {
  return useMutation((id: string) => {
    return CustomStylesService.removeCustomStyleFromFeatured(id);
  }, options);
};
