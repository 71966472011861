import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import './header.scss';
import { LogoWithText } from '../../../components/LogoWithText';
import { MenuLink } from '../../../components/NavLink';
import { HeaderProfile } from './components/HeaderProfile';
import { ProgressStatus } from '../../../components/ProgressStatus';
import {
  Stats,
} from '../../../services/StatsService';
import { useUser } from '../../../contexts';

export const Header = () => {
  const { user } = useUser();

  return (
    <div className="Header">
      <AppBar
        position="static"
        color="default"
      >
        <div className="header-base-component">
          <Toolbar>
            <div className="app-header-logo">
              <LogoWithText />
            </div>
            <div className="app-header-links">
              {user?.isCurator && (
                <>
                  <MenuLink to="/">New Batch</MenuLink>
                  <MenuLink to="/dashboard">Dashboard</MenuLink>
                </>
              )}
              {user?.isAdmin && (
                <>
                  <MenuLink to="/payouts">Payouts Manager</MenuLink>
                  <MenuLink to="/portfolio-ranker">Portfolio Ranker</MenuLink>
                  <MenuLink to="/challenge-manager">Challenge Manager</MenuLink>
                  <MenuLink to="/themes-manager">Themes Manager</MenuLink>
                </>
              )}
              {user?.isReviewer && (
                <>
                  <MenuLink to="/">Content</MenuLink>
                  <MenuLink to="/dashboard">Dashboard</MenuLink>
                </>
              )}
              {user?.isKeyworder && (
                <>
                  <ProgressStatus value={Stats.thisWeek?.total || 0} />
                </>
              )}
            </div>
            <div className="app-header-account">
              <HeaderProfile />
            </div>
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
};
