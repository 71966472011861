import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import './login.header.scss';
import { LogoWithText } from '../LogoWithText';

export const LoginHeader: React.FC = () => (
  <div className="LoginHeader">
    <AppBar position="static" color="default">
      <div className="header-base-component">
        <Toolbar>
          <div className="app-header-logo">
            <LogoWithText />
          </div>
        </Toolbar>
      </div>
    </AppBar>
  </div>
);
