import React from 'react';
import { useHistory } from 'react-router-dom';
import { useKey } from 'rooks';

import { KeyboardEvent } from '../../../services/KeyboardEventsService';
import { KEYBOARD_CONTEXT_MEDIA } from '../../../constants/keyboard.constants';
import { MediaItem } from '../../../components/MediaItem';
import { BatchMediaItemsProps, MediaItem as MediaItemProps } from '../../../types';
import { StyledButton } from '../../../components/StyledButton';
import './batch.media.items.scss';

export const BatchMediaItems = (props: BatchMediaItemsProps) => {
  const history = useHistory();
  const {
    items,
    batchMediaNoise,
    totalMediaCount,
    cursorPosition,
    setCursor,
    selectedItems,
    selectAll,
    user,
    mediaZoomOpen,
    toggleSelection,
  } = props;

  const selectionHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, i: number, multiple: boolean) => {
    toggleSelection(i, event.metaKey || event.ctrlKey || multiple);
  };

  const calculateItemsPerRow = () => {
    const mediaContainerWidth: any = document.querySelector('.BatchMediaContainer')?.clientWidth;
    const mediaElementWidth: any = document.querySelector('.MediaItem')?.clientWidth;

    return Math.floor(mediaContainerWidth / mediaElementWidth);
  };

  const scrollToCursor = () => {
    const cursorElement = (document.querySelector('.MediaItem.cursor') as HTMLElement);
    const mediaContainer = (document.querySelector('.batch-media-items') as HTMLElement);

    mediaContainer.scroll({
      top: cursorElement?.offsetTop - mediaContainer?.offsetTop,
      behavior: 'smooth',
    });
  };

  const getNoiseByMediaID = (mediaId: number) => {
    return batchMediaNoise?.find((item: any) => item.extId === mediaId)?.noise ?? null;
  };

  const changeCursor = (event: Event, direction: string) => {
    const itemsCountOnRow = calculateItemsPerRow();
    event.preventDefault();

    switch (direction) {
      case 'left':
        cursorPosition > 0 && setCursor(cursorPosition - 1);
        break;
      case 'right':
        cursorPosition < items.length - 1 && setCursor(cursorPosition + 1);
        break;
      case 'up':
        cursorPosition - itemsCountOnRow + 1 > 0 && setCursor(cursorPosition - itemsCountOnRow);
        break;
      case 'down':
        cursorPosition + itemsCountOnRow < items.length && setCursor(cursorPosition + itemsCountOnRow);
        break;
      default:
        break;
    }

    scrollToCursor();
  };

  const setCursorIndex = (index: number) => {
    setCursor(index);
  };

  useKey(37, (e) => KeyboardEvent.trigger(KEYBOARD_CONTEXT_MEDIA, () => changeCursor(e, 'left')));
  useKey(38, (e) => KeyboardEvent.trigger(KEYBOARD_CONTEXT_MEDIA, () => changeCursor(e, 'up')));
  useKey(39, (e) => KeyboardEvent.trigger(KEYBOARD_CONTEXT_MEDIA, () => changeCursor(e, 'right')));
  useKey(40, (e) => KeyboardEvent.trigger(KEYBOARD_CONTEXT_MEDIA, () => changeCursor(e, 'down')));
  useKey(16, () => KeyboardEvent.trigger(KEYBOARD_CONTEXT_MEDIA, () => toggleSelection(cursorPosition, true)));
  useKey(13, () => KeyboardEvent.trigger(KEYBOARD_CONTEXT_MEDIA, () => mediaZoomOpen(cursorPosition)));

  return (
    <div className="BatchMediaItems">
      {!items.length && (
        <div className="no-media-block">
          <p className="info-text"><span>🧐</span> There&apos;s no media in this batch.</p>
          <StyledButton
            className="batch-media-flag"
            variant="outlined"
            color="inherit"
            onClick={() => history.push('/')}
          >
            Go back Home
          </StyledButton>
        </div>
      )}
      {!!items.length && (
        <>
          <div className="batch-media-items-manager">
            <div>
              <p>
                Media:
                <strong>{items.filter((i: any) => i.show).length}</strong>
              </p>
              {user?.isKeyworder && (
                <p>
                  Total images:
                  <strong>{totalMediaCount}</strong>
                </p>
              )}
              {(user?.isCurator || user?.isReviewer) && (
                <>
                  <p>
                    Selected:
                    <span>{selectedItems.length}</span>
                  </p>
                  {selectedItems.length ? (
                    <p
                      className="batch-media-select-link"
                      onClick={() => selectAll(false)}
                    >
                      Deselect All
                    </p>
                  ) : (
                    <p
                      className="batch-media-select-link"
                      onClick={() => selectAll(true)}
                    >
                      Select All
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="batch-media-items">
            {items.map((item: MediaItemProps, i: number) => (
              <MediaItem
                item={item}
                noiseInfo={getNoiseByMediaID(item.id)}
                cursorIndex={cursorPosition}
                setCursor={setCursorIndex}
                key={i}
                index={i}
                mediaZoomOpen={() => mediaZoomOpen(i)}
                toggleSelection={(event: React.MouseEvent<HTMLDivElement, MouseEvent>, multiple: boolean) => selectionHandler(event, i, multiple)}
                user={user}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
