import React from 'react';
import { ChallengeType } from '../../constants/challenge.constants';

interface ChallengeTypeSelectorProps {
  challengeType: string;
  handleChallengeTypeChange: (value: string) => void;
}

export const ChallengeTypeSelector: React.FC<ChallengeTypeSelectorProps> = ({
  challengeType,
  handleChallengeTypeChange,
}: ChallengeTypeSelectorProps): JSX.Element => {
  return (
    <div className="ChallengeTypeSelector">
      <label htmlFor="challengeType" style={{ fontSize: 12, marginBottom: '4px', display: 'block' }}>Challenge Type</label>
      <div>
        {[ChallengeType.photo, ChallengeType.aiPhoto, ChallengeType.video, ChallengeType.aiVideo].map((item) => (
          <label style={{ fontSize: 14, marginLeft: 12 }} key={item.value}>
            <input
              type="radio"
              name="challengeType"
              value={item.value}
              checked={challengeType === item.value}
              onChange={() => handleChallengeTypeChange(item.value)}
            />
            {item.label}
          </label>
        ))}
      </div>
    </div>
  );
};
