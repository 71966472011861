import { KeyboardEventsProps } from '../types';
import { KEYBOARD_CONTEXT_MEDIA } from '../constants/keyboard.constants';

class KeyboardEventsService implements KeyboardEventsProps {
  public context: string = KEYBOARD_CONTEXT_MEDIA;

  public ctrlPressed?: boolean = false;

  public getContext = () => {
    return this.context;
  };

  public setContext = (value: string) => {
    this.context = value;

    return this.context;
  };

  private noActiveElements = () => {
    const activeElementType = (document.activeElement as HTMLInputElement).type;
    return !document.querySelector('.MuiPopover-root') && activeElementType !== 'textarea' && activeElementType !== 'input' && activeElementType !== 'text';
  };

  public trigger = (context: string | string[], callback: () => void, withCtrl?: boolean) => {
    if (withCtrl && !(this.ctrlPressed)) return;
    if (!withCtrl && (this.ctrlPressed)) return;

    if ((typeof context === 'string' ? context === this.context : context.includes(this.context)) && this.noActiveElements()) {
      callback();
    }
  };

}

export const KeyboardEvent = new KeyboardEventsService();
