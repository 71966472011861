import React, { Fragment } from 'react';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { LoadingProgress } from '../LoadingProgress';
import { Response, MediaAgencyProps } from '../../types';
import {
  useGetMediaAgencies,
  useAgencyMediaReject,
} from '../../queries';
import './media.agencies.scss';
import { WIRESTOCK_MARKETPLACE_AGENCY_NAME } from '../../constants/agencies.constants';

export const MediaAgencies = ({ media, onAgencyReject }: MediaAgencyProps): JSX.Element => {
  let rejectedAgency = 0;

  const rejectAgencyMedia = useAgencyMediaReject({
    onSuccess: () => {
      onAgencyReject(rejectedAgency);
    },
  });

  const { data: agenciesList, isLoading: isAgenciesLoading } = useGetMediaAgencies({
    cacheTime: 0,
    onSuccess: (res: Response) => {
      if (res.data) {
        const agencyData: any = {};

        res.data.forEach((agency: any) => {
          agencyData[agency.name] = {
            show: false,
            message: '',
            value: 0,
          };
        });
      }
    },
  });

  const getAgencyById = (id: number) => {
    let data: any = {};

    agenciesList?.data.forEach((agency: any) => {
      if (agency.id === id) {
        data = agency;
      }
    });

    return data;
  };

  const agencyRejectAction = (agencyId: number) => {
    rejectedAgency = agencyId;
    media.forEach((item: any) => {
      const agencyMediaId = item.agenciesMedia.data.filter((i: any) => i.agency_id === agencyId)[0].agency_media_id;
      const data = {
        mediaId: agencyMediaId,
        agency_rejection_reason_id: 8,
        reason: 'Media is not accepted for marketplace.',
      };

      rejectAgencyMedia.mutate(data);
    });
  };

  return (
    <div className="MediaAgencies">
      {(isAgenciesLoading) && (
        <LoadingProgress />
      )}
      {!media?.[0]?.agenciesMedia?.data?.length && (
        <p className="info-text">Media has no agencies selected.</p>
      )}
      {(!isAgenciesLoading) && agenciesList && media[0]?.agenciesMedia?.data?.map((agency: any, i: number) => {
        const agencyRejectStatus = (agency.status === 'rejected');

        return getAgencyById(agency.agency_id).name && (
          <Fragment key={i}>
            <div
              className={clsx(['media-agency-block', getAgencyById(agency.agency_id).name === WIRESTOCK_MARKETPLACE_AGENCY_NAME ? 'disabled' : ''])}
              key={i}
            >
              <div className="media-agency-block-title">
                <img
                  className="media-agencies-title-icon"
                  src={`${process.env.REACT_APP_ASSETS_BUCKET}/agencies/${getAgencyById(agency.agency_id).name}.svg`}
                  alt=""
                />
                <Typography className="media-agencies-title">{getAgencyById(agency.agency_id).description}</Typography>
              </div>
              {(getAgencyById(agency.agency_id).name !== WIRESTOCK_MARKETPLACE_AGENCY_NAME) && (
              <div className="media-agency-block-reject-icon">
                {agencyRejectStatus ? (
                  <RemoveCircleIcon color="error" />
                ) : (
                  <div onClick={() => agencyRejectAction(agency.agency_id)} className="agency-reject-icon">
                    <RemoveCircleOutlineIcon
                      color="action"
                    />
                  </div>
                )}
              </div>
              )}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};
