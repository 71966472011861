import styled from 'styled-components';

interface ChallengeMediaItemProps {
  selected?: boolean;
}

export const ChallengeMediaItem = styled.div<ChallengeMediaItemProps>`
  display: inline-block;
  width: 100%;
  aspect-ratio: 1;
  background-color: #F2F2F2;
  border-radius: 13px;
  position: relative;
  border: 1px solid transparent;
  cursor: pointer;
  overflow: hidden;
  transition: 100ms all;

  ${({ selected }) => selected && 'opacity: 0.5;'}
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ChallengeMediaItemFavorite = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  color: white;
  cursor: pointer;

  svg {
    font-size: 30px;
  }
`;

export const ChallengeMediaItemZoom = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  color: white;
  cursor: pointer;

  svg {
    font-size: 30px;
  }
`;

export const ChallengeMediaVideoIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const ChallengeMediaItemID = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 6px 16px;
  background-color: rgba(0,0,0,.5);
  color: white;
  border-radius: 0 13px 0 0;
  font-size: 12px;
`;

export const ChallengeMediaItemPosition = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 100%;
`;
