import React from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import useImage from 'use-image';
import { Loader, IconZoomIn, IconPlay } from '@wirestock.io/design-components';
import * as Styled from './ChallengeMediaItem.styles';

interface ChallengeMediaItemProps {
  id: string;
  thumbnail: string;
  favorite?: boolean;
  selected?: boolean;
  position?: number;
  type?: string;
  onFavorite?: (mediaId: string, favorite: boolean) => void;
  onZoom?: () => void;
  onSelect?: () => void;
}

export const ChallengeMediaItem = ({
  id,
  thumbnail,
  favorite,
  onFavorite,
  onZoom,
  selected,
  position,
  type,
  onSelect = () => {
  },
}: ChallengeMediaItemProps) => {
  const [media, status] = useImage(thumbnail);

  return (
    <Styled.ChallengeMediaItem
      onClick={onSelect}
      className={selected ? 'media-selected-highlight' : ''}
      selected={selected}
    >
      {position ? (
        <Styled.ChallengeMediaItemPosition>
          {position}
        </Styled.ChallengeMediaItemPosition>
      ) : (
        <>
          {onFavorite && (
            <Styled.ChallengeMediaItemFavorite onClick={() => onFavorite(id, !favorite)}>
              {favorite ? (
                <FavoriteIcon />
              ) : (
                <FavoriteBorderIcon />
              )}
            </Styled.ChallengeMediaItemFavorite>
          )}
        </>
      )}
      <Styled.ChallengeMediaItemZoom onClick={onZoom}>
        <IconZoomIn fill="white" width={24} />
      </Styled.ChallengeMediaItemZoom>
      {type === 'video' && (
        <Styled.ChallengeMediaVideoIcon onClick={onZoom}>
          <IconPlay fill="white" width={24} />
        </Styled.ChallengeMediaVideoIcon>
      )}
      <Styled.ChallengeMediaItemID>
        {id}
      </Styled.ChallengeMediaItemID>
      {!!(status === 'loaded') && (
        <img
          src={media && media.src}
          alt=""
        />
      )}
      {!!(status === 'loading') && (
        <Loader size={15} />
      )}
    </Styled.ChallengeMediaItem>
  );
};
