import Moment from 'moment';
import { ChallengeContentDTO } from './ChallengeContent.dto';
import { ChallengeMediaDTO, ChallengeMediaListResponseTransformer } from './ChallengeMedia.dto';

export const ChallengeListResponseTransformer = (challengeListResponse: any): Array<ChallengeDTO> => {
  return challengeListResponse?.map((challenge: any) => {
    return new ChallengeDTO(challenge);
  });
};

export class ChallengeDTO {

  id: string;

  title: string;

  type: string;

  description: string;

  isCompleted?: boolean;

  startDate: any;

  deadlineDate: any;

  prizeFund: string;

  submissionCount: number;

  favoritesCount: number;

  isPrivate: boolean;

  isOpenAccess: boolean;

  submissionDisabled: boolean;

  deadlineExceeded: boolean;

  sections: any;

  slug: string;

  content?: ChallengeContentDTO;

  winners: Array<ChallengeMediaDTO>;

  constructor(challengeData: any) {
    this.id = challengeData?.challengeId;
    this.title = challengeData?.title;
    this.type = challengeData?.type;
    this.description = challengeData?.description || '';
    this.isCompleted = challengeData?.isCompleted;
    this.startDate = new Date(challengeData?.startDate);
    this.deadlineDate = new Date(challengeData?.deadlineDate);
    this.prizeFund = challengeData?.prizeFund || '';
    this.isPrivate = !!challengeData?.isPrivate;
    this.isOpenAccess = challengeData?.isOpenAccess || false;
    this.submissionCount = challengeData?.submissionsCount;
    this.favoritesCount = challengeData?.favoritesCount;
    this.slug = challengeData?.slug;
    this.submissionDisabled = Date.parse(challengeData?.deadlineDate) - Date.now() <= 0 || !challengeData?.active;
    this.deadlineExceeded = Date.parse(challengeData?.deadlineDate) - Date.now() <= 0;
    this.winners = challengeData?.winners ? ChallengeMediaListResponseTransformer(challengeData?.winners?.data) : [];
    this.content = new ChallengeContentDTO(challengeData?.content);
  }
}

export const ChallengeRequestTransformer = (challengeData: ChallengeDTO): any => {
  return {
    isPrivate: challengeData.isPrivate,
    type: challengeData.type,
    title: challengeData.title,
    description: challengeData.description,
    slug: challengeData.slug,
    startDate: Moment(challengeData.startDate).toISOString(),
    deadlineDate: Moment(challengeData.deadlineDate).toISOString(),
    prizeFund: challengeData.prizeFund,
    prizeDetails: '',
    content: {
      coverPhotoUrl: challengeData.content?.coverPhotoUrl,
      album: challengeData.content?.album,
      sections: challengeData.content?.sections,
    },
    isOpenAccess: challengeData.isOpenAccess,
  };
};
