import React from 'react';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';

import { LoadingProgressProps } from '../../types';
import './loading.progress.scss';

export const LoadingProgress = ({ fixed, size, color }: LoadingProgressProps): JSX.Element => (
  <div className={clsx('LoadingProgress', { fixed })}>
    <CircularProgress size={size || 40} color={color || 'primary'} />
  </div>
);
