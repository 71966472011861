import { useQuery, useMutation, useInfiniteQuery } from 'react-query';
import { AxiosError } from 'axios';
import logger from '../utils/logger';
import ChallengeService from '../services/ChallengeService';
import { ChallengeDTO, ChallengeListResponseTransformer } from '../models/Challenge/Challenge.dto';
import { ChallengeSubmissionMediaDTO } from '../models/Challenge/ChallengeSubmissionMedia.dto';

export enum ChallengeKeysEnum {
  GetChallenges = 'GetChallenges',
  GetChallenge = 'GetChallenge',
  GetChallengeSubmissions = 'GetChallengeSubmissions',
}

export const getChallengeListPagesResult = (data: any) => {
  if (!data || !data?.pages) {
    return {
      data: [],
      total: 0,
    };
  }
  return data.pages.reduce(
    (acc: any, pageData: any) => {
      return {
        data: acc?.data?.concat(ChallengeListResponseTransformer(pageData?.data)),
        total: pageData?.meta?.pagination?.total,
      };
    },
    {
      data: [],
      total: 0,
    },
  );
};

export const useGetChallengesList = () => {
  const {
    data, fetchNextPage, isFetching, isSuccess, isLoading, hasNextPage, refetch, remove,
  } =
    useInfiniteQuery(
      ChallengeKeysEnum.GetChallenges,
      async ({ pageParam = 1 }) => {
        return ChallengeService.getChallenges(pageParam);
      },
      {
        refetchOnWindowFocus: false,
        getNextPageParam: (res: any) => {
          let nextPageNumber = res?.meta?.pagination?.links?.next?.split('page=')[1];
          if (nextPageNumber.includes('&')) {
            nextPageNumber = nextPageNumber.split('&')[0];
          }
          return nextPageNumber ? Number(nextPageNumber) : undefined;
        },
        retry: (failureCount, error: AxiosError) => {
          return error?.response?.status !== 403;
        },
      },
    );

  const challengeListResult = getChallengeListPagesResult(data);

  const mappedResult: Array<ChallengeDTO> = challengeListResult?.data;

  return {
    remove,
    refetch,
    isFetching,
    isSuccess,
    isLoading,
    hasNextPage,
    fetchNextPage,
    total: challengeListResult.total,
    data: mappedResult,
    hasResults: !!challengeListResult.data?.length && !isLoading,
    noResultWithFilters: !challengeListResult.data?.length,
  };
};

export const useGetChallenge = (challengeId: string, options = {}) =>
  useQuery(
    ChallengeKeysEnum.GetChallenge,
    async () => {
      const response: any = await ChallengeService.getChallenge(challengeId);
      return new ChallengeDTO(response);
    },
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`GetChallenge hook: ${error}`);
      },
    },
  );

export const useCreateChallenge = (options = {}) =>
  useMutation(
    (challengeData: ChallengeDTO) => ChallengeService.createChallenge(challengeData),
    {
      onError: (error: Error) => {
        logger.error(`useCreateChallenge hook: ${error}`);
      },
      ...options,
    },
  );

export const useUpdateChallenge = (options = {}) =>
  useMutation(
    (data: any) => ChallengeService.updateChallenge(data?.id, data?.challengeData),
    {
      onError: (error: Error) => {
        logger.error(`useUpdateChallenge hook: ${error}`);
      },
      ...options,
    },
  );

export const useSignChallengeFile = (options = {}) =>
  useMutation(
    (data: any) => ChallengeService.signChallengeFile(data?.challengeId, data?.file),
    {
      onError: (error: Error) => {
        logger.error(`useUploadChallengeFile hook: ${error}`);
      },
      ...options,
    },
  );

export const useUploadChallengeFile = (options = {}) =>
  useMutation(
    (data: any) => ChallengeService.uploadChallengeFile(data?.signedUrl, data?.file),
    {
      onError: (error: Error) => {
        logger.error(`useUploadChallengeFile hook: ${error}`);
      },
      ...options,
    },
  );

export const useDeleteChallenge = (options = {}) =>
  useMutation(
    (challengeId: string) => ChallengeService.deleteChallenge(challengeId),
    {
      onError: (error: Error) => {
        logger.error(`useDeleteChallenge hook: ${error}`);
      },
      ...options,
    },
  );

export const useGetChallengeSubmissions = (challengeData: any, options = {}) =>
  useQuery(
    ChallengeKeysEnum.GetChallengeSubmissions,
    async () => {
      const response: any = await ChallengeService.getChallengeSubmissions(challengeData?.id, challengeData?.shortlist, challengeData?.page);
      return {
        data: response?.data?.map((challengeMediaData: any) => {
          return new ChallengeSubmissionMediaDTO(challengeMediaData);
        }),
        pagination: response?.meta?.pagination,
      };
    },
    {
      cacheTime: 0,
      onError: (error: Error) => {
        logger.error(`useGetChallengeSubmissions hook: ${error}`);
      },
      ...options,
    },
  );

export const useUpdateChallengeSubmission = (options = {}) =>
  useMutation(
    (data: any) => ChallengeService.updateChallengeSubmission(data?.challengeId, data?.mediaId, data?.submissionData),
    {
      onError: (error: Error) => {
        logger.error(`useUpdateChallengeSubmission hook: ${error}`);
      },
      ...options,
    },
  );

export const useFinilizeChallenge = (options = {}) =>
  useMutation(
    (id: string) => ChallengeService.finalizeChallengeSubmission(id),
    {
      onError: (error: Error) => {
        logger.error(`useFinilizeChallenge hook: ${error}`);
      },
      ...options,
    },
  );
export const useGetChallengeShortlist = (challengeId: string, options = {}) =>
  useQuery(
    'GetChallengeShortlist',
    async () => {
      const response: any = await ChallengeService.getChallengeSubmissions(challengeId, true);
      return {
        data: response?.data?.map((challengeMediaData: any) => {
          return new ChallengeSubmissionMediaDTO(challengeMediaData);
        }),
        pagination: response?.meta?.pagination,
      };
    },
    {
      cacheTime: 0,
      onError: (error: Error) => {
        logger.error(`useGetChallengeSubmissions hook: ${error}`);
      },
      ...options,
    },
  );
