import styled from 'styled-components';
import { Tabs, Tab } from '@wirestock.io/design-components';

export const ChallengeSubmissionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChallengeSubmissionsHeader = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ChallengeSubmissionsActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: end;
`;

export const ChallengeSubmissionsBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChallengeSubmissionsPagination = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

export const ChallengeSubmissionsGrid = styled.div`
  flex: 1;
  display: grid;
  justify-items: start;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: max-content;
  gap: 16px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const ChallengeSubmissionsTab = styled(Tab)`
  font-weight: ${({ theme }) => theme.typography.REGULAR};
  padding: ${({ theme }) => theme.space.S16} 0;
`;

export const ChallengeSubmissionsTabs = styled(Tabs)`
  margin-bottom: ${({ theme }) => theme.space.S16};
`;

export const ChallengeSubmissionsLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;

  &:empty {
    display: none;
  }
`;

export const ChallengeFavoritesSelectorContainer = styled.div`
  display: flex;
  gap: 32px;
  height: 100%;
`;

export const ChallengeFavoritesEditor = styled.div`
  flex: 1;
  border-left: 1px solid ${({ theme }) => theme.color.BACKGROUND_2};
  padding: 16px;
`;

export const ChallengeMediaUserDetails = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.space.S32};
`;

export const FormElement = styled.div`
  margin-bottom: ${({ theme }) => theme.space.S16};
`;
