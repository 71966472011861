export const ChallengeType = {
  photo: {
    value: 'Photo',
    label: 'Photo',
  },
  aiPhoto: {
    value: 'AiPhoto',
    label: 'AI Photo',
  },
  video: {
    value: 'Video',
    label: 'Video',
  },
  aiVideo: {
    value: 'AiVideo',
    label: 'AI Video',
  },
};
