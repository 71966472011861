import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import DateAdapter from '@mui/lab/AdapterMoment';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import TextField from '@mui/material/TextField';
import { ChallengeDateTimePickerProps } from '../../types';
import './challenge.datetimepicker.scss';

export const ChallengeDateTimePicker = ({
  date,
  onChange,
  readOnly,
  label,
}: ChallengeDateTimePickerProps): JSX.Element => {
  const [value, setValue] = useState<any>(Moment(date).format('YYYY-MM-DD HH:mm:ss'));
  const handleChange = (momentDate: any) => {
    onChange(Moment(momentDate).format('YYYY-MM-DD HH:mm:ss'));
    setValue(momentDate);
  };

  useEffect(() => {
    setValue(date || 0);
  }, [date]);

  return (
    <div className="ChallengeDateTimePicker">
      <LocalizationProvider dateAdapter={DateAdapter} adapterLocale="en">
        <DateTimePicker
          inputFormat="YYYY-MM-DD HH:mm:ss"
          value={value}
          readOnly={readOnly}
          label={label}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </div>
  );
};
