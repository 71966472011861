import { Tab, Tabs, Text } from '@wirestock.io/design-components';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import * as Styled from './ThemesList.styles';
import { useGetCustomStylesWaitingForReview, useGetFeaturedCustomStyles } from '../../../queries';
import { CustomStyleDTO } from '../../../models/CustomStyle';

const placeholderImage = 'https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png';

export const ThemesList = () => {
  const { color } = useTheme();
  const { data: themes, total } = useGetCustomStylesWaitingForReview();
  const { data: featuredThemes } = useGetFeaturedCustomStyles();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState<string | number>('inReview');

  const navigateToThemeDetails = (themeId: string) => {
    history.push(`/themes-manager/themes/${themeId}`);
  };

  const renderThemeListItem = (theme: CustomStyleDTO, index: number) => (
    <Styled.ThemeListItem onClick={() => navigateToThemeDetails(theme.id)} key={index}>
      <Styled.ThemeListItemImage>
        <img
          src={!theme.coverPhotoUrl ? placeholderImage : theme.coverPhotoUrl}
          alt={theme.id}
          onErrorCapture={(e) => {
            e.currentTarget.src = placeholderImage;
          }}
        />
      </Styled.ThemeListItemImage>
      <Styled.ThemeListItemContent>
        <Text text={theme.name} tag="h6" />
        <Text text={`By ${theme.authorDisplayName}`} tag="p3" />
      </Styled.ThemeListItemContent>
    </Styled.ThemeListItem>
  );

  return (
    <>
      <Tabs activeTabValue={activeTab} textColor={color.TEXT_2} onChange={(activeTabValue) => setActiveTab(activeTabValue)}>
        <Tab label={`Themes In Review (${total ?? '...'})`} value="inReview">
          <Styled.ThemesListContainer>
            {themes?.map((theme, index) => renderThemeListItem(theme, index))}
          </Styled.ThemesListContainer>
        </Tab>
        <Tab label={`Featured (${featuredThemes?.length ?? '...'})`} value="featured">
          <Styled.ThemesListContainer>
            {featuredThemes?.map((theme, index) => renderThemeListItem(theme, index))}
          </Styled.ThemesListContainer>
        </Tab>
      </Tabs>
    </>
  );
};
