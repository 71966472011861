import { http } from './ApiService';
import {
  getItemFromLocalStorage,
} from './StorageService';
import { AUTH_DATA } from '../constants/storage.constants';
import { FlagDataProps, ReadyDataProps, RequestUrlsByRole } from '../types';
import {
  ROLE_CURATOR,
  ROLE_REVIEWER,
  ROLE_SUBMITTER,
  ROLE_RANKER,
} from '../constants/roles.constants';

export function getTopBatch(role: string) {
  const topBatchUrls: RequestUrlsByRole = {
    [ROLE_CURATOR]: '/api/batches/curating/top',
    [ROLE_REVIEWER]: '/api/batches/reviewing/top',
  };

  return http.get(
    topBatchUrls[role],
  );
}

export function assignBatch(batchId: number, role: string) {
  const { userId } = getItemFromLocalStorage(AUTH_DATA, true);
  const assignMediaUrls: RequestUrlsByRole = {
    [ROLE_CURATOR]: `/api/batches/${batchId}/assign`,
    [ROLE_REVIEWER]: `/api/batches/${batchId}/assign`,
  };

  return http.post(
    assignMediaUrls[role],
    { user_id: userId },
  );
}

export function getAssignedBatchMedia(batchId: number) {
  return http.get(
    `/api/batches/${batchId}/media`,
  );
}

export function getBatchMediaNoise(batchId: number) {
  return http.get(
    `${process.env.REACT_APP_OFFICE_BACKEND_BASE_URL}/api/batches/${batchId}`,
  );
}

export function flagBatch(flagData: FlagDataProps) {
  return http.post(
    `/api/batches/${flagData.batchId}/flag`,
    { ...flagData },
  );
}

export function readyBatch(readyData: ReadyDataProps) {
  return http.post(
    `/api/batches/${readyData.batchId}/ready`,
    { ...readyData },
  );
}

export function assignMedia(mediaCount: number | number[], role: string) {
  const { userId } = getItemFromLocalStorage(AUTH_DATA, true);
  const assignMediaUrls: RequestUrlsByRole = {
    [ROLE_SUBMITTER]: '/api/assignmentMedia/keywordings/top-media',
    [ROLE_RANKER]: '/api/assignmentMedia/ranking/top-media',
  };

  return http.post(
    assignMediaUrls[role],
    { user_id: userId, media_count: mediaCount },
  );
}

export function getAssignedMedia(role: string, page?: number) {
  const { userId } = getItemFromLocalStorage(AUTH_DATA, true);
  const requestAssignedMediaUrls: RequestUrlsByRole = {
    [ROLE_SUBMITTER]: `/api/assignmentMedia/keywordings/mine${page ? `?page=${page}` : ''}`,
    [ROLE_CURATOR]: `/api/batches/curating/assigned/${userId}${page ? `?page=${page}` : ''}`,
    [ROLE_REVIEWER]: `/api/batches/reviewing/assigned/${userId}${page ? `?page=${page}` : ''}`,
    [ROLE_RANKER]: `/api/assignmentMedia/keywording-rankings/mine${page ? `?page=${page}` : ''}`,
  };

  return http.get(
    requestAssignedMediaUrls[role],
  );
}

export function updateBatchMediaNote(data: any) {
  return http.put(
    `/api/batches/${data.batchId}/media`,
    {
      media_ids: data.body.media_ids,
      note: data.body.batchMedia.data.note_from_curator,
    },
  );
}

export function getRankerAssignedMedia() {
  return http.get(
    '/api/assignmentMedia/keywording-rankings/mine',
  );
}

export function getRankerKeywordingDefects() {
  return http.get(
    '/api/keywording-defects',
  );
}

export function saveKeywordingRank(mediaId: number, keywordingDefects: number[]) {
  return http.post(
    `/api/media/${mediaId}/keywording-rank`,
    { keywording_defect_ids: keywordingDefects },
  );
}
