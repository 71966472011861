import { http } from './ApiService';
import { ROLE_REVIEWER } from '../constants/roles.constants';

export function getMediaGeneratedKeywords(context: string) {
  return http.post(
    '/api/media/suggested-keywords/by-context',
    {
      context: context,
      count: 20,
    },
  );
}

export function getMediaDescriptionSuggestions(mediaId: number) {
  return http.get(
    `${process.env.REACT_APP_OFFICE_BACKEND_BASE_URL}/api/media/${mediaId}`,
  );
}

export function getMediaCategories() {
  return http.get(
    '/api/media/categories',
  );
}

export function getMediaAgencies() {
  return http.get(
    '/api/agencies',
    {
      params: {
        is_public: 1,
      },
    },
  );
}

export function getMediaAgenciesReasons() {
  return http.get(
    '/api/agencyMedia/rejection-reasons',
  );
}

export function mediaAgencyReject(data: any) {
  return http.post(
    `/api/agencyMedia/${data.mediaId}/rejections`,
    data,
  );
}

export function getMediaCountries() {
  return http.get(
    '/api/countries',
  );
}

export function getRejectionReasons() {
  return http.get(
    '/api/media/rejection-reasons',
  );
}

export function getMarketplaceRejectionReasons() {
  return http.get(
    '/api/media/marketplace-rejection-reasons',
  );
}

export function getRevisionReasons() {
  return http.get(
    '/api/media/revision-reasons',
  );
}

export function updateMedia(data: any) {
  return http.patch(
    '/api/media',
    data,
  );
}

export function acceptMedia(data: any, role: any) {
  return http.post(
    role === ROLE_REVIEWER ? '/api/media/accept' : '/api/media/content-ready',
    data,
  );
}

export function reviseMedia(mediaId: number, data: any) {
  return http.post(
    `/api/media/${mediaId}/revisions`,
    data,
  );
}

export function rejectMedia(data: any) {
  return http.post(
    '/api/media/reject',
    data,
  );
}

export function addMarketplaceRejections(data: any) {
  return http.post(
    '/api/media/marketplace-rejections',
    data,
  );
}

export function addMediaRejections(data: any) {
  return http.post(
    '/api/media/rejections',
    data,
  );
}

export function makeMediaAIGenerated(mediaIds: number[]) {
  return http.post(
    '/api/media/make-as-ai-generated',
    {
      media_ids: mediaIds,
    },
  );
}

export function mediaFavoriteRank(mediaId: number, score: number) {
  return http.put(
    `/api/media/${mediaId}/quality-properties`,
    {
      quality_properties: [
        {
          name: 'aesthetics_score',
          quantity: score,
        },
      ],
    },
  );
}

export function putSellingPermission(data: any) {
  return http.post(
    '/api/media/accept-for-portfolio',
    {
      media_ids: data?.mediaId,
    },
  );
}

export function getMediaContentIssues(mediaIds: number[]) {
  return http.get(
    '/api/media/content-issues',
    {
      params: {
        media_ids: mediaIds,
      },
    },
  );
}

export function regenerateMediaData(caption?: string) {
  return http.post(
    '/media/quality-evaluation/generate-by-caption',
    {
      caption,
    },
  );
}

export async function getMediaById(id: number) {
  return http.get(`/api/media/${id}`);
}
