import styled from 'styled-components';
import { MenuItem } from '@wirestock.io/design-components';

export const ChallengeManagerContainer = styled.div`
  display: flex;
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
  padding: ${({ theme }) => theme.space.S16};
  background-color: ${({ theme }) => theme.color.BACKGROUND_2};
  gap: ${({ theme }) => theme.space.S16};
  overflow: hidden;
`;

export const ChallengesListContainer = styled.div`
  overflow-y: auto;
  width: 400px;
  min-width: 400px;
  padding: ${({ theme }) => theme.space.S32};
  background-color: white;
  border-radius: ${({ theme }) => theme.radius.R13};
`;

export const ChallengesLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
`;

export const ChallengesListHeader = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.space.S32};
`;

export const ChallengesListHeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
`;

export const ChallengesListItem = styled(MenuItem)`
  &::before {
    border-radius: ${({ theme }) => theme.radius.R13};
  }
`;

export const ChallengesListItemTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.S4};
`;

export const ChallengesContentEditor = styled.div`
  display: flex;
  flex: 1;
  background-color: white;
  border-radius: ${({ theme }) => theme.radius.R13};
  justify-content: center;
`;
