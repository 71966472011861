import React from 'react';
import * as Styled from './ThemesManager.styles';
import { ThemesList } from './ThemesList';

export const ThemesManager = () => {
  return (
    <Styled.ThemesManagerContainer>
      <ThemesList />
    </Styled.ThemesManagerContainer>
  );
};
