import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { Select, Button } from '@wirestock.io/design-components';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import { useGetPayoutsList } from '../../queries';
import * as Styled from './PayoutsManager.styles';
import { PayoutsStatuses, PayoutsStates } from './constants';

type Order = 'asc' | 'desc';

interface Data {
  id: string;
  paymentAccountName: string;
  amountCents: number;
  paymentMethod: string;
  date: string;
  state: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderKey: Order;
  orderByKey: string;
  rowCount: number;
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

function createData(
  id: string,
  paymentAccountName: string,
  amountCents: number,
  paymentMethod: string,
  date: string,
  state: string,
): Data {
  return {
    id,
    paymentAccountName,
    amountCents,
    paymentMethod,
    date,
    state,
  };
}

export const PayoutsManager = () => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [state, setState] = useState('');
  const [status, setStatus] = useState('');
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(1);
  const [rows, setRows] = useState<any>({});

  const { data: payoutsList, refetch } = useGetPayoutsList({
    userId: undefined,
    state,
    status,
    page,
  });

  const markAs = (type: string) => {
    alert(`${type} ${selected.join(',')}`);
  };

  const approve = () => {
    alert(`approve ${selected.join(',')}`);
  };

  useEffect(() => {
    if (payoutsList?.data) {
      setRows((rowsState: any) => {
        return {
          ...rowsState,
          [page]: stableSort(payoutsList?.data?.map((payout: Data) =>
            createData(payout.id, payout.paymentAccountName, payout.amountCents, payout.paymentMethod, payout.date, payout.state),
          ), getComparator(order, orderBy)),
        };
      });
      setTotalCount(payoutsList?.listMetadata?.totalCount);
    }
  }, [payoutsList]);

  useEffect(() => {
    refetch();
  }, [page, state, status]);

  function descendingComparator<T>(a: T, b: T, orderByKey: keyof T) {
    if (b[orderByKey] < a[orderByKey]) {
      return -1;
    }
    if (b[orderByKey] > a[orderByKey]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    orderKey: Order,
    orderByKey: Key,
  ): (
      a: { [key in Key]: number | string },
      b: { [key in Key]: number | string },
    ) => number {
    return orderKey === 'desc'
      ? (a, b) => descendingComparator(a, b, orderByKey)
      : (a, b) => -descendingComparator(a, b, orderByKey);
  }

  function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const orderKey = comparator(a[0], b[0]);
      if (orderKey !== 0) {
        return orderKey;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'ID',
    },
    {
      id: 'paymentAccountName',
      numeric: false,
      disablePadding: false,
      label: 'Account name',
    },
    {
      id: 'amountCents',
      numeric: true,
      disablePadding: false,
      label: 'Amount($)',
    },
    {
      id: 'paymentMethod',
      numeric: false,
      disablePadding: false,
      label: 'Payment method',
    },
    {
      id: 'state',
      numeric: false,
      disablePadding: false,
      label: 'State',
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },
  ];

  function EnhancedTableHead(props: EnhancedTableProps) {
    const {
      onSelectAllClick,
      orderKey,
      orderByKey,
      numSelected,
      rowCount,
      onRequestSort,
    } =
      props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={selected.length > 0}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderByKey === headCell.id ? orderKey : false}
            >
              <TableSortLabel
                active={orderByKey === headCell.id}
                direction={orderByKey === headCell.id ? orderKey : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderByKey === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                  >
                    {orderKey === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Payouts
          </Typography>
        )}
        {numSelected > 0 && (
          <Styled.PayoutsListHeaderFilters>
            <div>
              <Button
                label="Mark as paid"
                type="tertiary"
                onClick={() => markAs('paid')}
              />
            </div>
            <div>
              <Button
                label="Mark as failed"
                type="destructive"
                onClick={() => markAs('failed')}
              />
            </div>
            <div>
              <Button
                label="Approve"
                type="primary"
                onClick={() => approve()}
              />
            </div>
          </Styled.PayoutsListHeaderFilters>
        )}
      </Toolbar>
    );
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows[page].map((row: any) => row.id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  useEffect(() => {
    if (Object.keys(rows).length && rows[page]) {
      rows[page] = stableSort(rows[page], getComparator(order, orderBy));
      setRows(rows);
    }
  }, [order, orderBy, page]);

  return (
    <Styled.PayoutsManagerContainer>
      <Styled.PayoutsListContainer>
        <Styled.PayoutsListHeader>
          <Styled.PayoutsListHeaderFilters>
            <Select
              label="State"
              value={state}
              options={[
                {
                  label: 'Created',
                  value: PayoutsStates.Created,
                },
                {
                  label: 'Approved',
                  value: PayoutsStates.Approved,
                },
                {
                  label: 'Paid',
                  value: PayoutsStates.Paid,
                },
                {
                  label: 'Funds held',
                  value: PayoutsStates.FundsHeld,
                },
                {
                  label: 'Taxes held',
                  value: PayoutsStates.TaxesHeld,
                },
                {
                  label: 'Taxes hold requested',
                  value: PayoutsStates.TaxesHoldRequested,
                },
                {
                  label: 'Payment pending',
                  value: PayoutsStates.PaymentPending,
                },
              ]}
              onSelect={(e, stateVal: any) => setState(stateVal)}
              placeholder="Select the state"
            />
            <Select
              label="Status"
              value={status}
              onSelect={(e, statusVal: any) => setStatus(statusVal)}
              options={[
                {
                  label: 'Successful',
                  value: PayoutsStatuses.OK,
                },
                {
                  label: 'Failed',
                  value: PayoutsStatuses.Failed,
                },
              ]}
              placeholder="Payment status"
            />
          </Styled.PayoutsListHeaderFilters>
        </Styled.PayoutsListHeader>
        <Styled.PayoutsListTableContainer>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <EnhancedTableToolbar numSelected={selected.length} />
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    orderKey={order}
                    orderByKey={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={totalCount}
                  />
                  <TableBody>
                    {rows[page]?.map((row: any, index: number) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.id}
                          </TableCell>
                          <TableCell align="left">{row.paymentAccountName}</TableCell>
                          <TableCell align="right">${parseInt(row.amountCents) / 100}</TableCell>
                          <TableCell align="left">{row.paymentMethod}</TableCell>
                          <TableCell align="left">{row.state}</TableCell>
                          <TableCell align="left">{Moment()
                            .date(row.date)
                            .format('LL')}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                rowsPerPageOptions={[30, 60]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
              />
            </Paper>
            <FormControlLabel
              control={(
                <Switch
                  checked={dense}
                  onChange={handleChangeDense}
                />
              )}
              label="Dense padding"
            />
          </Box>
        </Styled.PayoutsListTableContainer>
      </Styled.PayoutsListContainer>
    </Styled.PayoutsManagerContainer>
  );
};
