import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
} from './StorageService';
import { http } from './ApiService';
import { AUTH_DATA } from '../constants/storage.constants';
import { SELECTED_ROLE } from '../constants/roles.constants';
import { Credentials } from '../types';

export function login(credentials: Credentials) {
  return http.post(
    '/api/auth/login',
    credentials,
  );
}

/* eslint-disable camelcase */
export const getTokenExpireTime = (expires_in: number): number =>
  expires_in - Date.now();
/* eslint-enable camelcase */

export const isLoggedIn = (): boolean => {
  const authData = getItemFromLocalStorage(AUTH_DATA, true);

  if (!authData) {
    return false;
  }

  /* eslint-disable camelcase */
  const { access_token, expires_in } = authData;
  const tokenIsValid = !!expires_in && getTokenExpireTime(expires_in) >= 0;

  if (!tokenIsValid) {
    removeItemFromLocalStorage(AUTH_DATA);

    return false;
  }

  return !!access_token;
  /* eslint-enable camelcase */
};

export const logout = (): void => {
  removeItemFromLocalStorage(AUTH_DATA);
  removeItemFromLocalStorage(SELECTED_ROLE);
  window.location.reload();
};
