export class CustomStyleDTO {
  id: string;

  name: string;

  description: string;

  authorId: string;

  authorUsername: string;

  authorDisplayName: string;

  category?: {
    id: string;
    name: string;
  };

  isPublic: boolean;

  tags: string[];

  coverPhotoUrl: string;

  containsAI: boolean;

  status?: string;

  containsTrademark: boolean;

  featured?: boolean;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.authorId = data.authorId;
    this.authorUsername = data.authorUsername;
    this.authorDisplayName = data.authorDisplayName;
    this.category = data.category;
    this.isPublic = data.isPublic;
    this.tags = data.tags;
    this.coverPhotoUrl = data.coverPhotoUrl === 'n' ? null : data.coverPhotoUrl;
    this.containsAI = data.containsAi;
    this.status = data?.status;
    this.containsTrademark = data?.containsTrademark;
  }

  static fromData(data: any): CustomStyleDTO {
    return new CustomStyleDTO(data);
  }

  static fromDataList(data: any[]): CustomStyleDTO[] {
    return data.map(CustomStyleDTO.fromData);
  }
}
