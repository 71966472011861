import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useGetMediaCategories } from '../../queries';
import { Response, MediaCategoryProps } from '../../types';
import './media.category.scss';

export const MediaCategory = ({
  categoryId,
  onChange,
}: MediaCategoryProps): JSX.Element => {
  const [category, setCategory] = useState<any>(0);
  const [categoryList, setCategories] = useState<any>([]);
  const handleChange = (event: any) => {
    onChange(parseInt(event.target.value, 10));
    setCategory(event.target.value);
  };
  const { refetch } = useGetMediaCategories({
    onSuccess: (res: Response) => {
      setCategories(res.data);
    },
  });

  useEffect(() => {
    refetch();
    setCategory(categoryId || 0);
  }, [categoryId]);

  return (
    <div className="MediaCategory">
      <FormControl fullWidth>
        <Select
          value={category}
          label="Age"
          onChange={handleChange}
          placeholder="Select a category"
        >
          <MenuItem value="0">Select</MenuItem>
          {categoryList &&
            categoryList.map((item: any, index: number) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};
