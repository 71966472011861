import { ContentSectionDTO } from './ContentSection.dto';

export class ChallengeContentDTO {
  album: string[];

  coverPhotoUrl: string;

  sections: ContentSectionDTO[];

  constructor(contentData: any) {
    this.album = contentData?.album;
    this.coverPhotoUrl = contentData?.coverPhotoUrl;
    this.sections = contentData?.sections.map((section: any) => new ContentSectionDTO(section));
  }
}
