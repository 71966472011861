import { Theme } from '@wirestock.io/design-components';

export enum ThemeVariant {
  LIGHT = 'light',
  DARK = 'dark',
  DEFAULT = 'default',
}

export interface Elevation {
  DEFAULT: string;
  TOOLTIP: string;
  BOX: string;
}

export interface ZIndex {
  EDITOR: number;
  OVERLAY: number;
  POPUP: number;
  PROMPT: number;
  LOADER: number;
  ALERT: number;
}

export type ThemeVariantValues = `${ThemeVariant}`;

interface ThemeColors {
  blue: string;
  white: string;
  gray: string;
  lightGray: string;
  lightestGray: string;
  linkBlue: string;
  orange: string;
  red: string;
  black: string;
  green: string;
  whiteButtonHover: string;
  blueButtonHover: string;
  blackButtonHover: string;
  orangeButtonHover: string;
  transparentBlack: string;
  transparentDarkBlack: string;
  transparentLightBlack: string;
  mouseGray: string;
  rgbaGray: string;
  darkBlack: string;
  backgroundGray: string;
  backgroundGrayHover: string;
  darkGray: string;
  grayText: string;

  BABY_BLUE: string;
  DARK_GRAY: string;

  ACTION: string;
  SUCCESS: string;
  DANGER: string;
  BLACK: string;
  WHITE: string;
  WARNING: string;

  TEXT_1: string;
  TEXT_2: string;
  TEXT_3: string;
  TEXT_4: string;

  BACKGROUND_1: string;
  BACKGROUND_2: string;
  BACKGROUND_3: string;
  BACKGROUND_TRANSPARENT: string;
  BACKGROUND_TRANSPARENT_2: string;
  LANDING_BACKGROUND_1: string;
  LANDING_BACKGROUND_2: string;

  BORDER_OPAQUE: string;
  BORDER_ACCENT: string;

  POSITIVE: string;
  NEGATIVE: string;

  LINK_1: string;

  PREMIUM: string;
  PRIMARY_BUTTON: string;
  PRIMARY_BUTTON_HOVER: string;
  SECONDARY_BUTTON: string;
  SECONDARY_BUTTON_HOVER: string;
  TERTIARY_BUTTON: string;
  TERTIARY_BUTTON_HOVER: string;
  PREMIUM_BUTTON: string;
  PREMIUM_BUTTON_HOVER: string;
  DANGER_BUTTON: string;
  DANGER_BUTTON_HOVER: string;
}

interface ThemeShadows {
  contentShadow: string;
  popupShadow: string;
  modalShadow: string;
  buttonShadow: string;
  fixedFooterShadow: string;
  shadowMain: string;
  photoManagerShadow: string;
  smallComponentShadow: string;
}

interface ThemeFonts {
  mainRegular: string;
  mainSemiBold: string;
  mainBold: string;
  mainExtraBold: string;
}

interface ThemeFontSizes {
  small: string;
  default: string;
  medium: string;
  extraMedium: string;
  large: string;
  extraLarge: string;
  largest: string;
}

interface ThemeSpaces {
  extraSm: string;
  sx: string;
  sm: string;
  line: string;
  md: string;
  xmd: string;
  lg: string;
  big: string;
  extraBig: string;
  extraLg: string;

  S2: string;
  S4: string;
  S6: string;
  S8: string;
  S10: string;
  S12: string;
  S16: string;
  S24: string;
  S32: string;
  S48: string;
  S64: string;
  S128: string;
  S256: string;
}

interface ThemeRadius {
  sm: string;
  middle: string;
  main: string;
  lg: string;
  extraLg: string;
  half: string;

  R13: string;
  R2: string;
  R4: string;
  R8: string;
  R12: string;
  R16: string;
  R24: string;
  R26: string;
  R32: string;
  ROUND: string;
}

interface ThemeMediaQueries {
  sm: string;
  md: string;
  lg: string;
  extraLg: string;
  widthExtraLg: string;
  widthLg: string;
  widthMd: string;
  widthSm: string;
  widthEsm: string;
}

export interface FontFamily {
  BASE: string;
  SECONDARY: string;
}

export interface FontWeight {
  REGULAR: string;
  BOLD: string;
  SEMIBOLD: string;
}

export interface FontSize {
  FS72: string;
  FS42: string;
  FS36: string;
  FS24: string;
  FS20: string;
  FS16: string;
  FS14: string;
  FS12: string;
}

interface ThemeAnimation {
  easeInOutSine: string;
}

export interface WSTheme extends Theme {
  palette: any;
  color: ThemeColors;
  shadow: ThemeShadows;
  font: ThemeFonts;
  fontSize: ThemeFontSizes;
  space: ThemeSpaces;
  radius: ThemeRadius;
  mediaQuery: ThemeMediaQueries;
  animation: ThemeAnimation;
  name: ThemeVariantValues;
  typography: FontSize & FontWeight & FontFamily;
  elevation: Elevation;
  zIndex: ZIndex;

  footerHeight: string;
  headerHeight: string;
  maxWrapperWidth: string;
  maxLandingWrapperWidth: string;
  maxWrapperWidthMd: string;
  portfolioBackgroundHeight: string;

  // circles sizes
  circleWeightLg: string;
  circleWeightSm: string;
  circleWeightXsm: string;

  // submission form inputs default size
  inputHeight: string;
  searchInputHeight: string;
  textAreaHeight: string;

  // buttons maximum with size
  buttonWidthMax: string;
  buttonWidthMd: string;
  buttonWidthSm: string;
  buttonWidthExtraSm: string;
  buttonWidthXXS: string;
  buttonHeight: string;
  buttonHeightExtraSm: string;

  // Default scroll width
  scrollWidth: string;
  scrollWidthLg: string;

  // Snackbar
  snackbarHeight: string;
  snackbarWidth: string;
}
