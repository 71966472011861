import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import { logout } from '../../../../../services/AuthService';
import './header.profile.scss';
import { HeaderMenu } from '../HeaderMenu';
import {
  Stats,
} from '../../../../../services/StatsService';
import { useUser } from '../../../../../contexts';

export const HeaderProfile = () => {
  const { user, changeRole } = useUser();
  const [open, setOpen] = useState(false);
  const [pageItem, setPageItem] = useState(0);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const availableRoles = user?.role?.filter((role) => role !== user?.selectedRole);
  const todayCount = Stats.today;

  if (user?.role?.includes('admin')) {
    !user?.role?.includes('curator') && availableRoles?.push('curator');
    !user?.role?.includes('keyworder') && availableRoles?.push('keyworder');
    !user?.role?.includes('ranker') && availableRoles?.push('ranker');
    !user?.role?.includes('reviewer') && availableRoles?.push('reviewer');
    !user?.role?.includes('admin') && availableRoles?.push('admin');
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (
    event: React.MouseEvent<EventTarget>,
    itemId: number,
  ) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setPageItem(itemId);
    setOpen(false);
  };

  const changeSelectedRole = (role: string) => {
    changeRole && changeRole(role);
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleLogout = () => {
    logout();
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className="HeaderProfile">
      <div className="header-profile-counts">
        {(user?.isCurator || user?.isReviewer) && (
          <p>
            Today
            <strong> ({todayCount?.total || 0})</strong>
          </p>
        )}
        {user?.isKeyworder && (
          <HeaderMenu />
        )}
      </div>
      <div className="header-profile-name">
        <p>
          <strong>{user?.displayName}</strong>
        </p>
      </div>
      <div className="header-profile-menu">
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          disableRipple
          onClick={handleToggle}
        >
          {user?.selectedRole}
          <ArrowDropDownRoundedIcon className="header-profile-menu-icon" />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'center top',
              }}
            >
              <Paper className="header-profile-dropdown">
                <ClickAwayListener
                  onClickAway={(event: any) => handleClose(event, pageItem)}
                >
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {availableRoles?.map((item, i) => (
                      <Link
                        key={i}
                        to="/"
                      >
                        <MenuItem
                          className="header-profile-dropdown-item"
                          onClick={() => changeSelectedRole(item)}
                        >
                          {item}
                        </MenuItem>
                      </Link>
                    ))}
                    <MenuItem onClick={handleLogout}>
                      Log out
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};
