export function setItemToLocalStorage(key: string, value: any, stringify?: boolean) {
  try {
    localStorage.setItem(key, stringify ? JSON.stringify(value) : value);
  } catch (err) {
    // ignore catch (nothing to do here)
  }
}

export function getItemFromLocalStorage(key: string, parse?: boolean) {
  try {
    const getValue = localStorage.getItem(key);
    return parse ? JSON.parse(getValue || '{}') : getValue;
  } catch (err) {
    // return null if there is something went wrong when getting item from local storage
    return null;
  }
}

export function removeItemFromLocalStorage(key: string) {
  localStorage.removeItem(key);
}

export function clearAllLocalStorageItems() {
  localStorage.clear();
}
