import {
  InputKeywords, InputText, Popup, PopupProps, Select, Checkbox, Textarea,
} from '@wirestock.io/design-components';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { RouteParams } from '../ThemesManager.types';
import { useGetCustomStyleById, useGetCustomStyleCategories, useUpdateCustomStyleByIdMutation } from '../../../queries';
import * as Styled from './ThemeReview.styles';

export const EditThemeDetailsPopup = ({ isOpen, onClose }: PopupProps) => {
  const { themeId } = useParams<RouteParams>();
  const { data: style, refetch } = useGetCustomStyleById(themeId);
  const { data: categories } = useGetCustomStyleCategories();
  const { mutateAsync: mutateStyleDetails, isLoading } = useUpdateCustomStyleByIdMutation();
  const [name, setName] = useState<string>(style?.name ?? '');
  const [description, setDescription] = useState<string>(style?.description ?? '');
  const [containsTrademark, setContainsTrademark] = useState<boolean>(style?.containsTrademark ?? false);
  const [tags, setTags] = useState<string[]>(style?.tags ?? []);
  const [categoryId, setCategoryId] = useState<string>(style?.category?.id ?? '');

  const categoriesOptions = categories?.map((category) => ({
    label: category.name,
    value: category.id,
  })) ?? [];

  const saveThemeDetails = async () => {
    await mutateStyleDetails({
      id: themeId,
      data: {
        name,
        description,
        tags,
        category: categoryId,
        containsTrademark: containsTrademark.toString(),
      },
    }, {
      onSuccess: () => {
        onClose();
        refetch();
      },
    });
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title="Edit Theme Details"
      maxWidth={860}
      showCancelOnDesktop
      submitLabel="Save"
      submitButtonProps={{ isLoading }}
      onSubmit={saveThemeDetails}
    >
      <Styled.EditThemeDetailsPopupForm>
        <InputText
          hasBackground={false}
          hasBorder
          value={name}
          onChange={(e) => setName(e?.currentTarget?.value)}
          label="Name"
        />
        <Textarea
          value={description}
          onChange={(e) => setDescription(e?.currentTarget?.value)}
          hasBackground={false}
          hasBorder
          label="Description"
        />
        <Select
          value={categoryId}
          onSelect={(_e, v) => setCategoryId(v as string)}
          hasBackground={false}
          hasBorder
          options={categoriesOptions}
          label="Category"
        />
        <InputKeywords
          defaultKeywords={tags}
          onChange={(e) => setTags(e)}
          label="Tags"
          hasBackground={false}
          hasBorder
        />
        <Checkbox
          label="Contains Trademark"
          checked={containsTrademark}
          onChange={() => setContainsTrademark(!containsTrademark)}
        />
      </Styled.EditThemeDetailsPopupForm>
    </Popup>
  );
};
