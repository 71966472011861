import React from 'react';

import NoCameraIcon from '../../assets/images/icons/no-camera.svg';
import { MediaCameraProps } from '../../types';
import './media.camera.info.scss';

export const MediaCameraInfo = ({ info }: MediaCameraProps): JSX.Element => (
  <div className="MediaCameraInfo">
    <>
      <div className="media-camera-info">
        {info?.camera ? (
          <p>{info?.camera}</p>
        ) : (
          <>
            <img src={NoCameraIcon} className="media-camera-info-icon" alt="" />
            <p>Camera is Missing</p>
          </>
        )}
      </div>
      <div className="media-size-info">
        <p>{`${info?.width}x${info?.height}`}</p>
      </div>
    </>
  </div>
);
