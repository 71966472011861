import {
  KeywordRelatedMediaInfo,
  KeywordValidationError,
  KeywordValidationWarning,
} from './mediaKeywords.types';
import { KeywordType, MediaOrientations, MediaTypes } from '../../types';
import { MediaType } from '../../constants/mediaTypes.constants';

export const addIdsToKeywords = (keywordsList: KeywordType[]): KeywordType[] => {
  return keywordsList?.map((keyword, index) => {
    return { ...keyword, id: index, selected: false };
  });
};

export const checkKeywordExistence = (keywordsList: KeywordType[] | KeywordValidationError[] | KeywordValidationWarning[], keywordValue: string): boolean => {
  return keywordsList?.findIndex((item) => {
    return item?.keyword?.toLowerCase() === keywordValue.toLowerCase();
  }) !== -1;
};

export const addDefaultKeywords = (mediaInfo: KeywordRelatedMediaInfo, keywords: KeywordType[]): KeywordType[] => {
  let keywordsWithDefaultKeywords = keywords;

  // Keyword manipulations with keyword 'vector'
  if (mediaInfo?.type === MediaTypes.Vector && !checkKeywordExistence(keywords, MediaType.vector)) {
    keywordsWithDefaultKeywords?.splice(3, 0, {
      media_id: mediaInfo.id,
      keyword: MediaType.vector,
      id: NaN,
      order: 3,
    });
  } else if (checkKeywordExistence(keywords, MediaType.vector)) {
    keywordsWithDefaultKeywords = keywords?.filter(
      (keyword) => keyword.keyword.toUpperCase() !== MediaType.vector,
    );
  }

  // Keyword manipulations with media orientation
  const isMediaPanoramic = (mediaInfo?.details?.data?.width > mediaInfo?.details?.data?.height) &&
      ((mediaInfo?.details?.data?.width / mediaInfo?.details?.data?.height) >= 2);

  if (isMediaPanoramic && !checkKeywordExistence(keywords, MediaOrientations.Panoramic)) {
    keywordsWithDefaultKeywords.push({
      media_id: mediaInfo.id,
      keyword: MediaOrientations.Panoramic,
      id: NaN,
    });
  }

  const isMediaVertical = mediaInfo?.details?.data?.height > mediaInfo?.details?.data?.width;
  if (isMediaVertical && !checkKeywordExistence(keywords, MediaOrientations.Vertical)) {
    keywordsWithDefaultKeywords.push({
      media_id: mediaInfo.id,
      keyword: MediaOrientations.Vertical,
      id: NaN,
    });
  }

  return addIdsToKeywords(keywordsWithDefaultKeywords);
};
