import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isLoggedIn } from '../../services/AuthService';

import { ReactNode, AuthProps } from '../../types';

const checkAuthStatusAndRedirect = (
  redirect: boolean,
  Component: ReactNode,
  props: object,
  path: string,
) =>
  redirect ? (
    <Component {...props} />
  ) : (
    <Redirect
      to={{
        pathname: path,
      }}
    />
  );

export const AuthRoute = ({
  component: Component,
  authRequired,
  ...rest
}: AuthProps) => (
  <Route
    {...rest}
    render={(props) => authRequired ? checkAuthStatusAndRedirect(isLoggedIn(), Component, props, '/login') : checkAuthStatusAndRedirect(!isLoggedIn(), Component, props, '/')}
  />
);

AuthRoute.defaultProps = {
  authRequired: false,
};
