import { useQuery, useMutation } from 'react-query';
import logger from '../utils/logger';
import {
  getTopBatch,
  assignBatch,
  getAssignedBatchMedia,
  getBatchMediaNoise,
  flagBatch,
  readyBatch,
} from '../services/BatchService';
import { FlagDataProps, ReadyDataProps } from '../types';
import { useAuthedQuery } from '../hooks';

export enum BatchKeysEnum {
  GetTop = 'getTop',
  GetAssignedBatch = 'getAssignedBatch',
  AssignBatch = 'assignBatch',
  GetBatchMedia = 'getBatchMedia',
  FlagBatch = 'flagBatch',
  ReadyBatch = 'readyBatch',
  GetBatchMediaNoise = 'getBatchMediaNoise',
}

export const useGetTopBatch = (role?: string, options = {}) =>
  useQuery(
    BatchKeysEnum.GetTop,
    () => getTopBatch(role ?? ''),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`GetTopBatch hook: ${error}`);
      },
    },
  );

export const useAssignBatch = (role?: string, options = {}) =>
  useMutation(
    (batchId: number) => assignBatch(batchId, role ?? ''),
    {
      onError: (error: Error) => {
        logger.error(`AssignBatch hook: ${error}`);
      },
      ...options,
    },
  );

export const useGetBatchMedia = (batchId: number, options = {}) =>
  useAuthedQuery(
    [BatchKeysEnum.GetBatchMedia, batchId],
    () => !!batchId && getAssignedBatchMedia(batchId),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`GetBatchMedia hook: ${error}`);
      },
    },
  );

export const useGetBatchMediaNoise = (batchId: number, options = {}) =>
  useAuthedQuery(
    [BatchKeysEnum.GetBatchMediaNoise, batchId],
    () => !!batchId && getBatchMediaNoise(batchId),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`GetBatchMedia hook: ${error}`);
      },
    },
  );

export const useFlagBatch = (options = {}) =>
  useMutation(
    (flagData: FlagDataProps) => flagBatch(flagData),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`FlagBatch hook: ${error}`);
      },
    },
  );

export const useReadyBatch = (options = {}) =>
  useMutation(
    (readyData: ReadyDataProps) => readyBatch(readyData),
    {
      ...options,
    },
  );
