import styled from 'styled-components';

export const ThemeReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  position: relative;
  padding: ${({ theme }) => `${theme.space.S24} ${theme.space.S24} 0`};
`;

export const ThemeReviewHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space.S24};
  width: 100%;
  background-color: ${({ theme }) => theme.color.BACKGROUND_1};
  padding: ${({ theme }) => `${theme.space.S24} 0`};
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.OVERLAY};
`;

export const ThemeReviewHeaderInfoBlock = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.S24};
`;

export const ThemeReviewHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.S8};
  
  input {
    font-size: ${({ theme }) => theme.typography.FS20};
    font-weight: ${({ theme }) => theme.typography.BOLD};
    line-height: 1.3;
  }
  
  a {
    text-decoration: none;
    line-height: 1;
  }
`;

export const ThemeReviewContent = styled.div`
  height: 100%;
  z-index: 0;
`;

export const ThemeDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  gap: ${({ theme }) => theme.space.S12};
`;

export const ThemeDetailItem = styled.div`
  display: flex;
  white-space: pre-line;
  gap: ${({ theme }) => theme.space.S8};
  align-items: center;
`;

export const EditThemeDetailsPopupForm = styled.div`
  padding-top: ${({ theme }) => theme.space.S24};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.space.S16};
    width: 100%;
`;
