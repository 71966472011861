import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { useHistory } from 'react-router-dom';
import { useTimeout } from 'rooks';
import { useSnackbar } from 'notistack';
import {
  useGetAssignedMedia,
  useAssignMedia,
} from '../../../../queries';
import { LoadingProgress } from '../../../../components/LoadingProgress';
import { StyledButton } from '../../../../components/StyledButton';
import { Response, DefaultUserProps } from '../../../../types';
import homeIllustration from '../../../../assets/images/home-illustration.png';

export const KeyworderView = ({ user }: DefaultUserProps): JSX.Element => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [mediaCount, setMediaCount] = useState<number | number[]>(50);
  const { start: redirectToBatchView } = useTimeout(() => {
    history.push('/batch');
  }, 10);
  const { isLoading, data: assignedBatch, remove: removeAssignedBatch }: any =
    useGetAssignedMedia({
      role: user?.selectedRole,
      page: 1,
      options: {
        retry: true,
        retryDelay: 1000,
        onSuccess: (res: Response) => {
          const response = res && res.data;

          if (!response.length) {
            enqueueSnackbar(
              'No assigned media. Please try to request new batch.',
            );
          }
        },
        onError: (error: any) => {
          enqueueSnackbar(
            error.data.message,
            {
              variant: 'error',
            },
          );
        },
      },
    });

  const { mutate: assignMediaMutation, isLoading: getBatchLoading } = useAssignMedia(user?.selectedRole, {
    onSuccess: (res: Response) => {
      if (res && res.message) {
        removeAssignedBatch();
      }
    },
    onError: (error: any) => {
      enqueueSnackbar(
        error.data.message,
        {
          variant: 'error',
        },
      );
    },
  });
  const getTopBatch = () => {
    assignMediaMutation(mediaCount);
  };
  const handleMediaCount = (event: any, value: number | number[]) => {
    setMediaCount(value);
  };

  useEffect(() => {
    if (
      assignedBatch &&
      assignedBatch.data.length &&
      (assignedBatch.data[0].id || assignedBatch.data.id)
    ) {
      removeAssignedBatch();
      redirectToBatchView();
    }
  }, [assignedBatch]);

  return (
    <div className="Home-element">
      {!!isLoading && <LoadingProgress />}
      {!isLoading && (
        <>
          <h1>All Done</h1>
          <img src={homeIllustration} alt="" />

          <div className="media-count-slider">
            <Box sx={{ width: 300 }}>
              <Slider
                aria-label="Always visible"
                min={10}
                max={100}
                value={mediaCount}
                step={5}
                valueLabelDisplay="on"
                onChange={handleMediaCount}
              />
            </Box>
          </div>

          <StyledButton
            className="home-element-button"
            variant="contained"
            color="primary"
            onClick={getTopBatch}
            disabled={getBatchLoading}
          >
            Get New Batch
          </StyledButton>
          {!!getBatchLoading && <LoadingProgress fixed size={20} />}
        </>
      )}
    </div>
  );
};
