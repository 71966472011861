import React from 'react';
import {
  Tag, Text, IconButton, IconArrowBack,
} from '@wirestock.io/design-components';
import { useTheme } from 'styled-components';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import * as Styled from './ThemeReview.styles';
import { ThemeReviewHeader } from './ThemeReviewHeader';
import { ThemeAssetsList } from './ThemeAssets';
import { useGetCustomStyleById } from '../../../queries';
import { RouteParams } from '../ThemesManager.types';

export const ThemeReview = () => {
  const history = useHistory();
  const { themeId } = useParams<RouteParams>();
  const { typography } = useTheme();
  const { data } = useGetCustomStyleById(themeId);

  return (
    <Styled.ThemeReviewContainer>
      <IconButton
        icon={<IconArrowBack width={30} height={30} />}
        onClick={() => history.push('/themes-manager')}
        size="large"
        variant="transparent"
      />
      <ThemeReviewHeader />
      <Styled.ThemeReviewContent>
        <Styled.ThemeDetailsContainer>
          <Styled.ThemeDetailItem>
            <Text text="Category" tag="p3" weight={typography.BOLD} />
            <Text text={`${data?.category?.name}`} tag="p3" />
          </Styled.ThemeDetailItem>
          <Styled.ThemeDetailItem>
            <Text text="Description" tag="p3" weight={typography.BOLD} />
            <Text text={`${data?.description}`} tag="p3" />
          </Styled.ThemeDetailItem>
          <Styled.ThemeDetailItem>
            <Text text="Tags" tag="p3" weight={typography.BOLD} />
            {data?.tags?.map((tag, index) => <Tag label={tag} key={index} />)}
          </Styled.ThemeDetailItem>
          <Styled.ThemeDetailItem>
            <Text text="Contains Trademark" tag="p3" weight={typography.BOLD} />
            <Text text={`${data?.containsTrademark ? 'Yes' : 'No'}`} tag="p3" />
          </Styled.ThemeDetailItem>
        </Styled.ThemeDetailsContainer>
        <ThemeAssetsList />
      </Styled.ThemeReviewContent>
    </Styled.ThemeReviewContainer>
  );
};
