import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { FormGroup } from '@mui/material';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useKey } from 'rooks';
import { KeyboardEvent } from '../../services/KeyboardEventsService';
import { KEYBOARD_CONTEXT_POPUP } from '../../constants/keyboard.constants';
import { StyledButton } from '../StyledButton';
import { OnlyShopReasonProps } from '../../types';
import { LoadingProgress } from '../LoadingProgress';
import { ACTION_REJECT } from '../../constants/actions.constants';
import {
  useAddMarketplaceRejections,
  useGetMarketplaceRejectionReasons,
  useUpdateSellingPermission,
} from '../../queries';
import './onlyshop.reason.scss';

export const OnlyShopReason = (props: OnlyShopReasonProps) => {
  const [cursorPosition, setCursorPosition] = useState(0);
  const { mediaList, dialogCloseHandler, onActionSuccess } = props;
  const [mediaTypes, setMediaTypes] = useState<any>([]);
  const { data: reasons } = useGetMarketplaceRejectionReasons();
  const [rejectValues, setRejectValues] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  const [customValue, setCustomValue] = useState('');
  const checkedItems = rejectValues.filter((i: any) => i.checked);

  const { mutate: addMediaRejections, isLoading: mediaRejectionLoading } =
    useAddMarketplaceRejections({
      onSuccess: () => {
        clearData();
        onActionSuccess(ACTION_REJECT);
      },
    });
  const {
    mutate: updateSellingPermission,
    isLoading: sellingPermissionLoading,
  } = useUpdateSellingPermission({
    onSuccess: () => {
      const customReasonIds: any = {
        image: 32,
        vector: 50,
        video: 74,
      };
      addMediaRejections({
        media_ids: mediaList.map((media) => media.id),
        marketplace_rejection_reasons: [
          {
            id: rejectValues.filter((i: any) => i.checked)[0].id || customReasonIds[mediaTypes[0]],
            reason:
              customValue ||
              rejectValues.filter((i: any) => i.checked)[0].value,
          },
        ],
      });
    },
  });

  const handleRejectChange = (id: number) => {
    const changedValues = rejectValues.map((reason: any) => {
      if (reason.id === id) {
        reason.checked = !reason.checked;
      }
      return reason;
    });
    setRejectValues([...changedValues]);
  };

  const clearData = () => {
    setCustomValue('');
    setSearchValue('');
    setRejectValues([
      ...rejectValues.map((media: any) => {
        media.checked = false;
        return media;
      }),
    ]);
    dialogCloseHandler(false);
  };

  const handleAction = () => {
    if (!customValue && checkedItems.length === 0) return;

    updateSellingPermission({
      mediaId: mediaList.map((media) => media.id),
    });
  };

  const onDialogClose = () => {
    clearData();
  };

  const scrollToCursor = () => {
    const cursorElement = (document.querySelector('.reason-list .MuiFormControlLabel-root.cursor') as HTMLElement);
    const mediaContainer = (document.querySelector('.reason-list') as HTMLElement);

    mediaContainer.scroll({
      top: cursorElement?.offsetTop - mediaContainer?.offsetTop,
      behavior: 'smooth',
    });
  };

  const checkReason = () => {
    const changedValues = rejectValues.map((reason: any, index: number) => {
      if (index === cursorPosition) {
        reason.checked = !reason.checked;
      }
      return reason;
    });
    setRejectValues([...changedValues]);
  };

  const changeCursor = (event: Event, direction: string) => {
    event.preventDefault();

    switch (direction) {
      case 'up':
        cursorPosition > 0 ? setCursorPosition(cursorPosition - 1) : setCursorPosition(rejectValues.length - 1);
        break;
      case 'down':
        cursorPosition < rejectValues.length - 1 ? setCursorPosition(cursorPosition + 1) : setCursorPosition(0);
        break;
      default:
        break;
    }

    scrollToCursor();
  };

  useKey(82, () =>
    KeyboardEvent.trigger(KEYBOARD_CONTEXT_POPUP, () => handleAction()),
  );
  useKey(38, (e) => KeyboardEvent.trigger(KEYBOARD_CONTEXT_POPUP, () => changeCursor(e, 'up')));
  useKey(40, (e) => KeyboardEvent.trigger(KEYBOARD_CONTEXT_POPUP, () => changeCursor(e, 'down')));
  useKey(13, () => KeyboardEvent.trigger(KEYBOARD_CONTEXT_POPUP, () => checkReason()));

  useEffect(() => {
    const mediaTypesList: any = Array.from(new Set(mediaList.map((i) => i.type)));
    setMediaTypes(mediaTypesList.map((item: any) => {
      return clsx({
        image: item === 'photo' || item === 'illustration',
        vector: item === 'vector',
        video: item === 'video',
      });
    }));
  }, [mediaList]);

  useEffect(() => {
    const reasonsData: any = [];

    reasons && reasons.data.forEach((item: any) => {
      reasonsData.push({
        id: item.id,
        value: item.description,
        checked: false,
        type: item.type,
      });
    });

    setRejectValues(reasonsData);
  }, [reasons]);

  return (
    <div className="RejectReason">
      <div className="reason-title">Only Shop Rejection</div>
      <div className="reason-search-bar">
        <TextField
          label="Search"
          variant="filled"
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
        />
      </div>
      <div className="reason-list">
        {(mediaRejectionLoading || sellingPermissionLoading) && (
          <LoadingProgress />
        )}
        {!mediaRejectionLoading && !sellingPermissionLoading && (
          <FormGroup aria-label="reason">
            {rejectValues.map((item: any, i: number) => {
              return mediaTypes.includes(item.type) && item.name !== 'custom' && (
                <FormControlLabel
                  key={i}
                  value={item.id}
                  checked={!!item.checked}
                  className={clsx({ cursor: cursorPosition === i })}
                  onChange={() => handleRejectChange(item.id)}
                  control={<Checkbox checked={!!item.checked} />}
                  disabled={checkedItems.length === 1 && !item.checked}
                  label={item.value}
                />
              );
            })}
          </FormGroup>
        )}
      </div>
      {!mediaRejectionLoading && !sellingPermissionLoading && (
        <>
          <div className="reason-custom-field">
            <TextField
              label="Custom reason"
              variant="outlined"
              value={customValue}
              onChange={(event) => setCustomValue(event.target.value)}
            />
          </div>
          <div className="reason-action-buttons">
            <StyledButton
              className="batch-media-flag"
              variant="outlined"
              color="inherit"
              onClick={() => onDialogClose()}
            >
              Close
            </StyledButton>
            {mediaTypes.length === 1 && (
              <StyledButton
                className="batch-media-flag"
                variant="outlined"
                color="error"
                onClick={handleAction}
                disabled={!customValue && checkedItems.length === 0}
              >
                Reject
              </StyledButton>
            )}
          </div>
        </>
      )}
    </div>
  );
};
