import { ApiService } from './ApiService';

const http = new ApiService(process.env.REACT_APP_AI_GENERATOR_API_URL);

export default {
  getCustomStyleById(id: string) {
    return http.get(
      `/api/custom-styles/${id}`,
    );
  },
  getCustomStyleAssets(id: string, page: number, perPage: number) {
    return http.get(`api/custom-styles/${id}/assets`, {
      params: {
        page,
        perPage,
        statuses: 'uploaded',
      },
    });
  },
  getCustomStylesWaitingForReview(page: number, perPage: number) {
    return http.get('api/custom-styles/review', {
      params: {
        page,
        perPage,
        statuses: 'completed',
        isPublic: 'true',
      },
    });
  },
  reviewCustomStyle(id: string, status: string, reason?: string) {
    return http.post(`api/custom-styles/${id}/review`, {
      status,
      rejectionReason: reason,
    });
  },
  getCustomStyleCategories() {
    return http.get('api/custom-styles/categories');
  },
  updateCustomStyleById(id: string, data: any) {
    return http.patch(`api/custom-styles/${id}`, data);
  },
  getGenerationById(id: string) {
    return http.get(`api/generations/by-generation-media-id/${id}`);
  },
  getFeaturedCustomStyles(perPage?: number) {
    return http.get('api/custom-styles/featured', {
      params: {
        perPage,
      },
    });
  },
  addCustomStyleToFeatured(id: string) {
    return http.post('api/custom-styles/featured', {
      id,
    });
  },
  removeCustomStyleFromFeatured(id: string) {
    return http.delete(`api/custom-styles/featured/${id}`);
  },
};
