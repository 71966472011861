import Moment from 'moment-timezone';
import { StatsProps } from '../types';
import {
  getItemFromLocalStorage,
} from './StorageService';
import { http } from './ApiService';
import { AUTH_DATA } from '../constants/storage.constants';
import { ROLE_CURATOR, ROLE_REVIEWER } from '../constants/roles.constants';

class StatsService implements StatsProps {
  public daily?: {
    total: number;
    data: [];
  };

  public weekly?: {
    total: number;
    data: [];
  };

  public monthly?: {
    total: number;
    data: [];
  };

  public today?: {
    total: number;
    data: [];
  };

  public thisWeek?: {
    total: number;
    data: [];
  };

  public thisMonth?: {
    total: number;
    data: [];
  };

  public paymentCycle?: {
    total: number;
    data: [];
  };

  constructor() {
    this.update();
  }

  public update = (role?: string) => {
    const timePeriods = [{
      unit: 'daily',
      name: 'daily',
      from: Moment().tz('GMT').startOf('month').format('YYYY-MM-DD'),
      to: Moment().tz('GMT').add(1, 'd').format('YYYY-MM-DD'),
    }, {
      unit: 'weekly',
      name: 'weekly',
      from: Moment().tz('GMT').subtract(30, 'd').format('YYYY-MM-DD'),
      to: Moment().tz('GMT').add(1, 'd').format('YYYY-MM-DD'),
    }, {
      unit: 'monthly',
      name: 'monthly',
      from: Moment().tz('GMT').startOf('year').format('YYYY-MM-DD'),
      to: Moment().tz('GMT').add(1, 'd').format('YYYY-MM-DD'),
    }, {
      unit: 'daily',
      name: 'today',
      from: Moment().tz('GMT').startOf('day').format('YYYY-MM-DD'),
      to: Moment().tz('GMT').add(1, 'd').format('YYYY-MM-DD'),
    }, {
      unit: 'daily',
      name: 'thisWeek',
      from: Moment().tz('GMT').startOf('week').format('YYYY-MM-DD'),
      to: Moment().tz('GMT').add(1, 'd').format('YYYY-MM-DD'),
    }, {
      unit: 'daily',
      name: 'thisMonth',
      from: Moment().tz('GMT').startOf('month').format('YYYY-MM-DD'),
      to: Moment().tz('GMT').add(1, 'd').format('YYYY-MM-DD'),
    }];
    const promises: any = [];

    role && timePeriods.forEach((period) => {

      promises.push(this.requestStats(period.unit, period.from, period.to, role).then((res: any) => {
        let calculatedNumber = 0;
        const responseData = res.data;

        responseData.forEach((data: any) => {
          calculatedNumber += data.media_count;
        });

        Object.assign(this, { [period.name]: { total: calculatedNumber, data: responseData } });
      }));
    });

    return Promise.all(promises);
  };

  private requestStats = (time_unit: string, period_from: string, period_to: string, role?: string) => {
    const { userId } = getItemFromLocalStorage(AUTH_DATA, true);

    return http.get(
      (role === ROLE_CURATOR || role === ROLE_REVIEWER) ? `/api/media/action-log/curated-summary?user_id=${userId}&time_unit=${time_unit}&period_from=${period_from}&period_to=${period_to}` :
        `/api/media/action-log/content-ready-summary?user_id=${userId}&time_unit=${time_unit}&period_from=${period_from}&period_to=${period_to}`,
    );
  };

}

export const Stats = new StatsService();
