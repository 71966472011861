import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { MediaDatePickerProps } from '../../types';
import './media.datepicker.scss';

export const MediaDatePicker = ({
  date,
  onChange,
  readOnly,
}: MediaDatePickerProps): JSX.Element => {
  const [value, setValue] = useState<any>(Moment().format());
  const handleChange = (momentDate: any) => {
    onChange(Moment(momentDate).format('YYYY-MM-DD'));
    setValue(momentDate);
  };

  useEffect(() => {
    setValue(date || 0);
  }, [date]);

  return (
    <div className="MediaDatePicker">
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DesktopDatePicker
          inputFormat="YYYY-MM-DD"
          value={value}
          readOnly={readOnly}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </div>
  );
};
