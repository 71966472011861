import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';

import { MediaTypeSwitchProps } from '../../types';
import { MediaType } from '../../constants/mediaTypes.constants';
import './media.type.switch.scss';

export const MediaTypeSwitch = ({ type, onTypeChange }: MediaTypeSwitchProps): JSX.Element => {
  const [checked, setChecked] = useState(false);
  const handleChange = (event: any) => {
    onTypeChange(event.target.checked ? MediaType.illustration : MediaType.photo);
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (type === MediaType.photo) {
      setChecked(false);
    } else if (type === MediaType.illustration) {
      setChecked(true);
    }
  }, [type]);

  return (
    <div className="MediaTypeSwitch">
      <span className={checked ? 'disabled' : ''}>Photo</span>
      <Switch
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <span className={checked ? '' : 'disabled'}>Illustration</span>
    </div>
  );
};
