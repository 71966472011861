import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useTimeout } from 'rooks';
import { Pagination } from '@mui/material';
import { SmartToy } from '@mui/icons-material';
import FlagIcon from '@mui/icons-material/Flag';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import {
  useFlagBatch,
  useReadyBatch,
  useGetAssignedMedia,
  useGetMediaContentIssues,
  useUpdateSellingPermission,
} from '../../../queries';
import {
  BatchMediaContainerProps, Response, MediaItem, MediaContentIssue,
} from '../../../types';
import { LoadingProgress } from '../../../components/LoadingProgress';
import { StyledButton } from '../../../components/StyledButton';
import { BatchMediaItems } from '../BatchMediaItems';
import { MediaItemSlider } from '../../../components/MediaItemSlider';
import { DialogContainer } from '../../../components/Dialog';
import { FlagReason } from '../../../components/FlagReason';
import {
  Stats,
} from '../../../services/StatsService';
import './batch.media.container.scss';

export const BatchMediaContainer = (props: BatchMediaContainerProps) => {
  const history = useHistory();
  const {
    page,
    totalPage,
    batchMediaNoise,
    totalMediaCount,
    onPageChange,
    isLoading,
    batch,
    items,
    cursorPosition,
    setCursor,
    toggleSelection,
    selectedItems,
    onSubmitAll,
    onUpdateMedia,
    selectAll,
    user,
  } = props;
  const [mediaZoomIndex, setMediaZoomIndex]: any = useState(null);
  const [showFlagDialog, setShowFlagDialog] = useState<boolean>(false);
  const { remove: removeAssignedBatch } = useGetAssignedMedia({ role: user?.selectedRole });
  const { refetch: getContentIssues } = useGetMediaContentIssues(
    items?.map((item) => item?.id), {
      enabled: false,
    });
  const { start: redirectHome } = useTimeout(() => {
    removeAssignedBatch();
    history.push('/');
  }, 1000);
  const flagMutation = useFlagBatch({
    enabled: false,
    onSuccess: (res: Response) => {
      if (res.message) {
        redirectHome();
      }
    },
  });
  const { mutate: readyMutation, isLoading: readyMutationLoading } = useReadyBatch({
    enabled: false,
    onSuccess: (res: Response) => {
      if (res.message) {
        redirectHome();
      }
    },
    onError: async () => {
      const contentIssues: any = await getContentIssues();
      contentIssues?.data?.data?.forEach((mediaIssue: MediaContentIssue) => {

        onUpdateMedia([{
          id: mediaIssue?.media_id,
        } as MediaItem], {
          withError: true,
          submitted: false,
          validation: {
            data: {
              errors: mediaIssue?.errors,
            },
          },
        });
      });
    },
  });

  const batchReadyHandler = () => {
    const mediasWithoutSellingPermission = items.filter(item => item.is_selling_to_agencies_allowed === null);

    if (mediasWithoutSellingPermission.length) {
      updateSellingPermission({
        mediaId: mediasWithoutSellingPermission.map(item => item.id),
        is_selling_to_agencies_allowed: true,
      });
    } else {
      readyMutation({
        batchId: batch.id,
      });
    }
  };

  const { mutate: updateSellingPermission } = useUpdateSellingPermission({
    onSuccess: () => {
      readyMutation({
        batchId: batch.id,
      });
    },
  });

  const onActionSuccess = () => {
    // TODO: redirect to homepage
  };

  const mediaZoomOpen = (i: number) => {
    setMediaZoomIndex(i);
  };

  const changeMediaZoomIndex = (i: number) => {
    setMediaZoomIndex(i);
    setCursor(i);
  };

  const mediaZoomClose = () => {
    setMediaZoomIndex(null);
  };

  const batchFlagHandler = (batchId: number, message: string) => {
    flagMutation.mutate({
      batchId: batchId,
      reason: message,
    });
  };

  const getCreatorInfo = (collection: MediaItem[], property: string) =>
    (collection?.length && collection[0]?.creator?.data[property]) || '';

  return (
    <div className="BatchMediaContainer">
      {!!totalPage && totalPage > 1 && (
        <Pagination
          count={totalPage}
          page={page}
          onChange={onPageChange}
          className="batch-pagination"
        />
      )}

      {(!!batch.id || user?.isKeyworder || user?.isReviewer) && (
        <>
          <div className="batch-media-action-buttons">
            {user?.isCurator && (
              <>
                <StyledButton
                  className="batch-media-flag"
                  variant="outlined"
                  color="inherit"
                  onClick={() => setShowFlagDialog(true)}
                >
                  <FlagIcon />
                </StyledButton>
                <DialogContainer
                  open={showFlagDialog}
                  dialogCloseHandler={setShowFlagDialog}
                  onActionSuccess={onActionSuccess}
                >
                  <FlagReason
                    dialogCloseHandler={setShowFlagDialog}
                    batchFlagHandler={(message: string) => batchFlagHandler(batch.id, message)}
                  />
                </DialogContainer>
                <StyledButton
                  className="batch-media-ready"
                  variant="contained"
                  color="success"
                  disabled={readyMutationLoading}
                  onClick={batchReadyHandler}
                >
                  <ThumbUpIcon />
                </StyledButton>
              </>
            )}

            {user?.isReviewer && (
              <>
                <StyledButton
                  className="batch-media-flag"
                  variant="outlined"
                  color="inherit"
                  onClick={() => setShowFlagDialog(true)}
                >
                  <FlagIcon />
                </StyledButton>
                <DialogContainer
                  open={showFlagDialog}
                  dialogCloseHandler={setShowFlagDialog}
                  onActionSuccess={onActionSuccess}
                >
                  <FlagReason
                    dialogCloseHandler={setShowFlagDialog}
                    batchFlagHandler={(message: string) => batchFlagHandler(batch.id, message)}
                  />
                </DialogContainer>
                <StyledButton
                  className="batch-media-ready"
                  variant="contained"
                  color="success"
                  disabled={readyMutationLoading}
                  onClick={batchReadyHandler}
                >
                  <ThumbUpIcon />
                </StyledButton>
              </>
            )}

            {user?.isKeyworder && (
              <>
                <StyledButton
                  className="batch-media-ready batch-keywoarder-button"
                  variant="contained"
                  color="primary"
                  onClick={onSubmitAll}
                >
                  <ThumbUpIcon />
                </StyledButton>
              </>
            )}
          </div>
          {(user?.isCurator || user?.isReviewer) && (
            <div className="batch-media-info-container">
              <div>
                <div>
                  <p>Batch ID - {batch.id}</p>
                </div>
                {batch?.purpose === 'auto-fill-content-reviewing' && (
                  <div className="batch-media-ai-generated">
                    <SmartToy />
                  </div>
                )}
              </div>
              {!!items.length && (
                <div>
                  <div>
                    <p>
                      <strong>{getCreatorInfo(items, 'name')}</strong>
                      ({getCreatorInfo(items, 'email') || getCreatorInfo(items, 'username')})
                      {getCreatorInfo(items, 'subscription_plan') === 'premium' && (
                        <span className="premium-label">PREMIUM</span>)}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
          {user?.isKeyworder && (
            <div className="keyworder-info-container">
              <div>
                <div>
                  <p>Today: <strong>{Stats.today?.total || 0}</strong></p>
                </div>
                <div>
                  <p>This Week: <strong>{Stats.thisWeek?.total || 0}</strong></p>
                </div>
                <div>
                  <p>This Month: <strong>{Stats.thisMonth?.total || 0}</strong></p>
                </div>
              </div>
            </div>
          )}
          {!!isLoading && <LoadingProgress />}
          <div className="batch-media-files">
            {(!isLoading || !!items?.length) && (
              <BatchMediaItems
                items={items}
                totalMediaCount={totalMediaCount}
                cursorPosition={cursorPosition}
                setCursor={setCursor}
                toggleSelection={toggleSelection}
                mediaZoomOpen={mediaZoomOpen}
                selectedItems={selectedItems}
                selectAll={selectAll}
                user={user}
                batchMediaNoise={batchMediaNoise}
              />
            )}
          </div>
          {typeof mediaZoomIndex === 'number' && (
            <div className="batch-media-slideshow">
              <MediaItemSlider
                items={items}
                mediaZoomIndex={mediaZoomIndex}
                toggleSelection={toggleSelection}
                changeMediaZoomIndex={changeMediaZoomIndex}
                mediaZoomClose={mediaZoomClose}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
