export class CustomStyleAssetDTO {
  id: string;

  sourceMediaId: string;

  _sourceMediaStatus = '';

  isAIGenerated: boolean;

  status: string;

  styleId: string;

  url: string;

  constructor(data: any) {
    this.id = data.id;
    this.sourceMediaId = data.externalId;
    this.isAIGenerated = data.isAiGenerated;
    this.status = data.status;
    this.styleId = data.styleId;
    this.url = data.url;
  }

  static fromData(data: any) {
    return new CustomStyleAssetDTO(data);
  }

  static fromDataList(data: any) {
    return data?.map(CustomStyleAssetDTO.fromData);
  }

  get sourceMediaStatus(): string {
    return this._sourceMediaStatus;
  }

  set sourceMediaStatus(value: string) {
    this._sourceMediaStatus = value;
  }
}
