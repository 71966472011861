import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { CustomStyleAssetDTO } from '../../../../models/CustomStyle';
import { getMediaById } from '../../../../services/MediaService';
import { useGetCustomStyleAssets } from '../../../../queries';
import { RouteParams } from '../../ThemesManager.types';

export const useThemeAssetWithStatuses = () => {
  const [isGettingAssetsStatuses, setIsGettingAssetsStatuses] = useState<boolean>(false);
  const { themeId } = useParams<RouteParams>();
  const { data: initialAssets, isLoading } = useGetCustomStyleAssets(themeId);
  const [assetsList, setAssetsList] = useState<CustomStyleAssetDTO[]>([]);

  const getThemeAssetsStatuses = async () => {
    setIsGettingAssetsStatuses(true);
    const assetsListWithStatuses: CustomStyleAssetDTO[] = initialAssets ?? [];
    const assetsListWithSourceIds: CustomStyleAssetDTO[] = initialAssets?.filter((asset) => !!asset.sourceMediaId) ?? [];

    const [...assetStatusesResponse] = await Promise.all(assetsListWithSourceIds.map(async (asset) => {
      const media: any = await getMediaById(parseInt(asset.sourceMediaId));
      asset.sourceMediaStatus = media?.data?.status;
      return asset;
    }));

    for (const asset of assetsListWithStatuses) {
      if (asset.sourceMediaId) {
        asset.sourceMediaStatus = assetStatusesResponse
          ?.find((assetWithStatus) => assetWithStatus.id === asset.id)
          ?.sourceMediaStatus ?? '';
      }
    }

    setIsGettingAssetsStatuses(false);
    return assetsListWithStatuses;
  };

  useEffect(() => {
    (async function useEffectIIFE() {
      if (!isLoading) {
        setAssetsList(await getThemeAssetsStatuses());
      }
    }());

  }, [isLoading]);

  return {
    assetsList: isGettingAssetsStatuses ? initialAssets : assetsList,
    isLoading,
  };
};
