import styled from 'styled-components';

export const ChallengeContentContainer = styled.div`
  width: 100%;
`;

export const FormElement = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: ${({ theme }) => theme.space.S16};
  
  & > div:first-child {
    flex: 1;
  }
`;

export const FormElementWithText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.S8};
  margin-bottom: ${({ theme }) => theme.space.S16};

  & > div:first-child {
    flex: 1;
  }
`;

export const ChallengeMainInfo = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.S32};
  padding: ${({ theme }) => theme.space.S32};
  border-bottom: 1px solid ${({ theme }) => theme.color.BACKGROUND_2};
`;

export const ChallengePreviewImages = styled.div`
  padding: ${({ theme }) => theme.space.S32};
  border-bottom: 1px solid ${({ theme }) => theme.color.BACKGROUND_2};

  & > button {
    margin-top: 16px;
    font-weight: 500;
  }
`;

export const AddSectionButton = styled.div`
  padding: 32px;
  
  & > button {
    font-weight: 500;
  }
`;

export const ChallengePreviewImageItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;
  gap: 16px;

  &:empty {
    display: none;
  }
`;

export const ChallengePreviewImageItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    max-width: 100px;
    max-height: 100px;
  }
`;

export const ChallengeMainInfoForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ChallengePrivacySwitcher = styled.div`
  margin-bottom: 32px;
  margin-top: -32px;
  width: 200px;
`;

export const ChallengeMainInfoCoverImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  img {
    max-width: 500px;
    max-height: 200px;
  }
`;

export const ChallengeSectionBlock = styled.div`
  display: flex;
  padding: 32px;
  gap: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.color.BACKGROUND_2};
`;

export const ChallengeSectionBlockButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ChallengeSectionEditorButtons = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChallengeSectionBlockForms = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .DraftEditor-root {
    font-family: ${({ theme }) => theme.typography.BASE};
    line-height: 1.36;
    background-color: ${({ theme }) => theme.color.BACKGROUND_2};
    padding: 16px;
    border-radius: 13px;
    
    .public-DraftEditor-content {
      min-height: 100px;
    }
  }
`;

export const ChallengeSectionBlockImages = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${ChallengePreviewImageItems} {
    margin-top: 0;
  }

  & > button {
    font-weight: 500;
  }
`;

export const ChallengeContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ChallengeContentHeader = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.color.BACKGROUND_2};
  padding: 32px;
  box-sizing: border-box;
`;

export const ButtonsContainer = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 16px;
  
  button {
    flex: 1;
  }
`;

export const ChallengeContentHeaderInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ChallengeContentHeaderTitle = styled.div`
  display: flex;
  gap: 16px;
  max-width: 450px;
`;

export const ChallengeContentHeaderIcon = styled.div`
  display: flex;
  cursor: pointer;
`;

export const ChallengeContentHeaderActions = styled.div`
  display: flex;
  gap: 16px;
  align-items: self-start;
  
  & > button {
    font-weight: 500;
  }
`;

export const ChallengeContentBody = styled.div`
  flex: 1;
  overflow: auto;
`;
