import React, { useState, useEffect } from 'react';
import { useInterval } from 'rooks';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import './undo.snackbar.scss';

export const UndoSnackbar = (props: any): JSX.Element => {
  const { count, onFinish, onUndo } = props;
  const [show, setShow] = useState(true);
  const [progressCount, setProgressCount] = useState(0);

  const { stop }: any = useInterval(() => {
    setProgressCount((e) => e + 20);
  }, 1000, true);

  const cancelRequest = () => {
    onUndo();
    stop();
  };

  useEffect(() => {
    if (progressCount >= 100) {
      setShow(false);
      onFinish();
    }
  }, [progressCount]);

  return (
    <>
      {show && (
        <div className="UndoSnackbar">
          <div className="snackbar-icon">
            <InfoOutlinedIcon />
          </div>
          <div className="snackbar-title">
            {count.length} files submitted
          </div>
          <div className="snackbar-loader">
            <span className="snackbar-loader-seconds">{progressCount / 20}</span>
            <CircularProgress variant="determinate" value={progressCount} />
          </div>
          <div className="snackbar-undo-button" onClick={cancelRequest}>
            UNDO
          </div>
        </div>
      )}
    </>
  );
};
