import React, { useEffect, useState } from 'react';
import { Text, TextProps } from '@wirestock.io/design-components';
import * as Styled from './ClockCountdown.styles';

interface ClockCountdownProps extends Omit<TextProps, 'text'> {
  deadline: string;
}

export const ClockCountdown = ({ deadline, ...textProps }: ClockCountdownProps) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const leadingZero = (num: number) => {
    return num < 10 ? `0${num}` : num;
  };

  const getTimeLeft = (dateString: string) => {
    const time = Date.parse(dateString) - Date.now();
    if (time < 0) {
      setTimeLeft({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
    } else {
      const days = Math.floor(time / (1000 * 60 * 60 * 24));
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24) + days * 24;
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const seconds = Math.floor((time / 1000) % 60);
      setTimeLeft({
        days,
        hours,
        minutes,
        seconds,
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => getTimeLeft(deadline), 1000);

    return () => clearInterval(interval);
  }, [deadline]);

  const {
    days,
    hours,
    minutes,
    seconds,
  } = timeLeft;

  return (
    <Styled.ClockCountdown>
      {!!days && hours >= 48 && (
        <Text
          tag="p3"
          text={`${days} days `}
          {...textProps}
        />
      )}
      {!!hours && hours <= 48 && (
        <Text
          tag="p3"
          text={`${leadingZero(hours)}h`}
          {...textProps}
        />
      )}
      {!!minutes && hours <= 48 && (
        <Text
          tag="p3"
          text={` ${leadingZero(minutes)}m`}
          {...textProps}
        />
      )}
      {hours <= 48 && (
        <Text
          tag="p3"
          text={` ${leadingZero(seconds)}s`}
          {...textProps}
        />
      )}
    </Styled.ClockCountdown>
  );
};
