import React, {
  useState, SyntheticEvent,
} from 'react';
import moment from 'moment';
import { uuid } from 'uuidv4';
import {
  Text,
  IconButton,
  IconPlus,
  Menu,
  Avatar,
  Loader,
  IconLock,
} from '@wirestock.io/design-components';
import { useTheme } from 'styled-components';
import * as Styled from './ChallengeManager.styles';
import { ChallengeContent } from './ChallengeContent';
import { ClockCountdown } from '../../components/ClockCountdown';
import {
  useGetChallengesList,
  useCreateChallenge,
  useUpdateChallenge,
  useDeleteChallenge,
} from '../../queries';
import { ChallengeDTO } from '../../models/Challenge/Challenge.dto';
import { ChallengeType } from '../../constants/challenge.constants';

export const ChallengeManager = () => {
  const { color } = useTheme();
  const {
    data: challengeData, isLoading, refetch: refetchChallengeList, isSuccess, isFetching, hasNextPage, fetchNextPage,
  } = useGetChallengesList();
  const { mutate: createChallenge, isLoading: loadingCreateChallenge } = useCreateChallenge({
    onSuccess: async () => {
      await refetchChallengeList();
      setSelectedChallenge(0);
    },
  });
  const { mutate: updateChallenge, isLoading: loadingUpdateChallenge } = useUpdateChallenge({
    onSuccess: async () => {
      await refetchChallengeList();
    },
  });
  const { mutate: deleteChallenge } = useDeleteChallenge({
    onSuccess: async () => {
      await refetchChallengeList();
    },
  });
  const [selectedChallenge, setSelectedChallenge] = useState<number>(0);
  const challenges = [...(challengeData ?? [])];

  const addNewDraftChallenge = async () => {
    await handleCreateChallenge(new ChallengeDTO({
      type: ChallengeType.photo.value,
      isPrivate: true,
      title: 'Draft: Challenge',
      slug: `photography-challenge-${uuid()}`,
      startDate: moment().toLocaleString(),
      deadlineDate: moment().add(5, 'days').toLocaleString(),
    }));
  };

  const handleUpdateChallenge = (updatedData: any) => {
    updateChallenge({
      id: challenges[selectedChallenge].id,
      challengeData: updatedData,
    });
  };

  const handleCreateChallenge = (data: ChallengeDTO) => {
    createChallenge(data);
  };

  const handleDeleteChallenge = (challengeId: string) => {
    if (!challengeId) {
      setSelectedChallenge(0);
      return;
    }
    deleteChallenge(challengeId);
  };

  const handleChallengeListScroll = async (event: SyntheticEvent) => {
    const target = event.currentTarget;

    if (
      isSuccess &&
      target.scrollHeight - target.scrollTop < target.clientHeight + 200 &&
      !isFetching &&
      hasNextPage &&
      !isLoading
    ) {
      await fetchNextPage();
    }
  };

  return (
    <Styled.ChallengeManagerContainer>
      <Styled.ChallengesListContainer onScroll={handleChallengeListScroll}>
        <Styled.ChallengesListHeader>
          <Styled.ChallengesListHeaderTitle>
            <Text
              tag="h6"
              text="All Challenges"
            />
            <Text
              tag="span"
              text={`${challenges?.length} items`}
            />
          </Styled.ChallengesListHeaderTitle>
          <IconButton
            icon={<IconPlus />}
            isLoading={loadingCreateChallenge}
            onClick={addNewDraftChallenge}
            type="secondary"
          />
        </Styled.ChallengesListHeader>
        <Menu>
          {challenges?.map((challenge: ChallengeDTO, index: number) => (
            <Styled.ChallengesListItem
              key={index}
              selected={index === selectedChallenge}
              onSelect={() => setSelectedChallenge(index)}
            >
              <Avatar
                src={challenge?.content?.coverPhotoUrl}
                alt="User Name"
              />
              <Styled.ChallengesListItemTitle>
                <Text
                  tag="p3"
                  text={challenge?.title}
                />
                {!challenge?.deadlineExceeded && !challenge.isPrivate && (
                  <ClockCountdown
                    deadline={challenge?.deadlineDate?.toString()}
                    tag="span"
                  />
                )}
                {challenge?.isPrivate && (
                  <IconLock
                    width={20}
                    height={20}
                  />
                )}
              </Styled.ChallengesListItemTitle>
            </Styled.ChallengesListItem>
          ))}
        </Menu>
        {isFetching && (
          <Styled.ChallengesLoaderWrapper>
            <Loader
              color={color.black}
              isLoading
              size={20}
            />
          </Styled.ChallengesLoaderWrapper>
        )}
      </Styled.ChallengesListContainer>
      <Styled.ChallengesContentEditor>
        {isLoading ? (
          <Styled.ChallengesLoaderWrapper>
            <Loader
              color={color.black}
              isLoading
              size={30}
            />
          </Styled.ChallengesLoaderWrapper>
        ) : (
          <ChallengeContent
            selectedChallenge={challenges[selectedChallenge]}
            handleUpdateChallenge={handleUpdateChallenge}
            handleCreateChallenge={handleCreateChallenge}
            handleDeleteChallenge={handleDeleteChallenge}
            loadingUpdateChallenge={loadingUpdateChallenge}
          />
        )}
      </Styled.ChallengesContentEditor>
    </Styled.ChallengeManagerContainer>
  );
};
