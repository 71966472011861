import { ChallengeMediaDTO } from './ChallengeMedia.dto';

export class ChallengeSubmissionMediaDTO {

  challengeId: string;

  mediaId: string;

  position: number;

  reviewerNote: string;

  prize: string;

  favorite: boolean;

  media: ChallengeMediaDTO;

  constructor(challengeData: any) {
    this.challengeId = challengeData?.challengeId;
    this.mediaId = challengeData?.mediaId;
    this.position = challengeData?.position;
    this.reviewerNote = challengeData?.reviewerNote;
    this.prize = challengeData?.prize;
    this.favorite = !!challengeData?.favorite;
    this.media = new ChallengeMediaDTO(challengeData);
  }
}
