import styled, { css } from 'styled-components';
import { Skeleton, Snackbar } from '@wirestock.io/design-components';

export const ThemeAssetsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => `${theme.space.S24} 0`};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.S24};
`;

export const ThemeAssetsGrid = styled.div`
  ${({ theme }) => css`
    display: grid;
    position: relative;
    grid-gap: ${theme.space.S8};
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    width: 100%;
    margin: 0 auto auto;
  `};
`;

export const ThemeAssetsGridItem = styled.div`
  position: relative;
  padding-top: 100%;
  overflow: hidden;
`;

export const ThemeAssetsGridItemSkeleton = styled(Skeleton)`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  aspect-ratio: 1;
`;

export const ThemeAssetsGridItemImg = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 400ms all ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

export const ThemeAssetsGridItemLabel = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.space.S8};
  right: ${({ theme }) => theme.space.S8};
  cursor: pointer;
  padding: ${({ theme }) => theme.space.S8};
  filter: drop-shadow(0 0 14px rgba(0, 0, 0, 0.35));
  background-color: ${({ theme }) => theme.color.BACKGROUND_TRANSPARENT};
  border-radius: ${({ theme }) => theme.radius.R12};
  text-transform: capitalize;
`;

export const ThemeAssetsEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto 0;
`;

export const ThemeAssetsWarning = styled(Snackbar)`
  background-color: ${({ theme }) => theme.color.backgroundGray};
  color: ${({ theme }) => theme.color.TEXT_1};
  font-weight: ${({ theme }) => theme.typography.REGULAR};
`;
