import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router } from 'react-router';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from 'styled-components';
import { UserProvider } from './contexts';
import { theme } from './assets/scss/theme';
import { history } from './utils/history';
import { GrowTransition } from './components/Transitions';

export const Providers = ({ children }: { children: JSX.Element }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 60000,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <SnackbarProvider
            maxSnack={3}
            TransitionComponent={GrowTransition}
          >
            <UserProvider>
              {children}
            </UserProvider>
          </SnackbarProvider>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  );
};
