import React from 'react';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';

import { MediaExternalCopiesProps } from '../../types';
import './media.external.copies.scss';

export const MediaExternalCopies = ({ copies }: MediaExternalCopiesProps): JSX.Element => (
  <div className="MediaExternalCopies">
    {copies.map((copy: any, index: number) => (
      <div
        className="media-external-copy-item"
        key={index}
      >
        <a
          href={copy.image_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <OpenInNewRoundedIcon className="media-release-icon" />
        </a>
        <span>
          {copy.domain}
        </span>
        <div className="external-copies-button">
          <RemoveCircleOutlineIcon />
        </div>
      </div>
    ))}
  </div>
);
