import { useQuery, UseQueryResult } from 'react-query';
import { logout } from '../services/AuthService';

export const useAuthedQuery = (...props: any) => {
  const query: UseQueryResult<any, any> = useQuery(props[0], props[1], props?.[2]);

  if (query?.error?.status === 401) {
    logout();
  }

  return query;
};
