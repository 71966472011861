import React, { useState, useEffect } from 'react';
import { useCopyToClipboard } from 'react-use';
import clsx from 'clsx';
import { ReactSortable } from 'react-sortablejs';
import Chip from '@material-ui/core/Chip';
import { Input } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import InfoIcon from '@mui/icons-material/Info';
import { StyledButton } from '../StyledButton';
import { LoadingProgress } from '../LoadingProgress';
import { useDebouncedEffect } from '../../utils/useDebounceEffect';
import { useGetGeneratedKeywords } from '../../queries';
import { Response, MediaKeywordsProps, KeywordType } from '../../types';
import './media.keywords.scss';
import {
  addDefaultKeywords, addIdsToKeywords, checkKeywordExistence,
} from './mediaKeywords.helpers';
import { useMediaKeywordsValidation } from './useMediaKeywordsValidation';

export const MediaKeywords = (props: MediaKeywordsProps): JSX.Element => {
  const {
    defaultKeywords,
    suggestedKeywords,
    errors,
    canGenerate,
    mediaId,
    mediaInfo,
    mediaContext,
    onKeywordChange,
  } = props;
  // eslint-disable-next-line
  const specialCharacters = /[`!@#$%^&*()_+\=\[\]{};:"\\|,.<>\/?~]/;
  const [keywords, setKeywords] = useState<KeywordType[]>(defaultKeywords);
  const [aiKeywords, setAIKeywords] = useState<string[]>(suggestedKeywords || []);
  const [generatedKeywords, setGeneratedKeywords] = useState<string[]>([]);
  const [generateButtonClicked, setGenerateButtonClicked] = useState(false);
  const [keywordInputValue, setKeywordInputValue] = useState<string>('');
  const [generateInputValue, setGenerateInputValue] = useState<string>('');
  const [showCopyText, setShowCopyText] = useState(false);
  const { warnings, ignoreWarning } = useMediaKeywordsValidation(mediaInfo, keywords);
  const [, copyToClipboardHelper] = useCopyToClipboard();
  const { mutate: getGeneratedKeywords, isLoading } = useGetGeneratedKeywords({
    onSuccess: (res: Response) => {
      setGeneratedKeywords(res.data.keywords);
    },
  });

  const handleKeywordDelete = (index: number) => {
    setKeywords(addIdsToKeywords(keywords?.filter((_, i) => i !== index)));
  };

  const handleSelectKeyword = (id: number) => {
    const changedKeywords = keywords.map((keyword) => {
      if (keyword.id === id) {
        keyword.selected = !keyword.selected;
      }

      return keyword;
    });

    setKeywords([...changedKeywords]);
  };

  const handleGeneratedKeywordDelete = (index: number) => {
    setGeneratedKeywords(generatedKeywords?.filter((_, i) => i !== index));
  };

  const handleAIKeywordDelete = (index: number) => {
    setAIKeywords(aiKeywords?.filter((_, i) => i !== index));
  };

  const handleKeywordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const text = event.target.value;
    let splittedText = [];

    if (text.indexOf(',') > -1) {
      splittedText = text.split(',').filter(i => !!i.trim()).map(i => i.trim());
      splittedText = [...Array.from(new Set(splittedText))];
      splittedText = splittedText.filter((keyword: string) => {
        const keywordIsValid = !checkKeywordExistence(keywords, keyword) && !!keyword.trim();
        return keywordIsValid && keyword;
      });

      setKeywords(
        addIdsToKeywords([
          ...keywords,
          ...(splittedText.map((keyword: string, index: number) => {
            return {
              media_id: mediaId,
              keyword: keyword,
              order: keywords.length + index,
              id: NaN,
            };
          })),
        ]),
      );
      setKeywordInputValue('');
      return;
    }

    setKeywordInputValue(text);
  };

  const handleSubmitKeyword = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      const keywordIsValid = !checkKeywordExistence(keywords, keywordInputValue) && !!keywordInputValue.trim();
      if (keywords.length < 50 && keywordIsValid) {
        keywordInputValue &&
        setKeywords(
          addIdsToKeywords([
            ...keywords,
            {
              media_id: mediaId,
              keyword: keywordInputValue,
              order: keywords.length,
              id: NaN,
            },
          ]),
        );
        setKeywordInputValue('');
      } else {
        setKeywordInputValue('');
      }
    }
    if (event.key === 'Backspace' && !keywordInputValue.length) {
      handleKeywordDelete(keywords.length - 1);
    }
  };

  const handleGenerateInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => setGenerateInputValue(event.target.value);

  const handleGenerateKeyword = (
    event: React.KeyboardEvent<HTMLInputElement> | null,
    value: string | null,
  ) => {
    if ((event && event.key === 'Enter') || value) {
      getGeneratedKeywords(generateInputValue);

      setGenerateButtonClicked(true);
      setTimeout(() => setGenerateButtonClicked(false), 2000);
    }
  };

  const selectGeneratedKeyword = (keyword: string, index: number) => {
    if (keywords.length < 50 && !checkKeywordExistence(keywords, keyword)) {
      setKeywords(state => [...state, {
        media_id: mediaId,
        keyword: keyword,
        order: state.length,
        id: NaN,
      }]);
      handleGeneratedKeywordDelete(index);
    } else {
      handleGeneratedKeywordDelete(index);
    }
  };

  const selectAIKeyword = (keyword: string, index: number) => {
    if (keywords.length < 50 && !checkKeywordExistence(keywords, keyword)) {
      setKeywords(state => [...state, {
        media_id: mediaId,
        keyword: keyword,
        order: state.length,
        id: NaN,
      }]);
      handleAIKeywordDelete(index);
    } else {
      handleAIKeywordDelete(index);
    }
  };

  const handleDeleteAllKeywords = () => {
    setKeywords([]);
  };

  const handleCopyAllKeywords = () => {
    let text = '';
    keywords.forEach((keyword: any, index: number) => {
      text += `${keyword.keyword}${index + 1 !== keywords.length ? ',' : ''}`;
    });
    copyToClipboardHelper(text);

    setShowCopyText(true);
    setTimeout(() => {
      setShowCopyText(false);
    }, 2000);
  };

  const isKeywordWithError = (index: number) => {
    return !!errors && errors.findIndex((error) => error.index === index) !== -1;
  };

  const isKeywordIgnored = (keyword: KeywordType) => {
    return warnings?.findIndex(
      (keywordWarning) => keywordWarning?.ignored && keywordWarning.keyword === keyword.keyword,
    ) !== -1;
  };

  const isKeywordWithWarning = (keyword: KeywordType) => {
    return !!warnings?.length && checkKeywordExistence(warnings, keyword?.keyword) && !isKeywordIgnored(keyword);
  };

  const getKeywordTooltipInfo = (keyword: KeywordType, index: number) => {
    if (isKeywordWithError(index)) {
      return errors?.find((keywordError) => keywordError.index === index)?.errors?.join('\n');
    }

    return warnings?.find((keywordWarning) => keywordWarning.keyword === keyword.keyword)?.warning;
  };

  useDebouncedEffect(() => {
    mediaContext && setGenerateInputValue(mediaContext);
  }, [mediaContext], 1000);

  useEffect(() => {
    onKeywordChange(keywords?.map((el) => el?.keyword));
  }, [keywords]);

  useEffect(() => {
    const keywordsWidthIds = addIdsToKeywords(defaultKeywords);
    setKeywords(keywordsWidthIds);
  }, [defaultKeywords]);

  useEffect(() => {
    setAIKeywords(suggestedKeywords || []);
  }, [suggestedKeywords]);

  useEffect(() => {
    const keywordsWidthIds = addIdsToKeywords(defaultKeywords);
    setKeywords(keywordsWidthIds);
    setGeneratedKeywords([]);
    addDefaultKeywords(mediaInfo, keywordsWidthIds);
  }, [mediaId]);

  return (
    <div className="MediaKeywords">
      <div className="media-keywords-action-buttons">
        <div
          className={clsx('keywords-copy-icon', { copied: showCopyText })}
          onClick={handleCopyAllKeywords}
        >
          <span>Copied!</span>
          <FileCopyRoundedIcon />
        </div>
        <div
          className="keywords-delete-icon"
          onClick={handleDeleteAllKeywords}
        >
          <CloseRoundedIcon />
        </div>
      </div>
      <div className="media-keywords-keywords-container">
        <ReactSortable
          multiDrag
          list={keywords ?? []}
          setList={setKeywords}
          animation={100}
          onSelect={() => {
            debugger;
          }}
          delay={1}
          fallbackTolerance={3}
          ghostClass="ghost"
          dragClass="dragged"
          chosenClass="dragged"
          selectedClass="selected"
          className="media-keywords-draggable-element"
        >
          {keywords?.map((keyword, index) => (
            <div
              className="chip-container"
              key={index}
            >
              <Chip
                variant="outlined"
                color={(specialCharacters.test(keyword.keyword) || isKeywordWithError(index))
                  ? 'error'
                  : isKeywordWithWarning(keyword)
                    ? 'warning'
                    : 'default'}
                label={keyword.keyword}
                onClick={() => handleSelectKeyword(keyword.id)}
                onDelete={() => handleKeywordDelete(index)}
                deleteIcon={<CloseRoundedIcon />}
              />
              {(isKeywordWithWarning(keyword) || isKeywordWithError(index)) && (
                <div className="keyword-tooltip-container">
                  <div className="keyword-tooltip">
                    <div>
                      {isKeywordWithError(index)
                        ? (
                          <InfoIcon
                            fontSize="small"
                            htmlColor="#FF0000"
                          />
                        )
                        : (
                          <ReportProblemRoundedIcon
                            fontSize="small"
                            htmlColor="#FF7C04"
                          />
                        )}
                      <span>{getKeywordTooltipInfo(keyword, index)}</span>
                    </div>
                    {isKeywordWithWarning(keyword) && (
                      <span
                        onClick={() => ignoreWarning(keyword)}
                        className="keyword-tooltip-ignore"
                      >Ignore</span>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </ReactSortable>
        <Input
          defaultValue=""
          value={keywordInputValue}
          onChange={handleKeywordInputChange}
          onKeyDown={handleSubmitKeyword}
          className="keywords-input"
        />
      </div>
      <div className="media-keywords-status">
        <p>
          {keywords?.length < 12 && <span>(At least 12 keywords)</span>}{' '}
          {`${keywords?.length}/50`}
        </p>
      </div>
      {canGenerate && (
        <>
          <div className="media-keywords-generate">
            <Input
              className="media-keywords-generate-input"
              placeholder="Type a word"
              value={generateInputValue}
              onChange={handleGenerateInputChange}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleGenerateKeyword(event, null)}
            />
            <StyledButton
              className="media-keywords-generate-button"
              variant="contained"
              color="primary"
              onClick={() => handleGenerateKeyword(null, generateInputValue)}
              disabled={generateButtonClicked}
            >
              Generate
            </StyledButton>
          </div>
          <div className="media-keywords-generate-legends">
            <div className="media-keywords-legend-title">
              <span>Suggested</span>
            </div>
            <div className="media-keywords-legend-tags">
              <div className="ai-tags">
                <span />
                <span>AI</span>
              </div>
              <div className="generated-tags">
                <span />
                <span>Generated</span>
              </div>
            </div>
          </div>
          <div className="media-keywords-keywords-container">
            {aiKeywords.map((keyword, index) => (
              <Chip
                key={index}
                variant="outlined"
                color="primary"
                label={keyword}
                onDelete={() => handleAIKeywordDelete(index)}
                onClick={() => selectAIKeyword(keyword, index)}
                deleteIcon={<CloseRoundedIcon />}
              />
            ))}
            {isLoading && (
              <LoadingProgress />
            )}
            {!isLoading && !generatedKeywords.length && (
              <p className="info-text">Click &apos;Generate&apos; to see keyword suggestions.</p>
            )}
            {!isLoading && generatedKeywords.map((keyword, index) => (
              <Chip
                key={index}
                variant="outlined"
                color="success"
                label={keyword}
                onDelete={() => handleGeneratedKeywordDelete(index)}
                onClick={() => selectGeneratedKeyword(keyword, index)}
                deleteIcon={<CloseRoundedIcon />}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
