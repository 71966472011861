import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useKey } from 'rooks';
import useImage from 'use-image';
import ReactPlayer from 'react-player';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ReactImageMagnify from 'react-image-magnify';
import { LoadingProgress } from '../LoadingProgress';
import { KeyboardEvent } from '../../services/KeyboardEventsService';
import { KEYBOARD_CONTEXT_MEDIA, KEYBOARD_CONTEXT_MEDIA_SLIDER } from '../../constants/keyboard.constants';

import { MediaItemSliderProps } from '../../types';
import './media.item.slider.scss';

export const MediaItemSlider = ({
  items,
  mediaZoomIndex,
  toggleSelection,
  changeMediaZoomIndex,
  mediaZoomClose,
}: MediaItemSliderProps): JSX.Element => {
  const [media, status] = useImage(items[mediaZoomIndex]?.preview);
  const isVerticalMedia = media ? (media.height > media.width) : false;
  const itemFullImage = items[mediaZoomIndex].original_optimized_file_url || items[mediaZoomIndex].original_file_url || items[mediaZoomIndex].preview;

  const noOtherActiveElements = () => {
    const activeElementType = (document.activeElement as HTMLInputElement).type;
    return activeElementType !== 'textarea' && activeElementType !== 'input' && activeElementType !== 'text';
  };

  const previousItem = (e?: any) => {
    if (noOtherActiveElements()) {
      e && e.preventDefault();
      e && e.stopPropagation();
    }

    if (mediaZoomIndex > 0 && noOtherActiveElements()) {
      changeMediaZoomIndex(mediaZoomIndex - 1);
    }
  };

  const nextItem = (e?: any) => {
    if (noOtherActiveElements()) {
      e && e.preventDefault();
      e && e.stopPropagation();
    }

    if (mediaZoomIndex < items.length - 1 && noOtherActiveElements()) {
      changeMediaZoomIndex(mediaZoomIndex + 1);
    }
  };

  const mediaClickHandler = (e?: any) => {
    e && e.preventDefault();
    e && e.stopPropagation();
  };

  const closeSlider = () => {
    KeyboardEvent.setContext(KEYBOARD_CONTEXT_MEDIA);
    mediaZoomClose();
  };

  const openFullSize = () => {
    window.open(itemFullImage);
  };

  useKey(37, previousItem);
  useKey(39, nextItem);
  useKey(27, () => closeSlider());
  useKey(16, () => KeyboardEvent.trigger(KEYBOARD_CONTEXT_MEDIA_SLIDER, () => toggleSelection && toggleSelection(mediaZoomIndex, true)));
  useKey(13, () => KeyboardEvent.trigger(KEYBOARD_CONTEXT_MEDIA_SLIDER, () => openFullSize()));

  useEffect(() => {
    if (typeof mediaZoomIndex === 'number') {
      KeyboardEvent.setContext(KEYBOARD_CONTEXT_MEDIA_SLIDER);
    }
  }, [mediaZoomIndex]);

  useEffect(() => {
    if (!items[mediaZoomIndex]) {
      closeSlider();
    }
  }, [items]);

  return (
    <div className="MediaItemSlider">
      <div
        className="media-item-slider-backdrop"
        onClick={closeSlider}
      />
      <div
        className="media-item-slider-close"
        onClick={closeSlider}
      >
        <CloseRoundedIcon />
      </div>
      <div
        className={clsx('media-item-slider-container', {
          'vertical-image': isVerticalMedia,
          'horizontal-image': !isVerticalMedia,
          loaded: status === 'loaded',
        })}
        onClick={closeSlider}
      >
        <div
          className={clsx('media-item-slider-arrow-left', { disable: mediaZoomIndex === 0 })}
          onClick={previousItem}
        >
          <ArrowBackIosRoundedIcon />
        </div>
        <div className="media-item-preview">
          {!!(status === 'loaded') && (
            <div
              className="media-item-slider-open"
              onClick={() => openFullSize()}
            >
              <OpenInNewIcon />
            </div>
          )}
          <div
            className={clsx('media-item-preview-content')}
            onClick={mediaClickHandler}
          >
            {items[mediaZoomIndex]?.type === 'video' && (
              <ReactPlayer
                url={`${items[mediaZoomIndex]?.preview}`}
                controls
              />
            )}
            {(items[mediaZoomIndex]?.type !== 'video') && (
              <>
                {media && (status === 'loaded') && (
                  <div className="media-item-image">
                    <ReactImageMagnify {...{
                      smallImage: {
                        alt: 'Wristwatch by Ted Baker London',
                        isFluidWidth: true,
                        src: media.src,
                      },
                      largeImage: {
                        src: itemFullImage,
                        width: items[mediaZoomIndex].details?.data?.width / 2,
                        height: items[mediaZoomIndex].details?.data?.height / 2,
                      },
                      lensStyle: { backgroundColor: 'rgba(0,0,0,.6)', border: 'none' },
                      isHintEnabled: true,
                      shouldHideHintAfterFirstActivation: false,
                    }}
                    />
                  </div>
                )}
                {!!(status === 'loading') && (
                  <LoadingProgress />
                )}
                {!!(status === 'failed') && (
                  <div className="media-item-no-image">
                    <ImageRoundedIcon />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div
          className={clsx('media-item-slider-arrow-right', { disable: mediaZoomIndex === items.length - 1 })}
          onClick={nextItem}
        >
          <ArrowForwardIosRoundedIcon />
        </div>
        <div className="media-item-slider-status">
          <p>
            {mediaZoomIndex + 1}
            <span>/</span>
            {items.length}
          </p>
        </div>
      </div>
    </div>
  );
};
