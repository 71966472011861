import React from 'react';
import { NavLink } from 'react-router-dom';

import './nav.link.scss';

/* eslint-disable react/jsx-props-no-spreading */
export const MenuLink = (props: React.ComponentProps<typeof NavLink>): JSX.Element => {
  const { children } = props;

  return (
    <div className="NavLink">
      <NavLink exact {...props}>
        {children}
      </NavLink>
    </div>
  );
};
