import React, {
  MutableRefObject,
  useRef,
  useState,
  useEffect,
} from 'react';
import { Loader } from '@wirestock.io/design-components';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useSignChallengeFile, useUploadChallengeFile } from '../../../queries';
import * as Styled from './ChallengeUploadButton.styles';

interface ChallengeUploadButtonProps {
  challengeId: string;
  disabled?: boolean;
  onSuccess: (fileUrl?: string) => void;
}

export const ChallengeUploadButton = ({
  challengeId,
  disabled,
  onSuccess,
}: ChallengeUploadButtonProps) => {
  const id = Date.now() * Math.random();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [uploadedFile, setUploadedFile] = useState<any>();
  const { mutate: uploadChallengeFile, isLoading: uploadLoading } = useUploadChallengeFile({
    onSuccess: () => {
      onSuccess(uploadedFile.public_url);
      setUploadedFile(undefined);
      setSelectedFile(undefined);
    },
  });
  const { mutate: signChallengeFile, isLoading: signLoading } = useSignChallengeFile({
    onSuccess: (res: any) => {
      const response = res[0];
      setUploadedFile(response);
    },
  });
  const fileInput = useRef() as MutableRefObject<HTMLInputElement>;

  const handleFileSelect = async () => {
    const { current } = fileInput;

    if (current && (current?.files ?? current?.files?.length)) {
      signChallengeFile({
        challengeId: challengeId,
        file: current.files[0],
      });

      setSelectedFile(current.files[0]);
      current.value = '';
    }
  };

  useEffect(() => {
    uploadedFile && uploadChallengeFile({
      signedUrl: uploadedFile.upload_url,
      file: selectedFile,
    });
  }, [uploadedFile]);

  return (
    <Styled.ChallengeUploadButtonContainer>
      {signLoading || uploadLoading ? (
        <Loader
          isLoading={signLoading || uploadLoading}
          size={25}
          color="black"
        />
      ) : (
        <Styled.ChallengeUploadButtonIcon htmlFor={id.toString()}>
          <AttachFileIcon />
        </Styled.ChallengeUploadButtonIcon>
      )}
      <input
        id={id.toString()}
        type="file"
        ref={fileInput}
        disabled={disabled}
        onChange={handleFileSelect}
        accept="image/jpeg, image/webp"
      />
    </Styled.ChallengeUploadButtonContainer>
  );
};
