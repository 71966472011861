import React, { useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ZoomInRoundedIcon from '@material-ui/icons/ZoomInRounded';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import InfoIcon from '@mui/icons-material/Info';
import useImage from 'use-image';

import { MediaItemProps } from '../../types';
import { LoadingProgress } from '../LoadingProgress';
import './media.item.scss';

/* eslint-disable react/jsx-props-no-spreading */
export const MediaItem = (props: MediaItemProps): JSX.Element => {
  const {
    item,
    index,
    cursorIndex,
    noiseInfo,
    setCursor,
    toggleSelection,
    mediaZoomOpen,
    user,
  } = props;
  const [, copyToClipboardHelper] = useCopyToClipboard();
  const [copyStatus, setCopyStatus] = useState(false);
  const [media, status] = useImage(item.thumbnail);

  const copyIdNumber = (id: number) => {
    copyToClipboardHelper(id.toString());
    setCopyStatus(true);

    setTimeout(() => {
      setCopyStatus(false);
    }, 1000);
  };

  const mediaZoomClicked = (event: any) => {
    mediaZoomOpen(event);
  };

  return (
    <div
      className={clsx('MediaItem', {
        selected: item.selected,
        submitted: user?.isKeyworder && item.submitted,
        cursor: !user?.isKeyworder && index === cursorIndex,
      })}
      onClick={() => setCursor(index)}
    >
      <div
        className={clsx('media-type-label', { commercial: item.license === 'commercial' })}
        onClick={(event) => toggleSelection(event, false)}
      >
        {item.license === 'commercial' ? 'COM' : 'EDIT'}
      </div>
      <div
        className="media-item-zoom"
        onClick={mediaZoomClicked}
      >
        {(item.type === 'photo' || item.type === 'illustration') && (
          <ZoomInRoundedIcon />
        )}
        {(item.type === 'video') && (
          <PlayArrowIcon />
        )}
        {item.type === 'vector' && (
          <ZoomInRoundedIcon />
        )}
      </div>
      {(user?.isCurator || user?.isReviewer) && (
        <div
          className="media-item-selected-status"
          onClick={(event) => toggleSelection(event, true)}
        >
          {item.selected ? (
            <CheckCircleRoundedIcon className="media-item-selected-icon" />
          ) : (
            <RadioButtonUncheckedRoundedIcon className="media-item-deselected-icon" />
          )}
        </div>
      )}
      {item.withError && (
        <div className="media-item-selected-status error">
          <InfoIcon />
        </div>
      )}
      {noiseInfo && noiseInfo >= 3.5 && (
        <div
          className={clsx('media-item-warning')}
        ><Tooltip
          title={`Noise ${noiseInfo?.toFixed(2)}`}
          placement="top"
        >
          <ReportProblemIcon />
        </Tooltip>
        </div>
      )}
      <div
        className={clsx('media-item-id', { copied: copyStatus })}
        onClick={() => copyIdNumber(item.id)}
      >
        <p>
          ID
          <span>{item.id}</span>
        </p>
      </div>
      {!!(status === 'loaded') && (
        <div
          className="media-item-image"
          onClick={(event) => user?.isKeyworder && toggleSelection(event, true)}
        >
          <img
            src={media && media.src}
            alt=""
          />
        </div>
      )}
      {!!(status === 'loading') && (
        <LoadingProgress />
      )}
      {!!(status === 'failed') && (
        <>
          <div
            className="media-item-select-handler"
            onClick={(event) => toggleSelection(event, false)}
          />
          <div className="media-item-no-image">
            <ImageRoundedIcon />
          </div>
        </>
      )}
    </div>
  );
};
