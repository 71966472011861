import React from 'react';

import { MediaAdditionalInfoProps } from '../../types';
import './media.additional.info.scss';

export const MediaAdditionalInfo = ({
  info,
}: MediaAdditionalInfoProps): JSX.Element => (
  <div className="MediaAdditionalInfo">
    {info.note && (
      <div className="media-info-row">
        <div className="media-info-value">
          {info.note}
        </div>
      </div>
    )}
  </div>
);
