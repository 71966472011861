import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import ReactPlayer from 'react-player';
import useImage from 'use-image';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { RegenerateMediaDataProps } from '../../types';
import { LoadingProgress } from '../LoadingProgress';
import { useRegenerateMediaData } from '../../queries';
import './regenerate.mediaData.scss';

export const RegenerateMediaData = ({
  mediaItem,
  dialogCloseHandler,
  onActionSuccess,
}: RegenerateMediaDataProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [caption, setCaption] = useState('');
  const [media, status] = useImage(mediaItem?.preview);
  const { mutate: regenerateMediaData, isLoading } = useRegenerateMediaData({
    onSuccess: (res: any) => {
      onActionSuccess({ description: res?.description || '', keywords: res?.keywords || [], title: res?.title || '' });
      dialogCloseHandler(false);
    },
    onError: () => {
      enqueueSnackbar('AI tool is overloaded, please regenerate again.', {
        variant: 'error',
      });
    },
  });

  const handleRegenerateRequest = () => {
    regenerateMediaData(caption);
  };

  useEffect(() => {
    setCaption(mediaItem?.title || '');
  }, [mediaItem]);

  return (
    <div className="RegenerateMediaData">
      <div className="regenerate-media-preview">
        {mediaItem?.type === 'video' && (
          <ReactPlayer
            url={`${media?.src}`}
            controls
          />
        )}
        {mediaItem?.type !== 'video' && (
          <>
            {status === 'loaded' && (
              <div className="media-item-image">
                <img
                  src={media?.src}
                  alt=""
                />
              </div>
            )}
            {!!(status === 'loading') && <LoadingProgress />}
            {!!(status === 'failed') && (
              <div className="media-item-no-image">
                <ImageRoundedIcon />
              </div>
            )}
          </>
        )}
      </div>
      <div className="regenerate-media-input">
        <div className="form-group media-description">
          <div className="form-group-label">Caption</div>
          <div className="form-group-container">
            <TextField
              value={caption}
              inputProps={{
                spellCheck: true,
              }}
              name="description"
              multiline
              onChange={(e) => setCaption(e.target.value)}
              variant="standard"
            />
          </div>
        </div>
        <LoadingButton
          className="regenerate-button"
          loading={isLoading}
          variant="outlined"
          onClick={() => handleRegenerateRequest()}
        >
          Regenerate
        </LoadingButton>
      </div>
    </div>
  );
};
