import { useInfiniteQuery, useMutation } from 'react-query';
import { AxiosError } from 'axios';
import PortfolioService from '../services/PortfolioService';
import { PortfolioDTO, PortfolioListResponseTransformer } from '../models/Portfolio/Portfolio.dto';
import { useAuthedQuery } from '../hooks';

export enum PortfolioRankerKeysEnum {
  GetPortfolios = 'GetPortfolios',
  GetPortfolioGallery = 'GetPortfolioGallery',
}

export const getPortfolioListPagesResult = (data: any) => {
  if (!data || !data?.pages) {
    return {
      data: [],
      total: 0,
    };
  }
  return data.pages.reduce(
    (acc: any, pageData: any) => {
      return {
        data: acc?.data?.concat(PortfolioListResponseTransformer(pageData?.data)),
        total: pageData?.meta?.pagination?.total,
      };
    },
    {
      data: [],
      total: 0,
    },
  );
};

export const useGetPortfolioList = () => {
  const {
    data, fetchNextPage, isFetching, isSuccess, isLoading, hasNextPage, refetch, remove,
  } =
    useInfiniteQuery(
      PortfolioRankerKeysEnum.GetPortfolios,
      async ({ pageParam = 1 }) => {
        return PortfolioService.getPortfolios(pageParam);
      },
      {
        refetchOnWindowFocus: false,
        getNextPageParam: (res: any) => {
          const nextPageNumber = res?.meta?.pagination?.links?.next?.split('page=')[1] ?? undefined;
          return nextPageNumber ? Number(nextPageNumber) : undefined;
        },
        retry: (failureCount, error: AxiosError) => {
          return error?.response?.status !== 403;
        },
      },
    );

  const portfolioListResult = getPortfolioListPagesResult(data);
  const mappedResult: Array<PortfolioDTO> = portfolioListResult?.data;

  return {
    remove,
    refetch,
    isFetching,
    isSuccess,
    isLoading,
    hasNextPage,
    fetchNextPage,
    total: portfolioListResult.total,
    data: mappedResult,
    hasResults: !!portfolioListResult.data?.length && !isLoading,
    noResultWithFilters: !portfolioListResult.data?.length,
  };
};

export const useGetPortfolioGallery = (username: string) => {
  return useAuthedQuery(
    PortfolioRankerKeysEnum.GetPortfolioGallery,
    () => PortfolioService.getPortfolioGallery(username),
  );
};

export const useRankPortfolio = (options?: any) => {
  return useMutation((data: { userId: string, rankScore: number }) => {
    return PortfolioService.rankPortfolio(data?.userId, data?.rankScore);
  }, options);
};
