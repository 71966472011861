import styled from 'styled-components';

export const ChallengeUploadButtonContainer = styled.div`
  display: inline-block;
  margin: 12px;
  
  input {
    display: none;
  }
`;

export const ChallengeUploadButtonIcon = styled.label`
  display: inline-block;
  cursor: pointer;
`;
