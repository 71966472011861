import { http } from './ApiService';

export default {
  getPortfolios: (page: number) => {
    return http.get(
      `/api/users/ranked?page=${page}`,
    );
  },
  rankPortfolio: (userId: string, rankScore: number) => {
    return http.put(
      `/api/users/${userId}/rank`,
      {
        score: rankScore,
      },
    );
  },
  getPortfolioGallery: (username: string) => {
    return http.get(
      `api/search/portfolio/${username}?page=1&types[]=photo&types[]=video&types[]=illustration&types[]=vector`,
    );
  },
};
