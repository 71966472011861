import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { IApiClient } from '../types';
import { getItemFromLocalStorage } from './StorageService';
import logger from '../utils/logger';
import { AUTH_DATA } from '../constants/storage.constants';

export class ApiService implements IApiClient {
  private client: AxiosInstance;

  constructor(baseUrl?: string) {
    this.client = axios.create({
      baseURL: baseUrl ?? process.env.REACT_APP_BACKEND_BASE_URL,
      responseType: 'json',
      headers: { 'Content-Type': 'application/json' },
      timeout: 30 * 1000,
    });

    this.initializeResponseInterceptor();
    this.initializeRequestInterceptor();
  }

  private initializeResponseInterceptor = () => {
    this.client.interceptors.response.use(
      this.handleResponse,
      this.handleError,
    );
  };

  private initializeRequestInterceptor = () => {
    this.client.interceptors.request.use(
      this.handleRequest,
      this.handleError,
    );
  };

  private handleResponse = ({ data }: any) => data;

  private handleRequest = (config: AxiosRequestConfig) => {
    const token = this.getAccessToken();
    if (token && !Object.prototype.hasOwnProperty.call(config.headers, 'Authorization')) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else if (config.headers['Authorization'] === '') {
      delete config.headers['Authorization'];
    }
    return config;
  };

  private handleError = (error: any) => {
    logger.error(error);
    return Promise.reject(error);
  };

  private getAccessToken = () => {
    const authData = getItemFromLocalStorage(AUTH_DATA, true);
    const { access_token } = authData || { access_token: '' };
    return access_token;
  };

  async get<T, R = AxiosResponse<T>>(path: string, config?: AxiosRequestConfig): Promise<R> {
    return this.client.get<T, R>(path, config);
  }

  async post<T, B = any, R = AxiosResponse<T>>(path: string, body?: B, config?: AxiosRequestConfig): Promise<R> {
    return this.client.post<T, R>(path, body, config);
  }

  async put<T, B = any, R = AxiosResponse<T>>(path: string, body?: B, config?: AxiosRequestConfig): Promise<R> {
    return this.client.put<T, R>(path, body, config);
  }

  async patch<T, B = any, R = AxiosResponse<T>>(path: string, body?: B, config?: AxiosRequestConfig): Promise<R> {
    return this.client.patch<T, R>(path, body, config);
  }

  async delete<T, R = AxiosResponse<T>>(path: string, config?: AxiosRequestConfig): Promise<R> {
    return this.client.delete<T, R>(path, config);
  }
}

export const http = new ApiService();
