import { useEffect, useState } from 'react';
import {
  KeywordRelatedMediaInfo,
  KeywordValidationError,
  KeywordValidations,
  KeywordValidationWarning,
} from './mediaKeywords.types';
import { KeywordType } from '../../types';
import { checkKeywordExistence } from './mediaKeywords.helpers';

interface UseMediaKeywordsValidationResult extends KeywordValidations {
  ignoreWarning: (keyword: KeywordType) => void;
}

export const useMediaKeywordsValidation = (mediaInfo: KeywordRelatedMediaInfo, keywords: KeywordType[]): UseMediaKeywordsValidationResult => {
  const [validationErrors] = useState<KeywordValidationError[]>([]);
  const [validationWarnings, setValidationWarnings] = useState<KeywordValidationWarning[]>([]);

  const ignoreWarning = (keyword: KeywordType) => {
    setValidationWarnings(
      validationWarnings?.map((keywordWarning) => {
        if (keywordWarning.keyword === keyword.keyword) {
          return {
            ...keywordWarning,
            ignored: true,
          };
        }

        return keywordWarning;
      }),
    );
  };

  const validateKeywords = () => {

    // Check if a complex keyword doesn't contain already used simple keywords
    keywords?.forEach((keywordObj) => {
      if (keywordObj.keyword.split(' ').length > 1) {
        const complexKeywordWordOccurances = [];
        keywordObj.keyword.split(' ').forEach((keyword) => {
          if (checkKeywordExistence(keywords, keyword)) {
            complexKeywordWordOccurances.push(keyword);
          }
        });

        if (complexKeywordWordOccurances.length > 1) {
          const existingWarning = validationWarnings?.find(
            (validationWarning) => validationWarning?.keyword === keywordObj.keyword,
          ) ?? {};

          setValidationWarnings([
            ...validationWarnings,
            {
              keyword: keywordObj.keyword,
              warning: 'Keyword contains words that are already in use.',
              ...existingWarning,
            },
          ]);
        }
      }
    });
  };

  useEffect(() => {
    validateKeywords();
  }, [keywords]);

  return {
    errors: validationErrors,
    warnings: validationWarnings,
    ignoreWarning,
  };
};
