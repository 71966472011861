import React from 'react';
import clsx from 'clsx';
import { LinearProgress } from '@material-ui/core';

import { ProgressStatusProps } from '../../types';
import './progress.status.scss';

export const ProgressStatus = (props: ProgressStatusProps) => {
  let { value } = props;
  const maxValue = 750;

  if (value >= maxValue) {
    value = 750;
  }

  return (
    <div className={clsx('ProgressStatus', { completed: value >= maxValue })}>
      <div className="progress-status-numbers">
        <div>
          0
        </div>
        <div>
          400
        </div>
        <div>
          750
        </div>
      </div>
      <div className="progress-status-bar">
        <LinearProgress value={(value / maxValue) * 100} variant="determinate" />
      </div>
      <div className="progress-status-completed">
        <p>YOU NAILED IT!</p>
        <span>❤️</span>
      </div>
    </div>
  );
};
