import React, { useEffect, useState } from 'react';
import {
  Button,
  IconCheck,
  InputText,
  Loader,
  Select,
  Text,
  Textarea,
} from '@wirestock.io/design-components';
import * as Styled from '../ChallengeSubmissions.styles';
import { ChallengeSubmissionMediaDTO } from '../../../../models/Challenge/ChallengeSubmissionMedia.dto';
import { ChallengeMediaItem } from '../ChallengeMediaItem';
import { useGetChallengeSubmissions, useUpdateChallengeSubmission } from '../../../../queries';
import { ChallengeDTO } from '../../../../models/Challenge/Challenge.dto';
import { ChallengeMediaItemSlider } from '../../../../components/ChallengeMediaItemSlider';

interface ChallengeFavoritesSelectorProps {
  challenge: ChallengeDTO;
  refetchChallengeData: any;
}

export const ChallengeFavoritesSelector = ({
  challenge,
  refetchChallengeData,
}: ChallengeFavoritesSelectorProps) => {
  const [mediaZoomIndex, setMediaZoomIndex]: any = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [position, setPosition] = useState<string | number | undefined>();
  const [prize, setPrize] = useState<string>();
  const [reviewerNote, setReviewerNote] = useState<string>();
  const [submissions, setChallengeSubmissions] = useState<any>();
  const {
    refetch: refetchSubmissions,
    isLoading,
  } = useGetChallengeSubmissions({
    id: challenge.id,
    shortlist: true,
    page: 1,
  }, {
    onSuccess: (response: any) => {
      setChallengeSubmissions(response);
    },
  });
  const { mutate: updateChallengeSubmission } = useUpdateChallengeSubmission({
    onSuccess: () => {
      refetchChallengeData();
      refetchSubmissions();
    },
  });

  const onSelectHandler = (index: number) => {
    setSelectedIndex(index);
  };

  const handleSubmissionUpdate = () => {
    const selectedMedia = submissions?.data[selectedIndex];

    updateChallengeSubmission({
      challengeId: challenge.id,
      mediaId: selectedMedia?.mediaId,
      submissionData: {
        favorite: selectedMedia?.favorite,
        position: position || null,
        prize,
        reviewerNote: reviewerNote,
      },
    });
  };

  const onMediaFavorite = (mediaId: string, favorite: boolean) => {
    const challengeSubmissions = submissions;
    challengeSubmissions.data.forEach((item: any) => {
      if (item.mediaId === mediaId) {
        item.favorite = favorite;
      }
    });

    setChallengeSubmissions(challengeSubmissions);
    updateChallengeSubmission({
      challengeId: challenge.id,
      mediaId,
      submissionData: {
        favorite,
      },
    });
  };

  const changeMediaZoomIndex = (i: number) => {
    setMediaZoomIndex(i);
  };

  const mediaZoomClose = () => {
    setMediaZoomIndex(null);
  };

  useEffect(() => {
    if (submissions) {
      setPosition(submissions?.data[selectedIndex]?.position);
      setPrize(submissions?.data[selectedIndex]?.prize);
      setReviewerNote(submissions?.data[selectedIndex]?.reviewerNote);
    }
  }, [submissions]);

  useEffect(() => {
    const selectedMedia = submissions?.data[selectedIndex];

    setPosition(selectedMedia?.position);
    setPrize(selectedMedia?.prize || '');
    setReviewerNote(selectedMedia?.reviewerNote || '');
  }, [selectedIndex]);

  return (
    <>
      {isLoading ? (
        <Styled.ChallengeSubmissionsLoader>
          <Loader
            color="black"
            isLoading={isLoading}
            size={30}
          />
        </Styled.ChallengeSubmissionsLoader>
      ) : (
        <Styled.ChallengeFavoritesSelectorContainer>
          {!submissions?.data?.length && (
            <Text
              tag="p2"
              text="No favorites still, I guess."
            />
          )}
          <Styled.ChallengeSubmissionsGrid>
            {submissions?.data?.map((submission: ChallengeSubmissionMediaDTO, index: number) => (
              <ChallengeMediaItem
                key={index}
                id={submission?.mediaId}
                thumbnail={submission?.media?.thumbnail}
                favorite={submission?.favorite}
                position={submission?.position}
                onFavorite={onMediaFavorite}
                onZoom={() => setMediaZoomIndex(index)}
                selected={selectedIndex === index}
                onSelect={() => onSelectHandler(index)}
              />
            ))}
          </Styled.ChallengeSubmissionsGrid>
          <Styled.ChallengeFavoritesEditor>
            <Styled.ChallengeMediaUserDetails>
              <Text
                tag="p1"
                text={submissions?.data?.[selectedIndex]?.media?.displayName}
              />
            </Styled.ChallengeMediaUserDetails>
            <Styled.FormElement>
              <Select
                label="Position"
                value={position}
                options={
                  [
                    {
                      label: 'Unassign',
                      value: 0,
                    },
                    ...Array.from(Array(10).keys()).map((value: number) => {
                      const optionValue = value + 1;
                      return {
                        label: optionValue.toString(),
                        value: optionValue,
                      };
                    }),
                  ]
                }
                onSelect={(e, newValue) => {
                  setPosition(newValue);
                }}
              />
            </Styled.FormElement>
            <Styled.FormElement>
              <InputText
                value={prize}
                label="Prize"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrize(e?.target?.value)}
              />
            </Styled.FormElement>
            <Styled.FormElement>
              <Textarea
                value={reviewerNote}
                label="Jury note"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReviewerNote(e?.target?.value)}
                rows={3}
              />
            </Styled.FormElement>
            <Styled.FormElement>
              <Button
                slotLeft={(
                  <IconCheck
                    fill="white"
                    width={16}
                    height={16}
                  />
                )}
                onClick={handleSubmissionUpdate}
                type="primary"
                label="Save changes"
              />
            </Styled.FormElement>
          </Styled.ChallengeFavoritesEditor>
          {typeof mediaZoomIndex === 'number' && (
            <div className="batch-media-slideshow">
              <ChallengeMediaItemSlider
                items={submissions?.data}
                mediaZoomIndex={mediaZoomIndex}
                changeMediaZoomIndex={changeMediaZoomIndex}
                mediaZoomClose={mediaZoomClose}
                onFavorite={onMediaFavorite}
              />
            </div>
          )}
        </Styled.ChallengeFavoritesSelectorContainer>
      )}
    </>
  );
};
