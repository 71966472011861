import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

import { StyledButton } from '../StyledButton';
import { FlagReasonProps } from '../../types';
import './flag.reason.scss';

export const FlagReason = (props: FlagReasonProps) => {
  const { dialogCloseHandler, batchFlagHandler } = props;
  const [customValue, setCustomValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomValue(event.target.value);
  };

  const handleSaveDialog = () => {
    batchFlagHandler(customValue);
    setCustomValue('');
    dialogCloseHandler(false);
  };

  return (
    <div className="FlagReason">
      <div className="flag-reason-title">Flag Reason</div>
      <TextField
        label="Custom"
        rows={6}
        value={customValue}
        onChange={handleChange}
        multiline
        variant="outlined"
      />
      <StyledButton
        className="batch-media-flag-save"
        variant="contained"
        color="primary"
        onClick={handleSaveDialog}
      >
        Save
      </StyledButton>
    </div>
  );
};
