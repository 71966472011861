import React from 'react';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';

import { MediaReleasesProps } from '../../types';
import './media.releases.scss';

export const MediaReleases = (props: MediaReleasesProps): JSX.Element => {
  const { releases } = props;

  return (
    <div className="MediaReleases">
      {releases?.data?.map((release, index) => (
        <div key={index} className="media-release-item">
          <a href={`https://api.wirestock.io/nova/resources/releases/${release.id}`} target="_blank" rel="noopener noreferrer">
            <OpenInNewRoundedIcon className="media-release-icon" />
          </a>
          <span>
            {`${release?.id} ${release?.model?.data?.name} / ${release?.model?.data?.ethnicity_name}, ${release?.type}`}
          </span>
        </div>
      ))}
    </div>
  );
};
