import { useAuthedQuery } from '../hooks';

import logger from '../utils/logger';
import { getCuratedSummary, getKeyworderSummary } from '../services/DashboardService';
import { DashboardDataProps } from '../types';
import { Stats } from '../services/StatsService';

export enum DashboardKeysEnum {
  StatsUpdate = 'statsUpdate',
  GetDailyCuratedSummary = 'getDailyCuratedSummary',
  GetCuratedSummary = 'getCuratedSummary',
  GetKeyworderSummary = 'getKeyworderSummary',
}

export const useGetCuratedSummary = (data: DashboardDataProps, options = {}) =>
  useAuthedQuery(
    DashboardKeysEnum.GetCuratedSummary,
    () => getCuratedSummary(data.time_unit, data.period_from, data.period_to),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useGetCuratedSummary hook: ${error}`);
      },
    },
  );

export const useGetDailyCuratedSummary = (data: DashboardDataProps, options = {}) =>
  useAuthedQuery(
    DashboardKeysEnum.GetDailyCuratedSummary,
    () => getCuratedSummary(data.time_unit, data.period_from, data.period_to),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useGetDailyCuratedSummary hook: ${error}`);
      },
    },
  );

export const useGetKeyworderSummary = (data: DashboardDataProps, options = {}) =>
  useAuthedQuery(
    DashboardKeysEnum.GetKeyworderSummary,
    () => getKeyworderSummary(data.time_unit, data.period_from, data.period_to),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useGetKeyworderSummary hook: ${error}`);
      },
    },
  );

export const useStatsUpdate = (role?: string, options = {}) =>
  useAuthedQuery(
    DashboardKeysEnum.StatsUpdate,
    () => Stats.update(role),
    {
      ...options,
      cacheTime: 0,
      onError: (error: Error) => {
        logger.error(`useStatsUpdate hook: ${error}`);
      },
    },
  );
