import { theme as dsTheme } from '@wirestock.io/design-components';
import { WSTheme, ThemeVariant } from './theme.types';

export const theme: WSTheme = {
  palette: {
    mode: ThemeVariant.LIGHT,
  },
  color: {
    ...dsTheme.color,

    blue: '#4776E6',
    white: '#fff',
    gray: '#515151',
    lightGray: '#C4C4C4',
    lightestGray: '#EEEEEE',
    linkBlue: '#0070C9',
    orange: '#FF7F09',
    red: '#FB0404',
    black: '#000000',
    green: '#58C321',
    whiteButtonHover: '#F5F5F5',
    blueButtonHover: '#799AE9',
    blackButtonHover: '#747474',
    orangeButtonHover: '#FBA14E',
    transparentBlack: 'rgba(56, 56, 56, 0.6)',
    transparentDarkBlack: 'rgba(56, 56, 56, 0.5)',
    transparentLightBlack: 'rgba(56, 56, 56, 0.4)',
    mouseGray: '#E1E1E1',
    rgbaGray: 'rgba(238, 238, 238, 0.2)',
    darkBlack: '#2D2D2D',
    backgroundGray: '#F3F3F3',
    backgroundGrayHover: '#EAEAEA',
    darkGray: '#1B1B1B',
    grayText: '#838383',

    WHITE: '#ffffff',
    BABY_BLUE: '#4776e6',
    BLACK: '#000000',
    DARK_GRAY: 'rgba(27, 27, 27, 0.8)',

    ACTION: '#000000',
    SUCCESS: '#58C321',
    DANGER: '#fb0404',
    POSITIVE: '#ffffff',
    NEGATIVE: '#f3f3f3',
    WARNING: '#ff7c04',

    TEXT_1: '#000000',
    TEXT_2: '#838383',
    TEXT_3: '#ffffff',
    TEXT_4: '#4d4d4d',

    BACKGROUND_1: '#ffffff',
    BACKGROUND_2: '#f3f3f3',
    BACKGROUND_3: '#f7f7f7',
    BACKGROUND_TRANSPARENT: 'rgba(0, 0, 0, 0.6)',
    BACKGROUND_TRANSPARENT_2: 'rgba(256, 256, 256, 0.2)',
    LANDING_BACKGROUND_1: '#F8F5F2',
    LANDING_BACKGROUND_2: '#F3ECE5',

    BORDER_OPAQUE: '#fff',
    BORDER_ACCENT: '#fff',

    LINK_1: '#4776e6',
  },

  shadow: {
    contentShadow: '0 2px 1px rgba(0, 0, 0, 0.08)',
    popupShadow: '0 0 4px 2px rgba(0, 0, 0, 0.1)',
    modalShadow: '0 8px 8px rgba(0, 0, 0, 0.04)',
    buttonShadow: '6px 16px 30px rgba(0, 0, 0, 0.16);',
    fixedFooterShadow: '6px 16px 30px 16px rgba(56, 56, 56, 0.25)',
    shadowMain: '0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04)',
    photoManagerShadow: '2px 4px 6px rgba(0, 0, 0, 0.16)',
    smallComponentShadow: '1px 4px 4px rgba(56, 56, 56, 0.16)',
  },
  // Content Shadow

  font: {
    mainRegular: 'Proxima-Regular, sans-serif',
    mainSemiBold: 'Proxima-SemiBold, sans-serif',
    mainBold: 'Proxima-Bold, sans-serif',
    mainExtraBold: 'Proxima-Extra-Bold, sans-serif',
  },

  fontSize: {
    small: '12px',
    default: '14px',
    medium: '16px',
    extraMedium: '20px',
    large: '24px',
    extraLarge: '42px',
    largest: '72px',
  },

  space: {
    ...dsTheme.space,

    extraSm: '2px',
    sx: '4px',
    sm: '8px',
    line: '12px',
    md: '16px',
    xmd: '26px',
    lg: '32px',
    big: '64px',
    extraBig: '128px',
    extraLg: '256px',

    S2: '2px',
    S4: '4px',
    S6: '6px',
    S8: '8px',
    S12: '12px',
    S16: '16px',
    S24: '24px',
    S32: '32px',
    S48: '48px',
    S64: '64px',
    S128: '128px',
    S256: '256px',
  },

  radius: {
    sm: '4px',
    middle: '8px',
    main: '13px',
    lg: '16px',
    extraLg: '36px',
    half: '50%',
    R13: '13px',
    R26: '26px',

    R2: '2px',
    R4: '4px',
    R8: '8px',
    R12: '12px',
    R16: '16px',
    R24: '24px',
    R32: '32px',
    ROUND: '50%',
  },
  // default border-radius dimensions

  mediaQuery: {
    sm: '699.98px',
    md: '899.98px',
    lg: '1199.98px',
    extraLg: '1599.98px',
    widthExtraLg: '1599.98px',
    widthLg: '1199.98px',
    widthMd: '991.98px',
    widthSm: '767.98px',
    widthEsm: '575.98px',
  },

  animation: {
    easeInOutSine: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
  },

  footerHeight: '272px',
  headerHeight: '83px',
  maxWrapperWidth: '1600px',
  maxLandingWrapperWidth: '1294px',
  maxWrapperWidthMd: '1280px',
  portfolioBackgroundHeight: '242px',

  // circles sizes
  circleWeightLg: '14px',
  circleWeightSm: '12px',
  circleWeightXsm: '8px',

  // submission form inputs default size
  inputHeight: '42px',
  searchInputHeight: '67px',
  textAreaHeight: '70px',

  // buttons maximum with size
  buttonWidthMax: '290px',
  buttonWidthMd: '148px',
  buttonWidthSm: '112px',
  buttonWidthExtraSm: '75px',
  buttonWidthXXS: '51px',
  buttonHeight: '42px',
  buttonHeightExtraSm: '33px',

  // Default scroll width
  scrollWidth: '10px',
  scrollWidthLg: '22px',

  // Snackbar
  snackbarHeight: '50px',
  snackbarWidth: '672px',

  elevation: {
    DEFAULT: '0px 3px 8px rgba(0,0,0,0.12),0px 3px 1px rgba(0,0,0,0.04)',
    TOOLTIP: '0px 4px 30px 8px rgba(0, 0, 0, 0.3)',
    BOX: '0px 0px 8px 0px rgba(0, 0, 0, 0.12)',
  },
  zIndex: {
    EDITOR: 1,
    OVERLAY: 2,
    POPUP: 1000000,
    PROMPT: 4,
    LOADER: 5,
    ALERT: 6,
  },
  name: 'default',
  typography: {
    ...dsTheme.typography,

    BASE: '\'Open Sans\', sans-serif',
    SECONDARY: '\'SharpGroteskMedium23\', sans-serif',
    REGULAR: '400',
    BOLD: '700',
    FS72: '72px',
    FS42: '42px',
    FS36: '36px',
    FS24: '24px',
    FS20: '20px',
    FS16: '16px',
    FS14: '14px',
    FS12: '12px',
  },
};
