import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import './header.menu.scss';

export const HeaderMenu = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [pageItem, setPageItem] = useState(0);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const pageItems = [
    {
      id: 0,
      title: 'Content',
      href: '/',
    },
    {
      id: 1,
      title: 'Dashboard',
      href: '/dashboard',
    },
  ];

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (
    event: React.MouseEvent<EventTarget>,
    itemId: number,
    href?: string,
  ) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    href && history.push(href);
    setPageItem(itemId);
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className="HeaderMenu">
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        disableRipple
        onClick={handleToggle}
      >
        {pageItems[pageItem].title}
        <ArrowDropDownRoundedIcon className="header-profile-menu-icon" />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
            }}
          >
            <Paper className="header-profile-dropdown">
              <ClickAwayListener
                onClickAway={(event: any) => handleClose(event, pageItem)}
              >
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {pageItems.map((item, i) => (
                    <MenuItem
                      key={i}
                      className="header-profile-dropdown-item"
                      onClick={(event) => handleClose(event, item.id, item.href)}
                    >
                      {item.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
