import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { FormGroup } from '@mui/material';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useKey } from 'rooks';

import { KeyboardEvent } from '../../services/KeyboardEventsService';
import { KEYBOARD_CONTEXT_POPUP } from '../../constants/keyboard.constants';
import { LoadingProgress } from '../LoadingProgress';
import { StyledButton } from '../StyledButton';
import { MediaItem, ReasonType, RejectReasonProps } from '../../types';
import { ACTION_REJECT, ACTION_REVISE } from '../../constants/actions.constants';
import {
  useGetRejectionReasons,
  useGetRevisionReasons,
  useMediaRejection,
  useMediaRevision,
} from '../../queries';
import './reject.reason.scss';

export const RejectReason = (props: RejectReasonProps) => {
  const {
    mediaList,
    reasonType,
    dialogCloseHandler,
    onActionSuccess,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [rejectValues, setRejectValues] = useState<any>({});
  const [searchValue, setSearchValue] = useState('');
  const [customValue, setCustomValue] = useState('');
  const [mediaTypes, setMediaTypes] = useState<any>([]);
  const [cursorPosition, setCursorPosition] = useState(0);
  const checkedItems = Object.entries(rejectValues).filter((i: any) => i[1] === true);

  const { data: reasons, remove: removeReasons, isLoading }: any = reasonType === ACTION_REJECT ? useGetRejectionReasons() : useGetRevisionReasons();
  const { mutate: rejectMedia, isLoading: rejectMediaLoading } = useMediaRejection({
    onSuccess: () => {
      onActionSuccess(reasonType);
      clearData();
    },
    onError: (error: any) => {
      enqueueSnackbar(
        error.data.message,
        {
          variant: 'error',
        },
      );
    },
  });
  const { mutate: reviseMedia, isLoading: reviseMediaLoading } = useMediaRevision({
    onSuccess: () => {
      onActionSuccess(reasonType);
      clearData();
    },
    onError: (error: any) => {
      enqueueSnackbar(
        error.data.message,
        {
          variant: 'error',
        },
      );
    },
  });

  const handleRejectChange = (event: React.SyntheticEvent<Element, Event>, value: number, index: number) => {
    setCursorPosition(index);
    setRejectValues({ ...rejectValues, [value]: !rejectValues[value] });
  };

  const clearData = () => {
    setCustomValue('');
    setSearchValue('');
    setRejectValues(0);
    removeReasons();
    dialogCloseHandler(false);
  };

  const filteredFields = () =>
    reasons ? reasons.data.filter((item: ReasonType) => item.description.toLowerCase().includes(searchValue)) : [];

  const handleRejectDialog = async () => {
    const rejectedIds = Object.entries(rejectValues).filter((item) => item[1] === true);
    const isCustomReasonReady = reasons.data.filter((reason: any) => reason.name === 'custom');
    const customReasonData = {
      id: isCustomReasonReady.length ? reasons.data.filter((reason: any) => reason.name === 'custom' && reason.type === mediaTypes[0])[0].id : 0,
      message: customValue,
    };
    const data: any = {
      data: {
        media_ids: mediaList.map((i: any) => i.id),
        ...(reasonType === ACTION_REJECT ? { rejections: [] } : { revisions: [] }),
      },
    };

    if (customReasonData.message.length) {
      rejectedIds.push([customReasonData.id, customReasonData.message]);
    }

    rejectedIds.forEach((item: any) => {
      const reasonValue = reasons.data.filter((reason: any) => reason.id === parseInt(item[0], 10))[0];
      data.data[reasonType === ACTION_REJECT ? 'rejections' : 'revisions'].push({
        id: reasonValue.id === customReasonData.id ? customReasonData.id : reasonValue.id,
        reason: reasonValue.id === customReasonData.id ? customReasonData.message : reasonValue.description,
      });
    });

    if (reasonType === ACTION_REJECT) {
      await rejectMedia(data);
    } else if (reasonType === ACTION_REVISE) {
      await reviseMedia(data);
    }
  };

  const handleReviseDialog = () => {
    mediaList.forEach(async (media: MediaItem) => {
      const rejectedIds = Object.entries(rejectValues).filter((item) => item[1] === true);
      const customReasonData = {
        id: reasons.data.filter((reason: any) => reason.name === 'custom' && reason.type === mediaTypes[0])[0].id,
        message: customValue,
      };
      const data: any = {
        mediaId: media.id,
        data: {
          ...(reasonType === ACTION_REJECT ? { rejections: [] } : { revisions: [] }),
        },
      };

      if (customReasonData.message.length) {
        rejectedIds.push([customReasonData.id, customReasonData.message]);
      }

      rejectedIds.forEach((item: any) => {
        const reasonValue = reasons.data.filter((reason: any) => reason.id === parseInt(item[0], 10))[0];
        data.data[reasonType === ACTION_REJECT ? 'rejections' : 'revisions'].push({
          id: reasonValue.id === customReasonData.id ? customReasonData.id : reasonValue.id,
          reason: reasonValue.id === customReasonData.id ? customReasonData.message : reasonValue.description,
        });
      });

      if (reasonType === ACTION_REJECT) {
        await rejectMedia(data);
      } else if (reasonType === ACTION_REVISE) {
        await reviseMedia(data);
      }
    });
  };

  const handleAction = () => {
    if (reasonType === ACTION_REJECT) {
      handleRejectDialog();
    } else {
      handleReviseDialog();
    }
  };

  const onDialogClose = () => {
    clearData();
  };

  const scrollToCursor = () => {
    const cursorElement = (document.querySelector('.reason-list .MuiFormControlLabel-root.cursor') as HTMLElement);
    const mediaContainer = (document.querySelector('.reason-list') as HTMLElement);

    mediaContainer.scroll({
      top: cursorElement?.offsetTop - mediaContainer?.offsetTop,
      behavior: 'smooth',
    });
  };

  const changeCursor = (event: Event, direction: string) => {
    const reasonList = filteredFields().filter((item: ReasonType) => mediaTypes.includes(item.type) && item.name !== 'custom');
    event.preventDefault();

    switch (direction) {
      case 'up':
        cursorPosition > 0 ? setCursorPosition(cursorPosition - 1) : setCursorPosition(reasonList.length - 1);
        break;
      case 'down':
        cursorPosition < reasonList.length - 1 ? setCursorPosition(cursorPosition + 1) : setCursorPosition(0);
        break;
      default:
        break;
    }

    scrollToCursor();
  };

  const checkReason = () => {
    const keyId = Object.keys(rejectValues)[cursorPosition];

    setRejectValues({ ...rejectValues, [keyId]: checkedItems.length < 3 ? !rejectValues[keyId] : false });
  };

  useEffect(() => {
    const reasonsData: any = {};

    reasons && reasons.data.forEach((i: any) => {
      reasonsData[i.id] = false;
    });

    setRejectValues(reasonsData);
  }, [reasons]);

  useEffect(() => {
    const mediaTypesList: any = Array.from(new Set(mediaList.map((i) => i.type)));
    setMediaTypes(mediaTypesList.map((item: any) => {
      return clsx({ image: item === 'photo' || item === 'illustration', vector: item === 'vector', video: item === 'video' });
    }));
  }, [mediaList]);

  useKey(38, (e) => KeyboardEvent.trigger(KEYBOARD_CONTEXT_POPUP, () => changeCursor(e, 'up')));
  useKey(40, (e) => KeyboardEvent.trigger(KEYBOARD_CONTEXT_POPUP, () => changeCursor(e, 'down')));
  useKey(13, () => KeyboardEvent.trigger(KEYBOARD_CONTEXT_POPUP, () => checkReason()));
  useKey(82, () => KeyboardEvent.trigger(KEYBOARD_CONTEXT_POPUP, () => handleAction()));

  return (
    <div className="RejectReason">
      <div className="reason-title">Choose Reasons</div>
      <div className="reason-search-bar">
        <TextField
          label="Search"
          variant="filled"
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
        />
      </div>
      <div className="reason-list">
        {(isLoading || rejectMediaLoading || reviseMediaLoading) && (
          <LoadingProgress />
        )}
        {!(isLoading || rejectMediaLoading || reviseMediaLoading) && mediaTypes.length > 1 && (
          <p className="info-text"><span>🤷‍♂️</span>In order to {reasonType}, please select medias with the same type.</p>
        )}
        {!(isLoading || rejectMediaLoading || reviseMediaLoading) && mediaTypes.length === 1 && (
          <FormGroup
            aria-label="reason"
          >
            {filteredFields().map((item: ReasonType, i: number) => {
              return mediaTypes.includes(item.type) && item.name !== 'custom' && (
                <FormControlLabel
                  key={i}
                  className={clsx({ cursor: cursorPosition === i })}
                  value={item.id}
                  checked={!!rejectValues[item.id]}
                  onChange={(event) => handleRejectChange(event, item.id, i)}
                  control={<Checkbox checked={!!rejectValues[item.id]} />}
                  disabled={checkedItems.length === 3 && !rejectValues[item.id]}
                  label={item.description}
                />
              );
            })}
          </FormGroup>
        )}
      </div>
      {mediaTypes.length === 1 && (
        <div className="reason-custom-field">
          <TextField
            label="Custom reason"
            variant="outlined"
            value={customValue}
            onChange={(event) => setCustomValue(event.target.value)}
          />
        </div>
      )}
      <div className="reason-action-buttons">
        <StyledButton
          className="batch-media-flag"
          variant="outlined"
          color="inherit"
          onClick={() => onDialogClose()}
        >
          Close
        </StyledButton>
        {mediaTypes.length === 1 && (
          <>
            {reasonType === 'reject' ? (
              <StyledButton
                className="batch-media-flag"
                variant="outlined"
                color="error"
                onClick={handleAction}
              >
                Reject
              </StyledButton>
            ) : (
              <StyledButton
                className="batch-media-flag"
                variant="outlined"
                color="error"
                onClick={handleAction}
              >
                Revise
              </StyledButton>
            )}
          </>
        )}
      </div>
    </div>
  );
};
