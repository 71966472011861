import React, { ReactElement, useState } from 'react';
import {
  IconInfo, Switcher, SwitcherItem, SwitcherItemProps,
} from '@wirestock.io/design-components';
import { useTheme } from 'styled-components';
import { ThemeAssetsGrid } from './ThemeAssetsGrid';
import * as Styled from './ThemeAssetsList.styles';
import { useThemeAssetWithStatuses } from './useThemeAssetWithStatuses';

export const ThemeAssetsList = () => {
  const { color } = useTheme();
  const { assetsList: themeAssets, isLoading } = useThemeAssetWithStatuses();
  const [selectedAssetType, setSelectedAssetType] = useState('all');

  const portfolioImported = themeAssets?.filter((asset) => !!asset.sourceMediaId);
  const uploaded = themeAssets?.filter((asset) => !asset.sourceMediaId);
  const includesRejected = themeAssets?.some((asset) => asset.sourceMediaStatus === 'rejected' && !!asset.sourceMediaId);

  const assets = selectedAssetType === 'portfolioImported'
    ? portfolioImported
    : selectedAssetType === 'uploaded'
      ? uploaded
      : themeAssets;

  const switcherItems: ReactElement<SwitcherItemProps>[] = [
    <SwitcherItem value="portfolioImported" key="portfolioImported">Portfolio Imported</SwitcherItem>,
    <SwitcherItem value="uploaded" key="uploaded">Uploaded</SwitcherItem>,
  ];

  return (
    <Styled.ThemeAssetsContainer>
      <Switcher
        value={selectedAssetType}
        onChange={(v) => setSelectedAssetType(v)}
        indicatorColor={color.BACKGROUND_2}
        activeTextColor={color.ACTION}
        textColor={color.TEXT_4}
      >
        {[<SwitcherItem value="all" key="all">All</SwitcherItem>]
          .concat(portfolioImported?.length ? switcherItems : switcherItems.reverse())}
      </Switcher>
      {includesRejected && (
        <Styled.ThemeAssetsWarning
          text="This theme includes rejected media imported from user's potfolio"
          slotLeft={<IconInfo fill={color.TEXT_1} width={18} height={18} />}
          isDismissible={false}
        />
      )}
      <ThemeAssetsGrid assets={assets} isLoading={isLoading} />
    </Styled.ThemeAssetsContainer>
  );
};
