import styled from 'styled-components';

export const PayoutsManagerContainer = styled.div`
  display: flex;
  height: calc(100vh - 68px);
  padding: ${({ theme }) => theme.space.S16};
  background-color: ${({ theme }) => theme.color.BACKGROUND_2};
  gap: ${({ theme }) => theme.space.S16};
  overflow: hidden;
`;

export const PayoutsListContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.space.S32};
  background-color: white;
  border-radius: ${({ theme }) => theme.radius.R13};
`;

export const PayoutsListTableContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const PayoutsListHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PayoutsListHeaderFilters = styled.div`
  display: flex;
  gap: 16px;
`;
