export enum PayoutsStatuses {
  OK = 'Ok',
  Failed = 'Failed',
}

export enum PayoutsStates {
  Created = 'Created',
  Approved = 'FundsHoldRequested',
  FundsHeld = 'FundsHeld',
  TaxesHoldRequested = 'TaxesHoldRequested',
  TaxesHeld = 'TaxesHeld',
  PaymentPending = 'PaymentPending',
  Paid = 'Paid',
}
