import { useAuthedQuery } from '../hooks';
import { UserDTO } from '../models/User/User.dto';
import logger from '../utils/logger';
import UserService from '../services/UserService';
import { getItemFromLocalStorage } from '../services/StorageService';
import { AUTH_DATA } from '../constants/storage.constants';

export enum UserKeysEnum {
  useGetUserDetails = 'useGetUserDetails',
}

export const useUserDetails = (options = {}) =>
  useAuthedQuery(
    UserKeysEnum.useGetUserDetails,
    async (): Promise<UserDTO> => {
      const { userId, access_token } = getItemFromLocalStorage(AUTH_DATA, true);
      const response: any = await UserService.getDetails(userId);

      return new UserDTO(response?.data, access_token);
    },
    {
      ...options,
      enabled: !!getItemFromLocalStorage(AUTH_DATA, true).userId,
      onError: (error: Error) => {
        logger.error(`useGetUserDetails hook: ${error}`);
      },
    },
  );
