import React, { useState, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import { ChartProps, ChartOptionsType } from '../../types';

export const Chart = (props: ChartProps) => {
  const { categories, series } = props;
  const [options, setOptions] = useState<ChartOptionsType>({
    chart: {},
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
    },
    plotOptions: {
      column: {
        color: '#A5A5A5',
        pointPadding: -0.26,
        borderWidth: 0,
        borderRadius: 4,
      },
    },
    series: [
      {
        name: 'Media Count',
        data: [],
      },
    ],
  });

  useEffect(() => {
    setOptions({
      ...options,
      xAxis: {
        categories: categories,
        crosshair: true,
      },
      series: [
        {
          name: 'Media Count',
          data: series,
        },
      ],
    });
  }, [categories, series]);

  return (
    <div className="Chart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
