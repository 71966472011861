import { http } from './ApiService';
import { ChallengeDTO, ChallengeRequestTransformer } from '../models/Challenge/Challenge.dto';

export default {
  getChallenges: (page: number) => {
    return http.get(
      `challenge-service/challenges?page=${page}&perPage=100&showScheduled=true&showPrivate=true`,
    );
  },
  getChallenge: (challengeId: string) => {
    return http.get(
      `/challenge-service/challenges/${challengeId}`,
    );
  },
  createChallenge: (challengeData: ChallengeDTO) => {
    const requestData = ChallengeRequestTransformer(challengeData);
    return http.post(
      '/challenge-service/challenges',
      requestData,
    );
  },
  signChallengeFile: (challengeId: string, file: File) => {
    return http.post(
      `/challenge-service/challenges/${challengeId}/content-files`,
      [file.name],
    );
  },
  uploadChallengeFile: (signedUrl: string, file: File) => {
    return http.put(
      '',
      file,
      {
        baseURL: signedUrl,
        headers: {
          Authorization: '',
        },
      },
    );
  },
  updateChallenge: (id: string, challengeData: ChallengeDTO) => {
    const requestData = ChallengeRequestTransformer(challengeData);
    return http.put(
      `/challenge-service/challenges/${id}`,
      requestData,
    );
  },
  deleteChallenge: (id: string) => {
    return http.delete(
      `/challenge-service/challenges/${id}`,
    );
  },
  getChallengeSubmissions: (id: string, showShortlist?: boolean, page?: number) => {
    return http.get(
      `/challenge-service/challenges/${id}/media`,
      {
        params: {
          perPage: 50,
          ...(showShortlist && { showFavorites: showShortlist }),
          ...(showShortlist && { showWinners: showShortlist }),
          ...(page && { page }),
        },
      },
    );
  },
  updateChallengeSubmission: (challengeId: string, mediaId: string, data: any) => {
    return http.put(
      `/challenge-service/challenges/${challengeId}/media/${mediaId}`,
      data,
    );
  },
  finalizeChallengeSubmission: (challengeId: string) => {
    return http.post(
      `/challenge-service/challenges/${challengeId}/finalize`,
      {},
    );
  },
};
