import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useTimeout } from 'rooks';
import { useSnackbar } from 'notistack';
import {
  useGetAssignedMedia,
  useAssignBatch,
  useGetTopBatch,
} from '../../../../queries';
import { LoadingProgress } from '../../../../components/LoadingProgress';
import { StyledButton } from '../../../../components/StyledButton';
import { Response, DefaultUserProps } from '../../../../types';
import homeIllustration from '../../../../assets/images/home-illustration.png';

export const ReviewerView = ({ user }: DefaultUserProps): JSX.Element => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { start: redirectToBatchView } = useTimeout(() => {
    history.push('/batch');
  }, 10);
  const { isLoading, data: assignedBatch, remove: removeAssignedBatch }: any = useGetAssignedMedia({ role: user?.selectedRole });
  const assignBatchMutation = useAssignBatch(user?.selectedRole, {
    onSuccess: (res: Response) => {
      if (res && res.message) {
        removeAssignedBatch();
        redirectToBatchView();
      }
    },
    onError: (error: any) => {
      enqueueSnackbar(
        error.data.message,
        {
          variant: 'error',
        },
      );
    },
  });
  const { refetch, isLoading: getBatchLoading } = useGetTopBatch(user?.selectedRole, {
    enabled: false,
    onSuccess: (res: Response) => {
      const response = res && res.data;

      if (!res) {
        enqueueSnackbar(
          'No new batches available at this time. Please try later.',
        );
      }

      response && response.id && assignBatchMutation.mutate(response.id);
    },
  });
  const getTopBatch = () => {
    refetch();
  };

  useEffect(() => {
    if (
      user?.isReviewer &&
      assignedBatch &&
      assignedBatch.data.length &&
      (assignedBatch.data[0].id || assignedBatch.data.id)
    ) {
      redirectToBatchView();
    }
  }, [assignedBatch]);

  return (
    <div className="Home-element">
      {!!isLoading && <LoadingProgress />}
      {!isLoading && (
        <>
          <h1>All Done</h1>
          <img
            src={homeIllustration}
            alt=""
          />

          <StyledButton
            className="home-element-button"
            variant="contained"
            color="primary"
            onClick={getTopBatch}
            disabled={getBatchLoading}
          >
            Get New Batch
          </StyledButton>
          {
            getBatchLoading &&
            (
              <LoadingProgress
                fixed
                size={20}
              />
            )
          }
        </>
      )}
    </div>
  );
};
