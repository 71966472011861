import React from 'react';
import { Editor, RichUtils, EditorState } from 'draft-js';
import {
  Button,
  IconButton,
  IconClose,
  IconDelete,
  InputText,
} from '@wirestock.io/design-components';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import * as Styled from './ChallengeContent.styles';
import { ChallengeUploadButton } from '../ChallengeUploadButton';

export const ChallengeContentSection = ({
  challengeId,
  index,
  section,
  updateSection,
  deleteSection,
}: any) => {
  const { title, editorState, images } = section;

  const onTitleChange = (e: any) => {
    const newTitle = e.target.value;
    updateSection(index, { ...section, title: newTitle });
  };

  const onEditorStateChange = (newEditorState: any) => {
    updateSection(index, { ...section, editorState: newEditorState });
  };

  const updateImage = (imgIndex: number, newUrl?: string) => {
    const updatedImages = [...images];
    updatedImages[imgIndex] = newUrl;
    updateSection(index, { ...section, images: updatedImages });
  };

  const addImage = () => {
    updateSection(index, { ...section, images: [...images, ''] });
  };

  const deleteImage = (imgIndex: number) => {
    updateSection(index, { ...section, images: images.filter((_: any, i: number) => i !== imgIndex) });
  };

  const handleKeyCommand = (command: string, newEditorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(newEditorState, command);

    if (newState) {
      updateSection(index, { ...section, editorState: newState });
      return 'handled';
    }

    return 'not-handled';
  };

  const editorBoldHandler = () => {
    updateSection(index, { ...section, editorState: RichUtils.toggleInlineStyle(editorState, 'BOLD') });
  };

  const editorLinkHandler = () => {
    const url = window.prompt('Enter the URL');

    if (!url) {
      return;
    }

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url },
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    let newEditorState = EditorState.push(editorState, contentState, 'apply-entity');

    newEditorState = RichUtils.toggleInlineStyle(newEditorState, 'LINK');

    const editorStateWithLink = RichUtils.toggleLink(
      newEditorState,
      newEditorState.getSelection(),
      entityKey,
    );

    updateSection(index, { ...section, editorState: editorStateWithLink });
  };

  const editorItalicHandler = () => {
    updateSection(index, { ...section, editorState: RichUtils.toggleInlineStyle(editorState, 'ITALIC') });
  };

  const editorBulletPointHandler = () => {
    updateSection(index, { ...section, editorState: RichUtils.toggleBlockType(editorState, 'unordered-list-item') });
  };

  return (
    <Styled.ChallengeSectionBlock>
      <Styled.ChallengeSectionBlockButtons>
        <IconButton
          type="secondary"
          onClick={() => deleteSection(index)}
          icon={(
            <IconDelete
              height={17}
              width={17}
            />
          )}
        />
        <Styled.ChallengeSectionEditorButtons>
          <IconButton
            variant="transparent"
            onClick={editorBoldHandler}
            icon={(
              <FormatBoldIcon />
            )}
          />
          <IconButton
            variant="transparent"
            onClick={editorItalicHandler}
            icon={(
              <FormatItalicIcon />
            )}
          />
          <IconButton
            variant="transparent"
            onClick={editorBulletPointHandler}
            icon={(
              <FormatListBulletedIcon />
            )}
          />
          <IconButton
            variant="transparent"
            onClick={editorLinkHandler}
            icon={(
              <InsertLinkIcon />
            )}
          />
        </Styled.ChallengeSectionEditorButtons>
      </Styled.ChallengeSectionBlockButtons>
      <Styled.ChallengeSectionBlockForms>
        <Styled.FormElement>
          <InputText
            value={title}
            onChange={onTitleChange}
            placeholder="Section title"
          />
        </Styled.FormElement>
        <Editor
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={onEditorStateChange}
          customStyleMap={{
            LINK: {
              color: '#3b5998',
              textDecoration: 'underline',
            },
          }}
        />
      </Styled.ChallengeSectionBlockForms>
      <Styled.ChallengeSectionBlockImages>
        <Styled.ChallengePreviewImageItems>
          {images.map((imageUrl: string, imgIndex: number) => (
            <Styled.ChallengePreviewImageItem
              key={imgIndex}
              className="image"
            >
              <InputText
                value={imageUrl}
                onChange={(e: any) => updateImage(imgIndex, e.target.value)}
              />
              <ChallengeUploadButton
                disabled={!challengeId}
                challengeId={challengeId}
                onSuccess={(uploadedFileUrl) => updateImage(imgIndex, uploadedFileUrl)}
              />
              <img
                src={imageUrl}
                alt={`Section ${imgIndex + 1}`}
              />
              <IconButton
                onClick={() => deleteImage(imgIndex)}
                icon={(
                  <IconClose
                    width={15}
                    height={15}
                  />
                )}
                variant="transparent"
              />
            </Styled.ChallengePreviewImageItem>
          ))}
        </Styled.ChallengePreviewImageItems>
        <Button
          onClick={addImage}
          label="Add section image"
        />
      </Styled.ChallengeSectionBlockImages>
    </Styled.ChallengeSectionBlock>
  );
};
