import { PayoutsDataProps } from '../types';
import { useAuthedQuery } from '../hooks';
import PayoutsService from '../services/PayoutsService';
import logger from '../utils/logger';

export enum PayoutsKeysEnum {
  GetPayoutsList = 'getPayoutsList',
}

export const useGetPayoutsList = (data: PayoutsDataProps, options = {}) =>
  useAuthedQuery(
    PayoutsKeysEnum.GetPayoutsList,
    () => PayoutsService.getPayoutsList(data),
    {
      ...options,
      onError: (error: Error) => {
        logger.error(`useGetCuratedSummary hook: ${error}`);
      },
    },
  );
