import React, {
  useState,
  SyntheticEvent, useEffect,
} from 'react';
import {
  Text,
  Menu,
  Avatar,
  Loader,
} from '@wirestock.io/design-components';
import { useTheme } from 'styled-components';
import { useSnackbar } from 'notistack';
import * as Styled from './PortfolioRankerManager.styles';
import { PortfolioRankerContent } from './PortfolioRankerContent';
import {
  useGetPortfolioList, useRankPortfolio,
} from '../../queries';
import { PortfolioDTO } from '../../models/Portfolio/Portfolio.dto';

export const PortfolioRankerManager = () => {
  const { color } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: portfolioData,
    isLoading,
    refetch,
    total,
    isSuccess,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useGetPortfolioList();

  const { mutateAsync: rankPortfolio, isLoading: rankPortfolioLoading } = useRankPortfolio({
    onError: (error: any) => {
      enqueueSnackbar(
        `Error happened when ranking user. ${error.message}`,
        {
          variant: 'error',
        },
      );
    },
  });
  const [selectedPortfolioUsername, setSelectedPortfolioUsername] = useState<string>('');

  const portfolio = portfolioData?.find((p: PortfolioDTO) => p.username === selectedPortfolioUsername) ?? portfolioData?.[0];

  const handleChallengeListScroll = async (event: SyntheticEvent) => {
    const target = event.currentTarget;

    if (
      isSuccess &&
      target.scrollHeight - target.scrollTop < target.clientHeight + 200 &&
      !isFetching &&
      hasNextPage &&
      !isLoading
    ) {
      await fetchNextPage();
    }
  };

  const handlePortfolioRank = async (rankScore: number): Promise<void> => {

    if (!portfolio) {
      alert('Portfolio not found');
      return;
    }

    await rankPortfolio({ userId: portfolio?.id, rankScore });

    await refetch();

    enqueueSnackbar(
      `${portfolio.username} ranked as ${rankScore === 1 ? 'Good' : rankScore === 0 ? 'Normal' : 'Bad'}.`,
      {
        variant: 'success',
        autoHideDuration: 1000,
      },
    );
  };

  useEffect(() => {
    if (portfolio?.username) {
      setSelectedPortfolioUsername(portfolio?.username);
    }
  }, [portfolio?.username]);
  return (
    <Styled.ChallengeManagerContainer>
      <Styled.ChallengesListContainer onScroll={handleChallengeListScroll}>
        <Styled.ChallengesListHeader>
          <Styled.ChallengesListHeaderTitle>
            <Text
              tag="h6"
              text="Portfolios to Rank"
            />
            <Text
              tag="span"
              text={`${total} users`}
            />
          </Styled.ChallengesListHeaderTitle>
        </Styled.ChallengesListHeader>
        <Menu>
          {portfolioData?.map((item: PortfolioDTO, index: number) => (
            <Styled.ChallengesListItem
              key={index}
              selected={item.username === selectedPortfolioUsername}
              onSelect={() => setSelectedPortfolioUsername(item.username)}
            >
              <Avatar
                src={item?.userDetails?.avatarUrl}
                alt={item?.displayName}
              />
              <Styled.ChallengesListItemTitle>
                <Text
                  tag="p3"
                  text={item?.displayName}
                />
                <Text
                  text={item?.email}
                  tag="span"
                />
              </Styled.ChallengesListItemTitle>
            </Styled.ChallengesListItem>
          ))}
        </Menu>
        {isFetching && (
          <Styled.ChallengesLoaderWrapper>
            <Loader
              color={color.black}
              isLoading
              size={20}
            />
          </Styled.ChallengesLoaderWrapper>
        )}
      </Styled.ChallengesListContainer>
      <Styled.ChallengesContentEditor>
        {isLoading ? (
          <Styled.ChallengesLoaderWrapper>
            <Loader
              color={color.black}
              isLoading
              size={30}
            />
          </Styled.ChallengesLoaderWrapper>
        ) : (
          <PortfolioRankerContent
            selectedPortfolio={portfolio}
            handlePortfolioRank={handlePortfolioRank}
            isRankLoading={rankPortfolioLoading}
          />
        )}
      </Styled.ChallengesContentEditor>
    </Styled.ChallengeManagerContainer>
  );
};
