import React, { useEffect, useState } from 'react';
import {
  Button,
  Text,
  IconOpenInNew, Loader,
} from '@wirestock.io/design-components';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import * as Styled from './PortfolioRankerContent.styles';
import { useGetPortfolioGallery } from '../../../queries';
import { ChallengeMediaItem } from '../../ChallengeManager/ChallengeSubmissions/ChallengeMediaItem';
import { ChallengeMediaItemSlider } from '../../../components/ChallengeMediaItemSlider';

interface ChallengeContentProps {
  selectedPortfolio: any;
  handlePortfolioRank: (rankScore: number) => void;
  isRankLoading?: boolean;
}

export const PortfolioRankerContent = ({
  selectedPortfolio,
  handlePortfolioRank,
  isRankLoading,
}: ChallengeContentProps) => {
  const [mediaZoomIndex, setMediaZoomIndex]: any = useState(null);
  const {
    data: galleryData,
    refetch,
    isLoading,
    isFetching,
  } = useGetPortfolioGallery(selectedPortfolio?.username);

  const openChallengePage = (portfolioUsername: string) => {
    window.open(`https://wirestock.io/${portfolioUsername}`, '_blank');
  };

  const changeMediaZoomIndex = (i: number) => {
    setMediaZoomIndex(i);
  };

  const mediaZoomClose = () => {
    setMediaZoomIndex(null);
  };

  useEffect(() => {
    if (selectedPortfolio?.id) {
      refetch();
    }
  }, [selectedPortfolio?.id]);

  return (
    <Styled.ChallengeContentWrapper>
      <Styled.ChallengeContentHeader>
        <Styled.ChallengeContentHeaderInfo>
          <Styled.ChallengeContentHeaderTitle>
            <Text
              tag="h3"
              text={selectedPortfolio?.displayName}
            />

            {selectedPortfolio?.id && (
              <Styled.ChallengeContentHeaderIcon onClick={() => openChallengePage(selectedPortfolio?.username)}>
                <IconOpenInNew
                  width={20}
                  height={20}
                />
              </Styled.ChallengeContentHeaderIcon>
            )}
          </Styled.ChallengeContentHeaderTitle>
          <Text
            tag="span"
            text={`${selectedPortfolio?.email} / ${selectedPortfolio?.username} ${selectedPortfolio?.isSubscribed ? '/ Premium user' : ''} `}
          />
          {!(isLoading || isFetching) && (
            <Styled.InfoText>{`${galleryData?.meta?.pagination?.total} media in total`}</Styled.InfoText>
          )}
        </Styled.ChallengeContentHeaderInfo>
        <Styled.ChallengeContentHeaderActions>
          <Button
            slotLeft={(
              <ThumbDownAltIcon
                fill="white"
              />
            )}
            isLoading={isRankLoading}
            disabled={isRankLoading}
            type="destructive"
            label="Bad"
            onClick={() => handlePortfolioRank(-1)}
          />
          <Button
            type="secondary"
            label="Good"
            isLoading={isRankLoading}
            disabled={isRankLoading}
            onClick={() => handlePortfolioRank(0)}
          />
          <Button
            slotLeft={(
              <ThumbUpAltIcon
                fill="white"
              />
            )}
            isLoading={isRankLoading}
            disabled={isRankLoading}
            type="tertiary"
            label="Great"
            onClick={() => handlePortfolioRank(1)}
          />
        </Styled.ChallengeContentHeaderActions>
      </Styled.ChallengeContentHeader>
      <Styled.ChallengeContentBody>
        <Styled.ChallengeContentContainer>
          <Styled.ChallengeMainInfo>
            {(isLoading || isFetching) ? (
              <Loader
                color="gray"
                isLoading
                size={15}
              />
            ) : galleryData?.data?.length ? (
              <>
                <Styled.ChallengeSubmissionsGrid>
                  {galleryData?.data?.map((media: any, index: number) => (
                    <ChallengeMediaItem
                      key={index}
                      id={media?.media_id}
                      thumbnail={media?.thumbnail}
                      type={media?.type}
                      selected={index === mediaZoomIndex}
                      onZoom={() => setMediaZoomIndex(index)}
                    />
                  ))}
                </Styled.ChallengeSubmissionsGrid>
              </>
            ) : 'No media in gallery.'}
          </Styled.ChallengeMainInfo>
        </Styled.ChallengeContentContainer>
      </Styled.ChallengeContentBody>
      {typeof mediaZoomIndex === 'number' && (
        <div className="batch-media-slideshow">
          <ChallengeMediaItemSlider
            items={galleryData?.data}
            mediaZoomIndex={mediaZoomIndex}
            changeMediaZoomIndex={changeMediaZoomIndex}
            mediaZoomClose={mediaZoomClose}
          />
        </div>
      )}
    </Styled.ChallengeContentWrapper>
  );
};
