import React, { useState } from 'react';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { KeywordType } from '../../../../types';

import { StyledButton } from '../../../../components/StyledButton';
import { LoadingProgress } from '../../../../components/LoadingProgress';
import { useGetMediaCategories } from '../../../../queries';
import {
  useGetRankerKeywordingDefects,
  useGetRankerAssignedMedia,
  useSaveKeywordingRank,
} from './useRanker';
import './ranker.view.scss';

export const RankerMediaView = () => {
  const [rankNumber, setRankNumber] = useState<any>(10);
  const [disableRank, setDisableRank] = useState<boolean>(false);
  const [rankerMedia, setRankerMedia] = useState<any>({});
  const [keywordingDefects, setKeywordingDefects] = useState<any>([]);
  const [rankerMediaIndex, setRankerMediaIndex] = useState(0);
  const { data: categories } = useGetMediaCategories();
  const saveKeywordingRank = useSaveKeywordingRank({
    onSuccess: () => {
      setDisableRank(false);
      setKeywordingDefects(
        keywordingDefects.map((item: any) => ({
          ...item,
          ...{ selected: false },
        })),
      );
      setRankNumber(10);
      setRankerMediaIndex(rankerMediaIndex + 1);
      getRankerMedia();
    },
  });

  useGetRankerKeywordingDefects({
    onSuccess: (res: any) => {
      const responseData = [...res.data].map((item: any) => ({
        ...item,
        ...{ selected: false },
      }));

      responseData.length && setKeywordingDefects(responseData);
    },
  });
  const {
    refetch: getRankerMedia,
    isLoading,
  } = useGetRankerAssignedMedia({
    onSuccess: (res: any) => {
      res.data && setRankerMedia(res.data);
    },
  });
  const handleRankClick = () => {
    setDisableRank(true);
    saveKeywordingRank.mutate({
      mediaId: rankerMedia.id,
      keywordingDefects: keywordingDefects
        .filter((i: any) => i.selected)
        .map((i: any) => i.id),
    });
  };
  const getCategoryName = (id: number) => {
    return categories?.data?.filter((category: any) => {
      return category.id === id;
    })[0]?.name ?? '-';
  };
  const updateRankNumber = (reasonIndex: any) => {
    let calculatedNumber: any = 1;

    keywordingDefects[reasonIndex].selected =
      !keywordingDefects[reasonIndex].selected;
    keywordingDefects
      .filter((i: any) => i.selected === true)
      .forEach((item: any) => {
        calculatedNumber *= parseFloat(item.weight);
      });

    setRankNumber(calculatedNumber * 10);
  };
  const formatRank = (rank: number) => {
    let rankFormatted = parseFloat(rank.toString()).toFixed(1);
    const splittedRank = rankFormatted.split('.');

    if (splittedRank[1] === '0') {
      rankFormatted = splittedRank[0];
    }

    return rankFormatted;
  };

  return (
    <div className="Ranker-element">
      {!!isLoading && <LoadingProgress />}
      {!isLoading && !rankerMedia.id && (
        <p className="info-text"><span>🥱</span>Unfortunately, there&apos;s no media left to rank. Check back later.</p>
      )}
      {!isLoading && rankerMedia.id && (
        <>
          <div className="ranker-image-container">
            <div className="ranker-image">
              {rankerMedia.preview ? (
                <div>
                  <img src={rankerMedia.preview} alt="" />
                </div>
              ) : (
                <ImageRoundedIcon />
              )}
            </div>
          </div>
          <div className="ranker-image-details">
            <div className="ranker-image-title">
              {rankerMedia.title}
            </div>
            <div className="ranker-image-category">{getCategoryName(rankerMedia.category_id)}</div>
            <div className="ranker-image-info">
              {rankerMedia.description}
            </div>
            <div className="ranker-image-keywords">
              {rankerMedia.keywords.map((keyword: KeywordType, index: number) =>
                <div key={index}>{keyword.keyword}</div>,
              )}
            </div>
            <div className="ranker-image-buttons">
              <div className="ranker-image-rank">{formatRank(rankNumber)} / 10</div>
              <StyledButton
                className="home-element-button"
                variant="contained"
                disabled={disableRank}
                color="primary"
                onClick={handleRankClick}
              >
                Rank
              </StyledButton>
            </div>
            <div className="ranker-image-reasons">
              <div>
                {!!keywordingDefects.length &&
                  keywordingDefects
                    .slice(0, 7)
                    .map((item: any, index: number) => (
                      <FormControlLabel
                        key={index}
                        checked={item.selected}
                        value={parseFloat(item.weight).toFixed(2)}
                        control={<Checkbox icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
                        label={item.name}
                        onChange={() => updateRankNumber(index)}
                      />
                    ))}
              </div>
              <div>
                {!!keywordingDefects.length &&
                  keywordingDefects
                    .slice(7, 14)
                    .map((item: any, index: any) => (
                      <FormControlLabel
                        key={index + 7}
                        checked={item.selected}
                        value={parseFloat(item.weight).toFixed(2)}
                        control={<Checkbox icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
                        label={item.name}
                        onChange={() => updateRankNumber(index + 7)}
                      />
                    ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
