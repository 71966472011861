import React, { forwardRef } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import { Grow, Slide } from '@material-ui/core';

export const GrowTransition = forwardRef<unknown, TransitionProps>(
  (props: any, ref: any) => <Grow direction="up" ref={ref} {...props} />,
);
GrowTransition.displayName = 'GrowTransition';

export const SlideTransition = forwardRef<unknown, TransitionProps>(
  (props: any, ref: any) => <Slide direction="up" ref={ref} {...props} />,
);
SlideTransition.displayName = 'SlideTransition';
