import React from 'react';
import { KeywordRelatedMediaInfo } from '../components/MediaKeywords/mediaKeywords.types';

export type ReactNode =
  | React.ReactChild
  | React.ReactFragment
  | React.ReactPortal
  | boolean
  | null
  | undefined
  | any;

export type Response = {
  message: string;
  data: [] | any;
};

export type HttpHeaders = {
  [key: string]: string;
};

export type RequestConfig = {
  headers: HttpHeaders;
};

export type ChartOptionsType = {
  chart: object;
  title: object;
  subtitle: object;
  xAxis: object;
  yAxis: object;
  plotOptions: object;
  series: object;
};

export type BatchType = {
  id: number;
  user_id: number;
  purpose?: string;
};

export type MediaAgency = {
  show: boolean;
  message: string;
};

export type MediaAgencyTypes = {
  shutterstock: MediaAgency;
  adobe: MediaAgency;
  alamy: MediaAgency;
};

export type ReleaseType = {
  birth_date: string;
  country: string;
  created_at: string;
  ethnicity: string;
  firstname: string;
  gender: string;
  file_url: string;
  model: {
    data: {
      name: string;
      ethnicity_name: string;
      token: string;
    },
  };
  id: number;
  lastname: string;
  shoot_date: Date | null;
  type: string;
};

export type KeywordType = {
  media_id: number;
  keyword: string;
  order?: number;
  id: number;
  selected?: boolean;
};

export type MediaItem = {
  id: number;
  title?: string;
  description?: string;
  note?: string;
  profile_caption?: string;
  batchMedia?: any;
  agenciesMedia?: any;
  externalCopies?: any;
  category?: string;
  category_id?: number;
  city?: string;
  country?: string;
  country_id?: number;
  creator: {
    data: {
      [key: string]: string;
    };
  };
  details: {
    data: {
      [key: string]: string | number;
      height: number;
      width: number;
      aspect_ratio: number;
      is_ai_generated: number;
    }
  };
  releases: {
    data: ReleaseType[];
  };
  original_file_url?: string;
  original_optimized_file_url?: string;
  is_mature?: boolean;
  is_selling_to_agencies_allowed?: boolean | number;
  is_easy_submission?: boolean;
  is_release_needed?: boolean;
  license: string;
  photo_shoot_at?: string;
  keywords: KeywordType[];
  suggestedKeywords?: {
    data?: any;
  },
  selected: boolean;
  submitted: boolean;
  show: boolean;
  withError: boolean;
  thumbnail: string;
  preview: string;
  camera?: string;
  type?: string;
  validation?: any;
  height: number;
  width: number;
  source: {
    source_id: string;
    source: string;
  }
};

export type SummaryItemType = {
  for_actions: string[];
  media_count: number;
  time_unit: string;
  time_unit_beginning_date: string;
};

export type JWTToken = {
  aud: string;
  jti: string;
  iat: number;
  nbf: number;
  exp: number;
  sub: string;
  scopes: [];
};

export type ReasonType = {
  id: number;
  name: string;
  type: string;
  description: string;
};

export interface UserProps {
  id?: number;
  name?: string;
  display_name?: string;
  email?: string;
  username?: string;
  role?: string[];
  avatar?: string;
  selectedRole?: string;
  isCurator?: boolean;
  isKeyworder?: boolean;
  isReviewer?: boolean;
  isRanker?: boolean;
  isAdmin?: boolean;
}

export interface LoadingProgressProps {
  fixed?: boolean;
  size?: number;
  color?: 'inherit' | 'error' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | undefined;
}

export interface KeyboardEventsProps {
  context?: string;
}

export interface StatsProps {
  today?: {
    total: number;
    data: any;
  };
  weekly?: {
    total: number;
    data: [];
  };
  monthly?: {
    total: number;
    data: [];
  };
  thisWeek?: {
    total: number;
    data: [];
  };
  thisMonth?: {
    total: number;
    data: [];
  };
  paymentCycle?: {
    total: number;
    data: [];
  };
}

export interface MediaUpdateProps {
  media_ids: number[],
  user_id: number,
  is_easy_submission: boolean,
  category_id: number,
  title: string,
  description: string,
  license: string,
  type: string,
  country_id: number,
  city: string,
  photo_shoot_at: string,
  note: string,
  is_release_needed: boolean,
  keywords: string[],
  releases: number[],
  agencies: number[],
}

export interface IApiClient {
  post<TRequest, TResponse>(
    path: string,
    object: TRequest,
    config?: RequestConfig,
  ): Promise<TResponse>;

  patch<TRequest, TResponse>(
    path: string,
    object: TRequest,
  ): Promise<TResponse>;

  put<TRequest, TResponse>(path: string, object: TRequest): Promise<TResponse>;

  get<TResponse>(path: string): Promise<TResponse>;
}

export interface MediaExternalCopiesProps {
  copies: any;
}

export interface MediaAgencyProps {
  media: MediaItem[];
  onAgencyReject: (agencyId: number) => void;
}

export interface DefaultUserProps {
  user?: UserProps;
  changeRole?: (role: string) => void;
}

export interface ProgressStatusProps {
  value: number;
}

export interface BatchMediaNoiseInfo {
  extId: number;
  noise: number;
}

export interface BatchMediaContainerProps {
  page?: number;
  totalPage?: number;
  totalMediaCount?: number;
  batchMediaNoise: BatchMediaNoiseInfo[],
  onPageChange: () => void;
  isLoading: boolean;
  batch: BatchType,
  items: MediaItem[],
  cursorPosition: number;
  setCursor: any;
  toggleSelection: (index: number, ctrlPressed: boolean) => void;
  onSubmitAll: () => void;
  onUpdateMedia: (mediaItems: MediaItem[], data: any) => void;
  selectedItems: MediaItem[];
  selectAll: (value: boolean) => void;
  user?: UserProps;
}

export interface BatchMediaItemsProps {
  totalMediaCount?: number;
  items: MediaItem[];
  cursorPosition: number;
  batchMediaNoise: BatchMediaNoiseInfo[],
  setCursor: any;
  toggleSelection: (index: number, ctrlPressed: boolean) => void;
  mediaZoomOpen: (i: number) => void;
  selectedItems: MediaItem[];
  selectAll: (value: boolean) => void;
  user?: UserProps;
}

export interface DashboardDataProps {
  time_unit: string;
  period_from: string;
  period_to: string;
}

export interface PayoutsDataProps {
  userId?: number;
  state: string;
  status: string;
  page?: number;
}

export interface AuthProps {
  component: ReactNode;
  authRequired?: boolean;
  path: string;
}

export interface ChartProps {
  series: object;
  categories: object;
}

export interface ChartOptionsProps {
  series: number[];
  categories: string[];
}

export interface DialogProps {
  open: boolean;
  dialogCloseHandler: React.Dispatch<React.SetStateAction<boolean>>;
  onActionSuccess?: () => void;
  children: React.ReactNode;
}

export interface FlagReasonProps {
  dialogCloseHandler: (value: boolean) => void;
  batchFlagHandler: (value: string) => void;
}

export interface RejectReasonProps {
  mediaList: MediaItem[];
  reasonType: string;
  dialogCloseHandler: (value: boolean) => void;
  onActionSuccess: (actionType: string) => void;
}

export interface OnlyShopReasonProps {
  mediaList: MediaItem[];
  dialogCloseHandler: (value: boolean) => void;
  onActionSuccess: (actionType: string) => void;
}

export interface RegenerateMediaDataProps {
  mediaItem: MediaItem;
  dialogCloseHandler: (value: boolean) => void;
  onActionSuccess: (actionType: any) => void;
}

export interface MediaKeywordsProps {
  mediaId: number;
  canGenerate?: boolean;
  defaultKeywords: KeywordType[];
  suggestedKeywords: string[];
  mediaContext: string;
  onKeywordChange: (keywords: string[]) => void;
  mediaInfo: KeywordRelatedMediaInfo;
  onKeywordsValidationChange?: (areValid: boolean) => void;
  errors: {
    index: number,
    errors: string[]
  }[]
}

export interface MediaTypeSwitchProps {
  type?: string;
  onTypeChange: (type: string) => void;
}

export interface MediaCategoryProps {
  categoryId?: number;
  onChange: (category_id: number) => void;
}

export interface MediaCountryProps {
  countryId?: number;
  readOnly?: boolean;
  onChange: (country_id: number) => void;
}

export interface MediaDatePickerProps {
  date?: string;
  readOnly?: boolean;
  onChange: (date: string) => void;
}

export interface ChallengeDateTimePickerProps {
  date?: string;
  readOnly?: boolean;
  label?: string;
  onChange: (date: string) => void;
}

export interface MediaReleasesProps {
  releases: {
    data: ReleaseType[];
  }
}

export interface MediaCameraProps {
  info: any;
}

export interface MediaAdditionalInfoProps {
  info: any;
}

export interface MediaNoiseInfoProps {
  noiseValue: number | null;
}

export interface MediaItemProps {
  item: MediaItem;
  index: number;
  cursorIndex: number;
  noiseInfo: number | null;
  setCursor: (index: number) => void;
  toggleSelection: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, multiple: boolean) => void;
  mediaZoomOpen: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  user?: UserProps;
}

export interface MediaItemSliderProps {
  items: any;
  mediaZoomIndex: number;
  toggleSelection?: (index: number, ctrlPressed: boolean) => void;
  changeMediaZoomIndex: (value: number) => void;
  mediaZoomClose: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onFavorite?: (mediaId: string, favorite: boolean) => void;
}

export interface BatchMediaFormProps {
  batch: any,
  media: MediaItem[];
  user?: UserProps;
  descriptionSuggestions: string[];
  descriptionSuggestionLoading?: boolean;
  onSubmitMedia: (id: number) => void;
  onActionMedia: (medias: any, data: any) => void;
  onUpdateMedia: (mediaItems: MediaItem[], data: any) => void;
  onRefetchMedia: () => Promise<MediaItem[]>;
}

export interface FlagDataProps {
  batchId: number;
  reason: string;
}

export interface ReadyDataProps {
  batchId: number;
}

export interface RejectDataProps {
  batchId: number;
  reason: string;
}

export interface Credentials {
  username: string;
  password: string;
}

export enum MediaTypes {
  Photo = 'photo',
  Illustration = 'illustration',
  Video = 'video',
  Vector = 'vector',
}

export enum MediaOrientations {
  Panoramic = 'panoramic',
  Vertical = 'vertical',
}

export interface RequestUrlsByRole {
  [key: string]: string
}

export interface MediaContentIssue {
  media_id: number,
  errors: {
    [key: string]: string[]
  }
}
