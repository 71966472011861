export class ContentSectionDTO {
  title: string;

  content: string;

  images: string[];

  constructor(sectionData: any) {
    this.title = sectionData?.title;
    this.content = sectionData?.content;
    this.images = sectionData?.images;
  }
}
