import React from 'react';
import { IconImageNotSupported, Loader, Text } from '@wirestock.io/design-components';
import { useTheme } from 'styled-components';
import * as Styled from './ThemeAssetsList.styles';
import { CustomStyleAssetDTO } from '../../../../models/CustomStyle';

interface ThemeAssetsGridProps {
  assets?: CustomStyleAssetDTO[];
  isLoading?: boolean;
}

export const ThemeAssetsGrid = ({ assets, isLoading }: ThemeAssetsGridProps) => {
  const { color, typography } = useTheme();

  if (isLoading) {
    return (
      <Styled.ThemeAssetsEmptyState>
        <Loader isLoading size={40} color={color.BABY_BLUE} />
      </Styled.ThemeAssetsEmptyState>
    );
  }

  if (!assets?.length) {
    return (
      <Styled.ThemeAssetsEmptyState>
        <IconImageNotSupported fill={color.TEXT_4} width={30} height={30} />
        <Text text="No assets found" tag="p3" color={color.TEXT_4} weight={typography.BOLD} />
      </Styled.ThemeAssetsEmptyState>
    );
  }

  return (
    <>
      <Styled.ThemeAssetsGrid>
        {isLoading ? (
          <>
            {Array.from(Array(14).keys()).map((index) => (
              <Styled.ThemeAssetsGridItem key={index}>
                <Styled.ThemeAssetsGridItemSkeleton animation />
              </Styled.ThemeAssetsGridItem>
            ))}
          </>
        ) : (
          <>
            {assets?.map((asset, index) => (
              <Styled.ThemeAssetsGridItem key={index}>
                <Styled.ThemeAssetsGridItemImg src={asset.url} />
                {!!asset.sourceMediaId && (
                <Styled.ThemeAssetsGridItemLabel>
                  <Text
                    text={!asset?.sourceMediaStatus ? 'Loading...' : asset.sourceMediaStatus?.split('_').join(' ')}
                    tag="span"
                    weight={typography.BOLD}
                    color={color.TEXT_3}
                  />
                </Styled.ThemeAssetsGridItemLabel>
                )}
              </Styled.ThemeAssetsGridItem>
            ))}
          </>
        )}
      </Styled.ThemeAssetsGrid>
    </>
  );
};
