import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useGetMediaCountries } from '../../queries';
import { Response, MediaCountryProps } from '../../types';
import './media.country.scss';

export const MediaCountry = ({
  countryId,
  readOnly,
  onChange,
}: MediaCountryProps): JSX.Element => {
  const [country, setCountry] = useState<any>(0);
  const [countryList, setCountryList] = useState<any>([]);
  const handleChange = (event: any) => {
    onChange(parseInt(event.target.value, 10));
    setCountry(event.target.value);
  };
  const { refetch } = useGetMediaCountries({
    onSuccess: (res: Response) => {
      setCountryList(res.data);
    },
  });

  useEffect(() => {
    refetch();
    setCountry(countryId || 0);
  }, [countryId]);

  return (
    <div className="MediaCategory">
      <FormControl fullWidth>
        <Select
          value={country}
          label="Country"
          readOnly={readOnly}
          onChange={handleChange}
          placeholder="Select a country"
        >
          <MenuItem value="0">Select</MenuItem>
          {countryList &&
            countryList.map((item: any, index: number) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};
